import { Flex, Input } from '@chakra-ui/react'
import { type ReportResult } from 'graphql/reports/utils'
import { type SetStateAction, type Dispatch } from 'react'

type ReportState = Pick<ReportResult, 'name' | 'description'>

type Props<T extends ReportState> = {
  reportState: T
  setReportState: Dispatch<SetStateAction<T>>
}

export const ReportNameDescription = <T extends ReportState>({
  reportState,
  setReportState,
}: Props<T>) => (
  <Flex grow={1} w="full" flexDir="column">
    <Input
      id="reportName"
      type="string"
      variant="flushed"
      borderBottom="none"
      fontSize="lg"
      mb={1}
      lineHeight={7}
      h={9}
      pb={1}
      placeholder="Report title"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={true}
      value={reportState.name}
      onChange={(e) =>
        setReportState((prev) => ({ ...prev, name: e.target.value }))
      }
    />

    <Input
      id="reportDescription"
      isTruncated
      type="string"
      variant="flushed"
      borderBottom="none"
      paddingBottom={1}
      h={7}
      placeholder="Description (optional)"
      value={reportState.description}
      onChange={(e) =>
        setReportState((prev) => ({
          ...prev,
          description: e.target.value,
        }))
      }
    />
  </Flex>
)
