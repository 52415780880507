import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useReportQueryParam } from 'features/reports/hooks/useReportState'
import { useDeleteReport } from 'graphql/reports/useDeleteReport'
import { type ReportResult } from 'graphql/reports/utils'
import { type FC } from 'react'

interface DeleteReportModalProps {
  isOpen: boolean
  onClose: () => void
  report: ReportResult
}

export const DeleteReportModal: FC<DeleteReportModalProps> = ({
  isOpen,
  onClose,
  report,
}) => {
  const toast = useToast()
  const [deleteReportMutation, deleteReportState] = useDeleteReport()
  const [, setReportId] = useReportQueryParam()

  const onDeleteReport = async () => {
    try {
      const response = await deleteReportMutation({
        id: report.id,
      })

      if (response.data?.deleteReport) {
        // make sure to close report when is deleted
        setReportId(null)
        onClose()
      } else {
        toast({ status: 'error', description: 'Could not delete report' })
      }
    } catch (error) {
      toast({ status: 'error', description: 'Could not delete report' })
      captureException(`Delete report: ${error}`)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography>
            The report will be deleted. This can not be undone.
          </Typography>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="grey" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={onDeleteReport}
            isLoading={deleteReportState.loading}
          >
            Delete report
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
