import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_MARKETING_COST_MANUAL_INPUT_MUTATION = graphql(/* GraphQL */ `
  mutation CreateMarketingCostManualInput(
    $params: CreateMarketingCostManualInputParams!
  ) {
    createMarketingCostManualInput(params: $params) {
      ...IntegrationFields
    }
  }
`)

export const useCreateMarketingCostManualInput = () =>
  useMutation(CREATE_MARKETING_COST_MANUAL_INPUT_MUTATION, {
    refetchQueries: ['MarketingCostsViewQuery'],
  })
