import { Flex, Skeleton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type DropdownOption } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { forwardRef, useEffect, useRef } from 'react'
import { dateRange } from './TaxonomyDropdown'

type Props = {
  group?: DropdownOption
  name?: string
  rowHasValue: boolean
  isOpen: boolean
  isReadOnly?: boolean
  depth: number
  onClick: () => void
  setError: (errorMessage?: string) => void
}

export const TaxonomyDimensionButton = forwardRef<HTMLButtonElement, Props>(
  (
    { group, name, isOpen, rowHasValue, isReadOnly, depth, onClick, setError },
    ref,
  ) => {
    const { uniqueDimensionValues, query } = useUniqueDimensionValues({
      skip: !name || !group?.id,
      dimensionId: String(group?.id ?? ''),
      dateRange,
    })

    const label = uniqueDimensionValues.find(
      (dimension) => dimension.id === name,
    )?.name

    const hasError = !query.loading && rowHasValue && (!group?.name || !label)
    const errorMessage = hasError ? 'No dimension has been selected' : undefined
    const errorMessageRef = useRef(errorMessage)

    useEffect(() => {
      // Update error on data object
      // Obs! setError is not stable so this effect triggers more often than necessary
      // This should be revisited when we have a better solution
      if (errorMessageRef.current !== errorMessage) {
        errorMessageRef.current = errorMessage
        setError(errorMessage)
      }
    }, [errorMessage, setError])

    return (
      <Button
        ref={ref}
        variant="outline"
        size="sm"
        w="full"
        maxW={`${344 - (depth - 1) * 24}px`}
        colorScheme="grey"
        onClick={onClick}
        trailingIcon={{
          name: isOpen && !isReadOnly ? 'ChevronUpIcon' : 'ChevronDownIcon',
        }}
        justifyContent="space-between"
        error={errorMessage}
        aria-invalid={hasError}
        aria-readonly={isReadOnly}
      >
        {group?.name ? (
          <Flex textOverflow="ellipsis" alignItems="center" overflow="hidden">
            {query.loading ? (
              <Skeleton w="120px" h="18px" />
            ) : (
              <>
                <Typography mr={0.5} color="grey.600">
                  {group?.name}:
                </Typography>
                <Typography>{label ?? 'Select value'}</Typography>
              </>
            )}
          </Flex>
        ) : (
          <Typography color="grey.800">Select dimension/value</Typography>
        )}
      </Button>
    )
  },
)

TaxonomyDimensionButton.displayName = 'TaxonomyDimensionButton'
