import { filtersParamConfigMap } from 'features/reports/hooks/filters/useFiltersState'
import { tableStateParamConfigMap } from 'features/reports/hooks/useAnalyticsTableState'
import { chartStateParamConfigMap } from 'features/reports/hooks/useChartState'
import { type ReportsState } from 'features/reports/hooks/useCurrentReportsState'
import { dateStateParamConfigMap } from 'features/reports/hooks/useDateState'
import { dimensionsParamConfigMap } from 'features/reports/hooks/useDimensionsState'
import { metricsParamConfigMap } from 'features/reports/hooks/useMetricsState'
import { reportParamConfigMap } from 'features/reports/hooks/useReportState'
import queryString from 'query-string'
import { useCallback } from 'react'
import { encodeQueryParams } from 'use-query-params'
import { useFormatReport } from './useFormatReport'

export const useGenerateReportUrl = () => {
  const formatReport = useFormatReport()

  return useCallback(
    (report: ReportsState) => {
      const formattedReport = formatReport(report)

      const encodedParams = encodeQueryParams(
        {
          ...metricsParamConfigMap,
          ...dimensionsParamConfigMap,
          ...filtersParamConfigMap,
          ...reportParamConfigMap,
          ...chartStateParamConfigMap,
          ...dateStateParamConfigMap,
          ...tableStateParamConfigMap,
        },
        formattedReport,
      )

      return queryString.stringify(encodedParams)
    },
    [formatReport],
  )
}
