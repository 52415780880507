import { Flex, Menu, MenuButton, Portal } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { useState } from 'react'
import TableFilter from '../../Filters/TableFilter/TableFilter'
import { type HeaderMenuWrapperProps } from '../types'
import { DimensionMenu } from './DimensionsMenu'
import { MetricsMenu } from './MetricsMenu'

export const HeaderMenuWrapper = ({
  isMetric,
  columnId,
  displayName,
  setSortOrder,
  isFrozen,
  toggleFreezeState,
}: HeaderMenuWrapperProps) => {
  const [isFilterOpen, setFilterOpen] = useState(false)

  return (
    <>
      {isFilterOpen ? (
        <Flex
          className="icon"
          alignSelf="flex-start"
          visibility={isFilterOpen ? undefined : 'hidden'}
        >
          <TableFilter
            isPopupOpen={isFilterOpen}
            setPopupOpen={setFilterOpen}
            filterId={columnId}
            filterLabel={displayName}
          >
            <MenuButton
              as={ButtonIcon}
              name={isFilterOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              title="More column options"
              size="xs"
              variant="ghost"
              colorScheme="grey"
            />
          </TableFilter>
        </Flex>
      ) : (
        <ActionsMenu
          isMetric={isMetric}
          openFilterPopup={() => setFilterOpen(true)}
          setSortOrder={setSortOrder}
          isFrozen={isFrozen}
          toggleFreezeState={toggleFreezeState}
        />
      )}
    </>
  )
}

const ActionsMenu = ({
  isMetric,
  openFilterPopup,
  setSortOrder,
  isFrozen,
  toggleFreezeState,
}: Omit<HeaderMenuWrapperProps, 'columnId' | 'displayName'> & {
  openFilterPopup: () => void
}) => {
  return (
    <Menu>
      {({ isOpen }) => {
        return (
          <Flex
            className="icon"
            alignSelf="flex-start"
            visibility={isOpen ? undefined : 'hidden'}
          >
            <MenuButton
              as={ButtonIcon}
              name={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              title="More column options"
              size="xs"
              variant="ghost"
              colorScheme="grey"
            />
            <Portal>
              <MenuOverlay isOpen={isOpen} />
              {isMetric ? (
                <MetricsMenu
                  openFilterPopup={openFilterPopup}
                  setSortOrder={setSortOrder}
                />
              ) : (
                <DimensionMenu
                  openFilterPopup={openFilterPopup}
                  setSortOrder={setSortOrder}
                  isFrozen={isFrozen}
                  onFreezeItemClick={toggleFreezeState}
                />
              )}
            </Portal>
          </Flex>
        )
      }}
    </Menu>
  )
}
