import { useUser } from '@clerk/clerk-react'
import {
  REPORT_VISIBILITY,
  getReportVisibilityType,
  type ReportResult,
} from 'graphql/reports/utils'
import { useIsGuestUser } from 'hooks/useIsGuestUser'

export const useCanEditReport = (report: ReportResult | undefined) => {
  const isGuestUser = useIsGuestUser()
  const { user } = useUser()

  if (!report) return false

  const isOwner = report.owner.id === user?.publicMetadata.dema_id
  const isPrivate =
    getReportVisibilityType(report.visibility) === REPORT_VISIBILITY.PRIVATE

  // Logic is:
  // 1. If user is owner, they always have edit access no matter what
  // 2. If user is a guest, they never have edit access to other reports
  // 3. If the report is private, only the owner has edit access
  return isOwner || (!isGuestUser && !isPrivate)
}
