import { useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { OPTIMISTIC_IDS } from '../consts'

export const CONVERSATION_BY_ID_QUERY = graphql(/* GraphQL */ `
  query ConversationById($id: ID!) {
    viewer {
      conversation(id: $id) {
        ...ConversationFields
      }
    }
  }
`)

export const useGetConversationById = (conversationId: string | null) => {
  const fetchPolicy: WatchQueryFetchPolicy =
    conversationId === OPTIMISTIC_IDS.CONVERSATION
      ? 'cache-only'
      : 'cache-first'

  const { data, ...query } = useQuery(CONVERSATION_BY_ID_QUERY, {
    skip: !conversationId,
    variables: {
      id: String(conversationId ?? ''),
    },
    fetchPolicy: fetchPolicy,
    nextFetchPolicy: fetchPolicy,
  })

  return [data?.viewer?.conversation, query] as const
}
