import { type ReportByIdQuery } from 'generated/graphql/graphql'
import { useReportById } from 'graphql/reports/useReportById'
import { transformReport } from 'graphql/reports/utils'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useEffect } from 'react'
import { userAtom } from 'shared/atoms'
import { StringParam, useQueryParam } from 'use-query-params'
import { NEW_REPORT_ID, URL_PARAM_KEYS } from '../consts'

export const reportParamConfigMap = { [URL_PARAM_KEYS.REPORT_ID]: StringParam }

const reportAtom =
  atom<NonNullable<ReportByIdQuery['viewer']>['report']>(undefined)

const isCreateNewReportAtom = atom<boolean>(false)

export const reportStateAtom = atom((get) => {
  const report = get(reportAtom)
  const isCreateNewReport = get(isCreateNewReportAtom)
  const user = get(userAtom)

  if (!report) return undefined

  if (isCreateNewReport) {
    return transformReport({
      ...report,
      id: NEW_REPORT_ID,
      owner: user,
      metrics: [],
      dimensions: [],
      tableState: [],
    })
  }

  return transformReport(report)
})

export const useReportStateAtom = () => useAtomValue(reportStateAtom)

export const reportNameAtom = selectAtom(reportAtom, (report) => report?.name)
export const reportIdAtom = selectAtom(reportAtom, (report) => report?.id)

export const useReportQueryParam = () =>
  useQueryParam(
    URL_PARAM_KEYS.REPORT_ID,
    reportParamConfigMap[URL_PARAM_KEYS.REPORT_ID],
  )
export const useReportStateAtomSetter = () => useSetAtom(reportAtom)
const DEFAULT_REPORT_ID = '64db33416e66839e8d5de4e8'

export const useReportStateSync = () => {
  const [queryReportId] = useReportQueryParam()
  const [reportData, setReportData] = useAtom(reportAtom)
  const setIsCreateNewReport = useSetAtom(isCreateNewReportAtom)
  const isNewReportView = queryReportId === NEW_REPORT_ID
  const { report, query } = useReportById(
    // for the create new report we want the default report as a base
    isNewReportView || !queryReportId ? DEFAULT_REPORT_ID : queryReportId,
  )
  const isInSync = reportData?.id === report?.id

  useEffect(() => {
    setIsCreateNewReport(isNewReportView)
  }, [isNewReportView, setIsCreateNewReport])

  useEffect(() => {
    if (report !== reportData) {
      setReportData(report)
    }
  }, [report, reportData, setReportData])

  return { report: reportData, isInSync, query }
}
