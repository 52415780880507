import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'
import { graphql } from 'generated/graphql'
import { type ChannelMapping } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useRef } from 'react'
import { z } from 'zod'

const UPDATE_CHANNEL_MAPPINGS_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateChannelMappings($params: UpdateChannelMappingsInput!) {
    updateChannelMappings(params: $params) {
      mappings
      channel
      channelGroup
    }
  }
`)

const atLeastOneDefined = (obj: Record<string, unknown>) =>
  Object.values(obj).some((v) => v !== undefined)

export const MAPPING_ACTION = {
  CREATE: 'CREATE',
  REMOVE: 'REMOVE',
} as const

const mappingsSchema = z
  .object({
    utmCampaign: z.string(),
    utmMedium: z.string(),
    utmSource: z.string(),
  })
  .partial()
  .refine(atLeastOneDefined, { message: 'Provide at least one mapping.' })

const paramsSchema = z.object({
  frontendId: z.string(),
  channel: z.string(),
  channelGroup: z.string(),
  mappings: mappingsSchema,
  action: z.nativeEnum(MAPPING_ACTION),
})

type UpdateChannelMappingsParams = z.infer<typeof paramsSchema>

type Mapping = {
  utm_campaign: string | undefined
  utm_medium: string | undefined
  utm_source: string | undefined
}

type UpdateChannelMappingsResponse = {
  updateChannelMappings: ChannelMapping & {
    mappings: [Mapping]
  }
}

const eventActions: Record<keyof typeof MAPPING_ACTION, 'Created' | 'Deleted'> =
  {
    [MAPPING_ACTION.CREATE]: 'Created',
    [MAPPING_ACTION.REMOVE]: 'Deleted',
  }

export const useUpdateChannelMappings = (): [
  typeof updateChannelMappings,
  typeof state,
] => {
  const paramsRef = useRef<UpdateChannelMappingsParams>()
  const [trackEvent] = useTrackEvent()
  const [mutation, state] = useMutation<UpdateChannelMappingsResponse>(
    UPDATE_CHANNEL_MAPPINGS_MUTATION,
    {
      refetchQueries: ['UTMMappingsViewQuery'],
      onCompleted: (response) => {
        const data = response.updateChannelMappings
        const lastIndex = data.mappings.length - 1

        let mapping: Mapping = {
          utm_campaign: undefined,
          utm_medium: undefined,
          utm_source: undefined,
        }

        switch (paramsRef.current?.action) {
          case MAPPING_ACTION.CREATE: {
            mapping = data.mappings[lastIndex]
            break
          }
          case MAPPING_ACTION.REMOVE: {
            const { utmCampaign, utmMedium, utmSource } =
              paramsRef.current.mappings

            mapping = {
              utm_campaign: utmCampaign,
              utm_medium: utmMedium,
              utm_source: utmSource,
            }
            break
          }
          default: {
            captureException(
              `Channel mapping: invalid action ${paramsRef.current?.action} used while updating mapping`,
            )

            break
          }
        }

        const eventProperties = {
          utmSource: mapping.utm_source,
          utmMedium: mapping.utm_medium,
          utmCampaign: mapping.utm_campaign,
          channelGroup: data.channelGroup,
          channel: data.channel,
        }

        if (paramsRef.current) {
          trackEvent({
            eventName: `Settings Channel Mapping ${eventActions[paramsRef.current.action]}`,
            eventProperties,
          })
        }
      },
    },
  )
  const updateChannelMappings = (params: UpdateChannelMappingsParams) => {
    paramsRef.current = params
    const parsedParams = paramsSchema.safeParse(params)

    if (parsedParams.success) {
      const { frontendId, channel, channelGroup, mappings, action } =
        parsedParams.data

      return mutation({
        variables: {
          params: { frontendId, channel, channelGroup, mappings, action },
        },
      })
    }
    captureException(
      `updateChannelMappings invalid request ${parsedParams.error}`,
    )
  }

  return [updateChannelMappings, state]
}
