import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { sizes as buttonSizes, variants as buttonVariants } from './button'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

export const menuSizes = {
  xs: definePartsStyle({
    button: buttonSizes.xs,
    item: {
      px: 4,
      py: 1.5,
      h: 8,
      fontSize: 'sm',
    },
    list: {
      maxH: '256px',
    },
  }),
  sm: definePartsStyle({
    button: buttonSizes.sm,
    item: {
      px: 4,
      py: 1.5,
      h: 8,
      fontSize: 'sm',
      lineHeight: 5,
    },
    icon: {
      marginInline: 0,
      marginInlineEnd: 0,
      margin: 0,
      marginRight: 2,
    },
    divider: {
      my: 1,
    },
    list: {
      maxH: '256px',
    },
  }),
  md: definePartsStyle({
    button: buttonSizes.md,
    item: {
      px: 4,
      py: 2.5,
      h: 10,
      fontSize: 'sm',
    },
    list: {
      maxH: '320px',
    },
  }),
  lg: definePartsStyle({
    button: { ...buttonSizes.lg, fontSize: 'md' },
    item: {
      px: 4,
      py: 2.5,
      h: 10,
      fontSize: 'sm',
    },
    list: {
      maxH: '320px',
    },
  }),
}

const _disabled = {
  bg: 'grey.100',
  color: 'grey.400',
  cursor: 'default',
  pointerEvents: 'none',
}

const baseStyle = definePartsStyle(() => ({
  button: {
    color: 'grey.800',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    _hover: { bg: 'grey.100' },
    _disabled,
  },
  list: {
    borderRadius: 0,
    bg: 'white',
    overflowY: 'auto',
    zIndex: 'popover',
  },
  item: {
    bg: 'white',
    color: 'grey.800',
    // we do not want a hover state, chakra will apply the focus styling to an element if you hover it
    _hover: { bg: 'grey.100' },
    _focus: {
      bg: 'grey.100',
      color: 'grey.900',
    },
    _disabled,
    _selected: {
      bg: 'grey.200',
      color: 'grey.900',
      _hover: { bg: 'grey.300' },
      _focus: { bg: 'grey.300' },
    },
    _checked: {
      bg: 'grey.200',
      color: 'grey.900',
      _hover: { bg: 'grey.300' },
      _focus: { bg: 'grey.300' },
    },
  },
}))

const variants = {
  ghost: definePartsStyle((props) => ({
    button: {
      ...buttonVariants.ghost({
        ...props,
        colorScheme: 'primary',
      }),
    },
  })),
  solid: definePartsStyle((props) => ({
    button: {
      ...buttonVariants.solid({
        ...props,
        colorScheme: 'grey',
      }),
    },
  })),
  outline: definePartsStyle((props) => ({
    button: {
      ...buttonVariants.outline({
        ...props,
        colorScheme: 'grey',
        border: '1px solid',
        borderColor: 'grey.500',
      }),
      _disabled: {
        _disabled,
        borderColor: 'grey.200',
      },
      _invalid: {
        borderColor: 'red.500',
      },
    },
  })),
}

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: menuSizes,
  variants,
})
