import { type BadgeProps } from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type FC } from 'react'
import {
  type CalcTotalDifferenceProps,
  calcTotalDifference,
} from 'utils/calcTotalDifference'

export const DiffBadge: FC<
  CalcTotalDifferenceProps & {
    displayType?: 'value' | 'formattedDifference' | 'percentage'
    badgeProps?: BadgeProps
  }
> = ({ badgeProps, displayType = 'percentage', ...props }) => {
  const {
    iconName,
    color,
    formattedValue,
    formattedDifference,
    formattedPercentageDifference,
  } = calcTotalDifference(props)

  const colorWithoutShade = color.split('.')[0]
  const bgColor = `${colorWithoutShade}.50`
  const textColor =
    colorWithoutShade === 'green' ? 'green.600' : `${colorWithoutShade}.500`

  return (
    <Badge
      display="flex"
      alignItems="center"
      borderRadius={0.5}
      bg={bgColor}
      color={textColor}
      gap="2px"
      p="2px 6px"
      h={5}
      fontSize="xs"
      lineHeight="16px"
      fontWeight={400}
      {...badgeProps}
    >
      <Typography
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        color="inherit"
      >
        {(displayType === 'value'
          ? formattedValue
          : displayType === 'formattedDifference'
            ? formattedDifference
            : formattedPercentageDifference) ?? 'N/A'}
      </Typography>
      <Icon name={iconName} size="small"></Icon>
    </Badge>
  )
}
