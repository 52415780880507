import { Box, Flex, MenuButton } from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { ToggleWithIcons } from 'components/ToggleWithIcons/ToggleWithIcons'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { type ChartSerie } from 'features/reports/utils/chart/types'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import {
  type Metric,
  type NormalizedMetrics,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { useRef } from 'react'
import {
  chartTypes,
  multiMetricChartTypes,
  type ChartTypeId,
} from 'utils/chart/chartTypes'
import { staticChartOptions } from 'utils/chart/constants'

interface Props {
  series: ChartSerie[]
  setChartState: (state: { gSeries: ChartSerie[] }) => void
  uniqueMetrics: Metric[]
}

const chartTypeKeys = Object.keys(chartTypes) as (keyof typeof chartTypes)[]

const allChartItems = chartTypeKeys.map((chartTypeKey) => ({
  id: chartTypeKey,
  iconName: chartTypes[chartTypeKey]?.iconName,
  title: chartTypes[chartTypeKey]?.label,
}))

const multiMetricChartItems = chartTypeKeys
  .filter((chartTypeKey) => multiMetricChartTypes.includes(chartTypeKey))
  .map((chartTypeKey) => ({
    id: chartTypeKey,
    iconName: chartTypes[chartTypeKey]?.iconName,
    title: chartTypes[chartTypeKey]?.label,
  }))

const ChartSeriesListItem = ({
  chartSeries,
  index,
  normalizedMetrics,
  isMultiMetric,
  onUpdateSerieKey,
  onUpdateSerieType,
  removeSerie,
  uniqueMetrics,
}: {
  chartSeries: ChartSerie
  index: number
  normalizedMetrics: NormalizedMetrics
  isMultiMetric: boolean
  onUpdateSerieKey: (index: number, key: string) => void
  onUpdateSerieType: (index: number, type: ChartTypeId) => void
  removeSerie: (index: number) => void
  uniqueMetrics: Metric[]
}) => {
  const { type, key } = chartSeries
  const metricName = normalizedMetrics[key]?.label ?? key
  const textRef = useRef<HTMLParagraphElement>(null)
  const isOverflowing = useIsOverflowing(textRef)

  return (
    <Box>
      <Flex mb={2} alignItems="flex-end" gap={2}>
        <Dropdown
          callback={(newYAxis) => onUpdateSerieKey(index, newYAxis.id)}
          value={key}
          options={uniqueMetrics.map((metric) => ({
            id: metric.key,
            name: metric?.label ?? '',
          }))}
          containerProps={{ minW: 0 }}
          customMenuButton={
            <Tooltip isDisabled={!isOverflowing} label={metricName}>
              <MenuButton
                as={Button}
                size="sm"
                px={2}
                variant="outline"
                colorScheme="gray"
                leadingComponent={
                  <Box
                    textAlign="center"
                    minW={4} // in case of double digit number
                    px={1}
                    h={4}
                    bg={
                      staticChartOptions.colors[
                        index % staticChartOptions.colors.length
                      ]
                    }
                  >
                    <Typography
                      fontSize="xs"
                      fontWeight={700}
                      lineHeight="16px"
                      color="white"
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                }
              >
                <Typography
                  ref={textRef}
                  isTruncated
                  fontSize="inherit"
                  color="inherit"
                  fontWeight="inherit"
                  lineHeight="inherit"
                >
                  {metricName}
                </Typography>
              </MenuButton>
            </Tooltip>
          }
        />
        {isMultiMetric && (
          <ButtonIcon
            size="sm"
            variant="ghost"
            onClick={() => removeSerie(index)}
            name="TrashIcon"
            title="Remove series"
          />
        )}
      </Flex>

      <ToggleWithIcons
        selected={type}
        setSelected={(selected) => onUpdateSerieType(index, selected)}
        items={isMultiMetric ? multiMetricChartItems : allChartItems}
      />
    </Box>
  )
}

export const ChartSeriesList = ({
  series,
  setChartState,
  uniqueMetrics,
}: Props) => {
  const [trackEvent] = useTrackEvent()
  const isMultiMetric = series.length > 1
  const normalizedMetrics = useNormalizedMetrics()

  const onUpdateSerieType = (index: number, type: ChartTypeId) => {
    if (type === series[index].type) return

    const newSeries = [...series]

    newSeries[index] = { ...newSeries[index], type }

    trackEvent({
      eventName: 'Chart Series Type Updated',
      eventProperties: {
        previousType: series[index].type,
        newType: type,
        key: series[index].key,
      },
    })

    setChartState({ gSeries: newSeries })
  }

  const onUpdateSerieKey = (index: number, key: string) => {
    const newSeries = [...series]

    newSeries[index] = { ...newSeries[index], key }

    trackEvent({
      eventName: 'Chart Series Key Updated',
      eventProperties: {
        previousKey: series[index].key,
        newKey: key,
      },
    })

    setChartState({ gSeries: newSeries })
  }

  const removeSerie = (index: number) => {
    const newSeries = [...series]

    newSeries.splice(index, 1)

    trackEvent({
      eventName: 'Chart Series Removed',
      eventProperties: {
        removedKey: series[index].key,
        numberOfSeries: newSeries.length,
      },
    })

    setChartState({ gSeries: newSeries })
  }

  return (
    <Flex direction="column" gap={6}>
      {series.map((chartSeries, index) => {
        return (
          <ChartSeriesListItem
            key={`${chartSeries.key}-${chartSeries.type}`}
            chartSeries={chartSeries}
            index={index}
            isMultiMetric={isMultiMetric}
            normalizedMetrics={normalizedMetrics}
            onUpdateSerieKey={onUpdateSerieKey}
            onUpdateSerieType={onUpdateSerieType}
            removeSerie={removeSerie}
            uniqueMetrics={uniqueMetrics}
          />
        )
      })}
    </Flex>
  )
}
