import { Box } from '@chakra-ui/react'
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import classNames from 'classnames'
import { Button, ButtonIcon } from 'components/buttons'
import { Typography } from 'components/Typography'
import {
  cloneElement,
  type ComponentPropsWithRef,
  type PropsWithChildren,
} from 'react'
import styles from './styles.module.scss'
import type { PopupProps } from './types'

const Popup = ({
  title,
  size = 'regular',
  applyButton = undefined,
  hasCancelButton = false,
  hasCloseIcon = false,
  className,
  onClose,
  isOpen,
  setIsOpen,
  children,
  popupButton,
  placement = 'right-start',
  fallbackPlacements = ['bottom-start', 'right-start', 'left-start'],
  placementOffset = 8,
  onApply,
}: PropsWithChildren<PopupProps>) => {
  const nodeId = useFloatingNodeId()
  const { x, y, refs, strategy, context } = useFloating({
    nodeId,
    open: isOpen,
    onOpenChange: (open) => {
      setIsOpen(open)
      if (!open) {
        onClose?.()
      }
    },
    strategy: 'fixed',
    placement,
    middleware: [
      offset(placementOffset),
      flip({
        flipAlignment: false,
        fallbackPlacements,
      }),
      shift({ padding: 8 }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const { getFloatingProps, getReferenceProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ])

  return (
    <FloatingNode id={nodeId}>
      {!!popupButton &&
        cloneElement(
          popupButton,
          getReferenceProps({ ref: refs.setReference, ...popupButton.props }),
        )}
      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              className={classNames(styles.container, styles[size], className)}
              style={{
                left: isFinite(x) ? x : 0,
                top: isFinite(y) ? y : 0,
                position: strategy,
              }}
              {...getFloatingProps()}
            >
              {(title || hasCloseIcon) && (
                <Box mb={4} className={styles.topContainer}>
                  {title && <Typography fontSize="xl">{title}</Typography>}
                  {hasCloseIcon && (
                    <ButtonIcon
                      name="CloseIcon"
                      title="Close modal"
                      variant="ghost"
                      colorScheme="grey"
                      onClick={onClose}
                      justifySelf="flex-end"
                    />
                  )}
                </Box>
              )}
              <div className={styles.contentContainer}>{children}</div>
              {(hasCancelButton || applyButton) && (
                <Box mt={8} className={styles.buttonContainer}>
                  {hasCancelButton && (
                    <Button onClick={onClose} variant="outline">
                      Cancel
                    </Button>
                  )}
                  {applyButton && (
                    <Button onClick={onApply} isDisabled={applyButton.disabled}>
                      Apply
                    </Button>
                  )}
                </Box>
              )}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </FloatingNode>
  )
}

const NestedPopup = (props: ComponentPropsWithRef<typeof Popup>) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree>
        <Popup {...props} />
      </FloatingTree>
    )
  }

  return <Popup {...props} />
}

export default NestedPopup
