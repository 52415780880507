import { Box, Divider, Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useReportStateAtom } from 'features/reports/hooks/useReportState'
import { useState } from 'react'
import { formatDate } from 'utils/formatDate'
import { AttributionSection } from './AttributionSection'

export const AdditionalInfoSection: React.FC = () => {
  const selectedReport = useReportStateAtom()
  const [showMore, setShowMore] = useState(false)

  if (!showMore) {
    return (
      <Box p={4}>
        <Button
          colorScheme="gray"
          size="xs"
          variant="ghost"
          leadingIcon={{ name: 'ChevronDownIcon' }}
          onClick={() => setShowMore(true)}
        >
          See additional information
        </Button>
      </Box>
    )
  }

  const formattedCreatedAt = selectedReport?.createdAt
    ? formatDate(new Date(selectedReport.createdAt))
    : null
  const formattedUpdatedAt = selectedReport?.updatedAt
    ? formatDate(new Date(selectedReport.updatedAt))
    : null

  return (
    <>
      <Flex direction="column" gap={4} p={4}>
        <AttributionSection />
      </Flex>
      <Divider />
      <Flex direction="column" gap={1} p={4}>
        {formattedCreatedAt && (
          <Typography fontSize="xs" color="grey.600" lineHeight={4}>
            Created{' '}
            <Typography
              as="time"
              lineHeight={4}
              fontSize="xs"
              color="gray.800"
              dateTime={formattedCreatedAt}
            >
              {formattedCreatedAt}
            </Typography>
          </Typography>
        )}
        {formattedUpdatedAt && (
          <Typography fontSize="xs" color="grey.600" lineHeight={4}>
            Last updated{' '}
            <Typography
              as="time"
              lineHeight={4}
              fontSize="xs"
              color="gray.800"
              dateTime={formattedUpdatedAt}
            >
              {formattedUpdatedAt}
            </Typography>
          </Typography>
        )}
      </Flex>
    </>
  )
}
