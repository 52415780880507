import { type IconName } from 'components/Icon/Icon'
import { colorTheme } from 'ui/theme/colors'

export const teamIconColors: string[] = [
  colorTheme.violet[600],
  colorTheme.grape[500],
  colorTheme.jade[600],
  colorTheme.dandelion[600],
  colorTheme.terracotta[600],
  colorTheme.sky[500],
  colorTheme.violet[500],
  colorTheme.grape[600],
  colorTheme.jade[700],
  colorTheme.sky[600],
  colorTheme.dandelion[700],
  colorTheme.terracotta[700],
  colorTheme.violet[700],
]

export const teamIconNames: IconName[] = [
  'ArchiveIcon',
  'BarChartIcon',
  'CustomerSupportIcon',
  'DiamondIcon',
  'Percent1Icon',
  'DollarCoinIcon',
  'EarthIcon',
  'Flower1Icon',
  'Flower2Icon',
  'HeartIcon',
  'ChartPieIcon',
  'PiggyBankIcon',
  'ShippingBoxIcon',
  'ShippingTruckIcon',
  'ShoppingBagIcon',
  'StarIcon',
  'SuitcaseIcon',
  'TreeIcon',
  'UserMultipleCollaborateIcon',
  'WalletIcon',
]
