import { useOrganization } from '@clerk/clerk-react'
import { useMerchantReports } from 'graphql/reports/useMerchantReports'
import { getMerchantVisibilityFromOrganization } from 'graphql/reports/utils'
import { useSetAtom } from 'jotai'
import { newReportViewStateAtom } from './NewReportView/atom'
import { ReportListTable } from './shared/ReportListTable'

export const OrganisationReportsView = () => {
  const { merchantReports, query } = useMerchantReports()
  const { organization } = useOrganization()
  const setNewReportViewState = useSetAtom(newReportViewStateAtom)

  return (
    <ReportListTable
      pageTitle={organization?.name}
      reports={merchantReports}
      isLoading={query.loading}
      addReportCallback={() => {
        setNewReportViewState((prev) => {
          return {
            ...prev,
            visibility: getMerchantVisibilityFromOrganization(organization),
          }
        })
      }}
    />
  )
}
