export enum URL_PARAM_KEYS {
  COLOR = 'gColor',
  DIMENSIONS = 'dimensions',
  FILTERS = 'filters',
  METRICS = 'metrics',
  REPORT_ID = 'reportId',
  SERIES = 'gSeries',
  TABLE_STATE = 'tableState',
  X_AXIS = 'xAxis',
  DATE_RANGE = 'dateRange',
  COMPARE_DATE_RANGE = 'compareDateRange',
  DYNAMIC_DATE = 'dynamicDate',
  COMPARE_DYNAMIC_DATE = 'compareDynamicDate',
  IS_COMPARE = 'isCompare',
}

export const ORGANIZATION_ID = '*'

export const NEW_REPORT_ID = 'new-report'
