import { Box, Skeleton, type FlexProps } from '@chakra-ui/react'
import { WidgetErrorState } from 'components/Error/WidgetErrorState'
import { ToggleWithLabels } from 'components/ToggleWithLabels/ToggleWithLabels'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { atom, useAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { BaseWidget } from '../BaseWidget'
import { MultiChannelSaturationCurve } from './MultiChannelSaturationCurve/MultiChannelSaturationCurve'
import { SingleChannelSaturationCurve } from './SingleChannelSaturationCurve/SingleChannelSaturationCurve'

interface SaturationCurvesProps {
  curveData: SaturationCurveData | undefined
  dayDiff: number
  isLoading: boolean
  hasError: boolean
  toggleButtonsWrapperProps?: FlexProps
}

enum SaturationCurveSectionId {
  overview = 'overview',
  channelBreakdown = 'channelBreakdown',
}

const sections = [
  {
    id: SaturationCurveSectionId.overview,
    label: 'Overview',
  },
  { id: SaturationCurveSectionId.channelBreakdown, label: 'Channel Breakdown' },
]

const saturationCurveShowValuesPerDayAtom = atom(true)

export const useSaturationCurveShowValuesPerDayAtom = () =>
  useAtom(saturationCurveShowValuesPerDayAtom)

export const SaturationCurves: React.FC<SaturationCurvesProps> = ({
  curveData,
  dayDiff,
  hasError,
  isLoading,
  toggleButtonsWrapperProps,
}) => {
  const [trackEvent] = useTrackEvent()
  const [selectedSection, setSelectedSection] = useState(
    SaturationCurveSectionId.overview,
  )
  const { data: configData } = useOptimizationConfig()
  const channels =
    configData?.viewer?.merchant.optimizationConfig.channels ?? []
  const [showValuesPerDay] = useSaturationCurveShowValuesPerDayAtom()

  const dividedCurveData = useMemo(() => {
    const divideDataPoint = (dataPoint: number) => dataPoint / dayDiff

    return (
      (showValuesPerDay
        ? curveData?.map((curve) => ({
            ...curve,
            line: curve.line.map((point) => point.map(divideDataPoint)),
            optimalPoint: curve.optimalPoint.map(divideDataPoint),
            actualPoint: curve.actualPoint.map(divideDataPoint),
            saturationPoint: curve.saturationPoint.map(divideDataPoint),
          }))
        : curveData) || []
    )
  }, [curveData, showValuesPerDay, dayDiff])

  if (isLoading) {
    return (
      <BaseWidget title={null}>
        <Skeleton h="548px" />
      </BaseWidget>
    )
  }

  if (hasError) {
    return (
      <BaseWidget title={null}>
        <Box py={20}>
          <WidgetErrorState />
        </Box>
      </BaseWidget>
    )
  }

  return (
    <Box>
      <ToggleWithLabels
        items={sections}
        setSelected={(id) => {
          trackEvent({
            eventName: 'Optimizations Saturation Curve Section Changed',
            eventProperties: { section: id },
          })
          setSelectedSection(id)
        }}
        selected={selectedSection}
        sliderBoxProps={{ border: 'none' }}
        shadow="base"
        {...toggleButtonsWrapperProps}
      />
      <BaseWidget title={null}>
        {selectedSection === SaturationCurveSectionId.overview ? (
          <MultiChannelSaturationCurve
            curveData={dividedCurveData}
            channels={channels}
            showValuesPerDay={showValuesPerDay}
          />
        ) : (
          <SingleChannelSaturationCurve
            curveData={dividedCurveData}
            channels={channels}
            showValuesPerDay={showValuesPerDay}
          />
        )}
      </BaseWidget>
    </Box>
  )
}
