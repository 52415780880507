import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  type MenuItemProps,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { useIsGuestUser } from 'hooks/useIsGuestUser'
import { useSignOut } from 'hooks/useSignOut'
import { Link, useMatch } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { NavIconLink } from '../NavLinks'
import { Featurebase } from './Featurebase'

export const profileMenuItemStyles: MenuItemProps = {
  color: 'gray.700',
  fontSize: 'sm',
  iconSpacing: 2,
}

export const Profile = () => {
  const { hide: hideIntercom } = useIntercom()
  const signOut = useSignOut()
  const { user } = useUser()
  const isGuestUser = useIsGuestUser()
  const match = useMatch({ path: '/profile/*' })

  if (!user) return null

  return (
    <Menu placement="bottom-end" gutter={-0.5} onOpen={hideIntercom}>
      {({ isOpen }) => (
        <>
          <MenuButton
            aria-label="User profile"
            as={NavIconLink}
            isSelected={!!match}
            iconName="UserIcon"
          />

          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList>
              <MenuItem
                {...profileMenuItemStyles}
                cursor="default"
                icon={<Icon name="UserCircleIcon" size="small" />}
              >
                {user.primaryEmailAddress?.emailAddress}
              </MenuItem>

              <MenuDivider borderColor="gray.200" />

              <MenuItem
                {...profileMenuItemStyles}
                icon={<Icon name="SlidersIcon" size="small" />}
                as={Link}
                to="/profile/personal"
              >
                Profile
              </MenuItem>
              {!isGuestUser && (
                <MenuItem
                  {...profileMenuItemStyles}
                  icon={<Icon name="OfficeIcon" size="small" />}
                  as={Link}
                  to="/settings/organisation/members"
                >
                  Organisation
                </MenuItem>
              )}

              <Featurebase user={user} />

              <MenuDivider borderColor="gray.200" />

              <MenuItem
                {...profileMenuItemStyles}
                icon={<Icon name="LogoutIcon" size="small" />}
                onClick={signOut}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
