import config from 'config'
import { Routes as Auth } from 'features/auth'
import { routes as optimizationsRoutes } from 'features/optimizations/Routes'
import { routes as profileRoutes } from 'features/profile/Routes'
import { routes as reportsRoutes } from 'features/reports/Routes'
import { routes as settingsRoutes, teamRoutes } from 'features/settings/Routes'
import queryString from 'query-string'
import { lazy } from 'react'
import { createBrowserRouter, Outlet } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { AppWrapper } from './AppWrapper'
import { AuthenticatedOutlet } from './AuthenticatedOutlet'
import { NotFound } from './NotFound'
import { UnexpectedError } from './UnexpectedError'

const queryParamProviderElement = (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: queryString.parse,
      objectToSearchString: queryString.stringify,
    }}
  >
    <Outlet />
  </QueryParamProvider>
)

export const router = createBrowserRouter(
  [
    {
      path: '*',
      element: queryParamProviderElement,
      children: [
        { path: 'auth/*', Component: Auth, errorElement: <UnexpectedError /> },
        {
          path: '*',
          Component: AuthenticatedOutlet,
          errorElement: <UnexpectedError />,
          children: [
            {
              Component: AppWrapper,
              children: [
                {
                  index: true,
                  Component: lazy(() => import('features/dashboard/Dashboard')),
                },

                {
                  path: 'optimizations',
                  Component: lazy(
                    () => import('features/optimizations/Optimizations'),
                  ),
                  children: optimizationsRoutes,
                },
                {
                  path: 'reports',
                  Component: lazy(() => import('features/reports/Reports')),
                  children: reportsRoutes,
                },
                {
                  path: 'settings',
                  Component: lazy(() => import('features/settings/Settings')),
                  children: [...settingsRoutes, ...teamRoutes],
                },
                {
                  path: 'profile',
                  Component: lazy(() => import('features/profile/Profile')),
                  children: profileRoutes,
                },
                { path: '*', Component: NotFound },
              ],
            },
          ],
        },
      ],
    },
  ],
  { basename: config.APP_BASENAME },
)
