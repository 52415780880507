import { Box, Flex } from '@chakra-ui/react'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type OptimizationChannel } from 'generated/graphql/graphql'
import { ProfitDescription } from '../ProfitDescription'

import { SingleChannelControlPanel } from './SingleChannelControlPanel'
import { SingleChannelGraph } from './SingleChannelGraph'
import { getSelectedCurveData, useSingleChannelState } from './state'

interface SingleChannelSaturationCurveProps {
  curveData: SaturationCurveData
  channels: OptimizationChannel[]
  showValuesPerDay: boolean
}

export const SingleChannelSaturationCurve: React.FC<
  SingleChannelSaturationCurveProps
> = ({ curveData, channels, showValuesPerDay }) => {
  const [state, dispatch] = useSingleChannelState(curveData)

  if (!state) return null

  const selectedCurve = getSelectedCurveData(curveData, state.channel)
  const selectedChannelName =
    channels.find((channel) => channel.id === state.channel)?.name ||
    state.channel

  const channelOptions = curveData.map((data) => ({
    id: data.channel,
    name:
      channels.find((channel) => channel.id === data.channel)?.name ||
      data.channel,
  }))

  return (
    <Flex w="full" gap={6}>
      <ProfitDescription
        selectedChannel={selectedChannelName}
        optimalSpend={selectedCurve.optimalPoint[0]}
        optimalProfit={selectedCurve.optimalPoint[1]}
        actualProfit={selectedCurve.actualPoint[1]}
        actualSpend={selectedCurve.actualPoint[0]}
        saturationProfit={selectedCurve.saturationPoint[1]}
        saturationSpend={selectedCurve.saturationPoint[0]}
        showValuesPerDay={showValuesPerDay}
        isSingleChannel
      />
      <Flex width="full" flexDir="column" overflow="auto">
        <Box mb={4} minW={400}>
          <SingleChannelControlPanel
            state={state}
            dispatch={dispatch}
            selectedCurveData={selectedCurve}
            channels={channelOptions}
          />
        </Box>

        <SingleChannelGraph
          selectedCurveData={selectedCurve}
          selectedChannel={selectedChannelName}
        />
      </Flex>
    </Flex>
  )
}
