import { type IconName } from 'components/Icon/Icon'
import {
  OPTIMIZATIONS_PAGES,
  OPTIMIZATIONS_ROUTE_FRAGMENTS,
} from 'features/optimizations/routePages'
import { REPORT_PAGES } from 'features/reports/routePages'
import { type FeatureFlags } from 'graphql/useFeatureFlags'

export const HEADER_HEIGHT = 12

interface HeaderLink {
  path: string
  label: string
  iconName: IconName
  matchPath?: string
  featureFlag?: keyof FeatureFlags
}

export const headerLinks: HeaderLink[] = [
  {
    path: '/',
    label: 'Dashboard',
    iconName: 'ChartPieIcon',
    featureFlag: 'enableDashboard',
  },
  {
    path: REPORT_PAGES.BASE,
    label: 'Reports',
    matchPath: `${REPORT_PAGES.BASE}/*`,
    iconName: 'CopyIcon',
  },
  {
    path: OPTIMIZATIONS_PAGES.BASE,
    label: 'Optimisations',
    iconName: 'DollarSignIcon',
    matchPath: `/${OPTIMIZATIONS_ROUTE_FRAGMENTS.OPTIMIZATIONS}/*`,
    featureFlag: 'enableOptimizations',
  },
  {
    path: '/settings',
    label: 'Settings',
    iconName: 'CogIcon',
    matchPath: '/settings/*',
    featureFlag: 'enableSettings',
  },
]
