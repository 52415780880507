import { useIntercom } from 'react-use-intercom'
import { intercomSelector } from 'shared/IntercomProvider/consts'
import { NavIconLink } from '../NavLinks'

export const IntercomButton = () => {
  // needed to load in intercom
  useIntercom()

  return (
    <NavIconLink
      iconName="QuestionIcon"
      id={intercomSelector}
      tooltip="Chat support"
    />
  )
}
