import { Box, Flex, GridItem, type TextProps } from '@chakra-ui/react'
import { InfoTooltip } from 'components/Tooltip/InfoTooltip'
import { Typography } from 'components/Typography'
import {
  getOptimalOptimizationMetric,
  optimizationsMetricValuesMap,
  type CompareValueType,
  type MetricOptimizationTableColumn,
  type OPTIMIZATION_METRIC,
} from 'features/optimizations/consts'
import { type SummaryData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type OptimizationSummary } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { Fragment } from 'react/jsx-runtime'
import { CellValue } from './CellValue'
import { derivedMetrics, tdStyles, totalKey } from './consts'
import { getCompareValue } from './utils'

interface Props {
  aggregatedData: Record<string, Record<OPTIMIZATION_METRIC, number>>
  summaryData: SummaryData
  summaries: OptimizationSummary[] | undefined
  compareValueType: CompareValueType
  columns: MetricOptimizationTableColumn[]
}

export const TableSummary = ({
  aggregatedData,
  summaryData,
  summaries,
  compareValueType,
  columns,
}: Props) => {
  const totalRow = useMemo(() => {
    const calculatedData = columns.reduce(
      (acc, { key }) => {
        const optimalKey = getOptimalOptimizationMetric(key)

        acc[key] = summaryData.reduce((sum, row) => {
          const value = optimizationsMetricValuesMap[key].getValue(row)

          sum += isFinite(value) ? value : 0

          return sum
        }, 0)
        acc[optimalKey] = summaryData.reduce((sum, row) => {
          const value = optimizationsMetricValuesMap[optimalKey].getValue(row)

          sum += isFinite(value) ? value : 0

          return sum
        }, 0)
        acc[key] += aggregatedData[totalKey][key]
        acc[optimalKey] += aggregatedData[totalKey][optimalKey]

        return acc
      },
      {} as Record<OPTIMIZATION_METRIC, number>,
    )

    derivedMetrics.forEach((key) => {
      calculatedData[key] =
        optimizationsMetricValuesMap[key].getValue(calculatedData)
    })

    return calculatedData
  }, [aggregatedData, summaryData, columns])

  return (
    <>
      <TotalRow
        row={aggregatedData[totalKey]}
        label="Paid marketing"
        compareValueType={compareValueType}
        columns={columns}
      />
      {summaryData?.map((row) => {
        const { name, description } =
          summaries?.find(({ id }) => id === row.id) ?? {}

        return (
          <SummaryRow
            key={String(row.id)}
            row={row}
            label={String(name)}
            description={description}
            compareValueType={compareValueType}
            columns={columns}
          />
        )
      })}
      <TotalRow
        columns={columns}
        row={totalRow}
        label="Total"
        compareValueType={compareValueType}
      />
    </>
  )
}

interface SummaryRowProps extends TextProps {
  row: Record<string, unknown>
  label: string
  description?: string
  compareValueType: CompareValueType
  columns: MetricOptimizationTableColumn[]
}
const SummaryRow = ({
  row,
  label,
  description,
  compareValueType,
  columns,
  ...rest
}: SummaryRowProps) => {
  return (
    <Fragment>
      <GridItem {...tdStyles}>
        <Flex alignItems="center" gap={1}>
          <Typography fontSize="xs" color="grey.900">
            {label}
          </Typography>
          {description && <InfoTooltip tooltipText={description} />}
        </Flex>
      </GridItem>
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map(({ key, format, includeActualValue, isHighlighted }) => {
          const historicalValue =
            optimizationsMetricValuesMap[key].getValue(row)
          const optimalValue =
            optimizationsMetricValuesMap[
              getOptimalOptimizationMetric(key)
            ].getValue(row)

          return (
            <Fragment key={key}>
              {includeActualValue && (
                <GridItem {...tdStyles}>
                  <CellValue
                    value={historicalValue}
                    format={format}
                    skipCompareValue={true}
                    {...rest}
                  />
                </GridItem>
              )}
              <GridItem {...tdStyles} p={0}>
                <Box
                  mr={6}
                  py={2}
                  pr={4}
                  pl={1.5}
                  backgroundColor={isHighlighted ? 'gray.100' : undefined}
                >
                  <CellValue
                    value={optimalValue}
                    format={format}
                    compareValue={getCompareValue(
                      compareValueType,
                      historicalValue,
                      optimalValue,
                    )}
                    compareValueType={compareValueType}
                    {...rest}
                  />
                </Box>
              </GridItem>
            </Fragment>
          )
        })}
      <GridItem {...tdStyles} />
    </Fragment>
  )
}

const TotalRow = ({
  row,
  label,
  compareValueType,
  columns,
}: SummaryRowProps) => {
  return (
    <Fragment>
      <GridItem {...tdStyles} borderWidth={0}>
        <Typography fontSize="sm" color="grey.900" fontWeight={600}>
          {label}
        </Typography>
      </GridItem>
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map(({ key, format, includeActualValue, isHighlighted }) => {
          const historicalValue =
            optimizationsMetricValuesMap[key].getValue(row)
          const optimalValue =
            optimizationsMetricValuesMap[
              getOptimalOptimizationMetric(key)
            ].getValue(row)

          return (
            <Fragment key={key}>
              {includeActualValue && (
                <GridItem
                  verticalAlign="top"
                  {...tdStyles}
                  pr={2}
                  borderWidth={0}
                  paddingLeft={8}
                >
                  <CellValue
                    value={historicalValue}
                    format={format}
                    skipCompareValue={true}
                    isBold
                  />
                </GridItem>
              )}
              <GridItem verticalAlign="top" {...tdStyles} borderWidth={0} p={0}>
                <Box
                  mr={6}
                  py={2}
                  pr={4}
                  pl={1.5}
                  backgroundColor={isHighlighted ? 'gray.100' : undefined}
                >
                  <CellValue
                    value={optimalValue}
                    format={format}
                    isBold
                    compareValue={getCompareValue(
                      compareValueType,
                      historicalValue,
                      optimalValue,
                    )}
                    compareValueType={compareValueType}
                  />
                </Box>
              </GridItem>
            </Fragment>
          )
        })}
      <GridItem {...tdStyles} borderWidth={0} />
    </Fragment>
  )
}
