import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import { useState } from 'react'
import { MessageInput } from './components/MessageInput'
import { useCreateConversation } from './graphql/useCreateConversation'
import { useCreateConversationMessage } from './graphql/useCreateConversationMessage'
import { useGetConversationById } from './graphql/useGetConversationById'
import { useCopilotIsOpen } from './state'

const CopilotSidebar = () => {
  const [isOpen, setIsOpen] = useCopilotIsOpen()
  const [conversationId, setConversationId] = useState<string | null>(null)

  const [createConversation, createConversationState] = useCreateConversation()
  const [createConversationMessage, createConversationMessageState] =
    useCreateConversationMessage()

  const isLoading =
    createConversationState.loading || createConversationMessageState.loading

  const [conversation] = useGetConversationById(conversationId)

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => setIsOpen(false)}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton top={4} right={4} />

        {/* TODO maybe revisit how we handle thesse close button styles */}
        <DrawerHeader p={4} fontSize="lg" lineHeight={8}>
          Copilot
        </DrawerHeader>

        <DrawerBody p={4}>
          {/* TODO replace this with real message UI */}
          {conversation?.messages.map((message) => (
            <Box key={message.id} bg="gray.100" p={1} mb={2}>
              {message.items[0]?.__typename === 'TextMessageItem'
                ? message.items[0]?.text
                : message.items[0]?.report.name}
            </Box>
          ))}
          {isLoading && <Box>loading...</Box>}
        </DrawerBody>
        <DrawerFooter p={4}>
          <MessageInput
            conversationId={conversationId}
            setConversationId={setConversationId}
            isLoading={isLoading}
            createConversation={createConversation}
            createConversationMessage={createConversationMessage}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default CopilotSidebar
