import { MenuButton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type ButtonProps } from 'components/buttons/Button'
import { type DropdownOption } from 'components/Dropdown'
import { useDateState } from 'features/reports/hooks/useDateState'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { forwardRef, useState } from 'react'
import { DimensionMetricAutocomplete } from './DimensionMetricAutocomplete'
import { FilterPopupPicker } from './FilterPopupPicker'

const buttonStyles: ButtonProps = {
  variant: 'outline',
  colorScheme: 'gray',
  size: 'sm',
  px: 2,
  leadingIcon: { name: 'FilterIcon', size: 'small' },
}

// forwardRef is needed for passing the button into the FilterPopover component later on
const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick }, ref) => {
    return (
      <Button ref={ref} onClick={onClick} {...buttonStyles}>
        Filter
      </Button>
    )
  },
)

ButtonComponent.displayName = 'ButtonComponent'

const MenuButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick }, ref) => {
    return (
      <MenuButton as={Button} ref={ref} onClick={onClick} {...buttonStyles}>
        Filter
      </MenuButton>
    )
  },
)

MenuButtonComponent.displayName = 'MenuButtonComponent'

const OPEN_STATE = {
  CLOSED: 'closed',
  FILTER: 'filter',
  GROUP: 'group',
} as const

export const AddFilterButton = () => {
  const [openState, setOpenState] = useState<
    (typeof OPEN_STATE)[keyof typeof OPEN_STATE]
  >(OPEN_STATE.CLOSED)
  const [selectedFilter, setSelectedFilter] = useState<
    DropdownOption | undefined
  >()
  const { dateRange } = useDateState()
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    dimensionId: String(selectedFilter?.id ?? ''),
    dateRange,
  })

  // Not that this component is currently not finished and will be changed when logic is implemented
  return openState === OPEN_STATE.CLOSED ? (
    <ButtonComponent
      onClick={() => {
        if (!selectedFilter) {
          setOpenState(OPEN_STATE.FILTER)
        } else {
          setOpenState(OPEN_STATE.GROUP)
        }
      }}
    />
  ) : !selectedFilter ? (
    <DimensionMetricAutocomplete
      isOpen={openState === OPEN_STATE.FILTER}
      onOpen={() => setOpenState(OPEN_STATE.FILTER)}
      onClose={() => {
        setOpenState(OPEN_STATE.GROUP)
      }}
      customMenuButton={<MenuButtonComponent />}
      setSelected={(item) => {
        setSelectedFilter(item)
      }}
    />
  ) : (
    <FilterPopupPicker
      isOpen={openState === OPEN_STATE.GROUP}
      onOpen={() => setOpenState(OPEN_STATE.GROUP)}
      onClose={() => {
        setOpenState(OPEN_STATE.CLOSED)
        setSelectedFilter(undefined)
      }}
      options={uniqueDimensionValues}
      isLoading={query.loading}
      filterLabel={selectedFilter.name}
      filterId={String(selectedFilter.id)}
      popupButton={<MenuButtonComponent />}
    />
  )
}
