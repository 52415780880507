import { Typography } from 'components/Typography'
import { type FC } from 'react'

interface GroupHeaderProps {
  label: string
}

export const GroupHeader: FC<GroupHeaderProps> = ({ label }) => (
  <Typography
    py={1.5}
    px={3}
    roundedTop={1}
    fontSize="sm"
    lineHeight={5}
    fontWeight={600}
    color="gray.800"
  >
    {label}
  </Typography>
)
