import { useMemo } from 'react'
import { JsonParam, StringParam, useQueryParams } from 'use-query-params'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { URL_PARAM_KEYS } from '../consts'
import { type ChartSerie, type ChartState } from '../utils/chart/types'
import {
  getDefaultGColor,
  getDefaultGType,
  getDefaultSeries,
  getDefaultXAxis,
  getDefaultYAxis,
} from '../utils/utils'
import { useDateState } from './useDateState'
import { useDimensionsState } from './useDimensionsState'
import { useMetricsState } from './useMetricsState'
import { useReportStateAtom } from './useReportState'

export const chartStateParamConfigMap = {
  [URL_PARAM_KEYS.X_AXIS]: StringParam,
  [URL_PARAM_KEYS.COLOR]: StringParam,
  [URL_PARAM_KEYS.SERIES]: JsonParam,
}

export const useChartState = (): [ChartState, typeof setChartState] => {
  const [chartState, setChartState] = useQueryParams(chartStateParamConfigMap)
  const { metricKeys: metrics } = useMetricsState()
  const { dimensionKeys: dimensions } = useDimensionsState()
  const { isCompare } = useDateState()
  const report = useReportStateAtom()

  const validState: ChartState = useMemo(() => {
    const { xAxis, gColor, gSeries } = chartState
    const {
      color: reportColor,
      xAxis: reportXAxis,
      series: reportSeries,
    } = report?.chart ?? {}

    const validType = getDefaultGType(undefined)
    const validYAxis = getDefaultYAxis(undefined, metrics)

    // This is only added to support retro compatibility and should be removed when going live and updating existing reports
    const retroCompatibleSeries: ChartSerie[] = [
      { key: validYAxis, type: validType },
    ]

    const validSeries = getDefaultSeries(
      gSeries ?? reportSeries ?? retroCompatibleSeries,
      metrics,
    )
    const isScatterPlot =
      validSeries.length === 1 && validSeries[0].type === CHART_TYPE_ID.SCATTER

    const validXAxis = getDefaultXAxis(
      xAxis ?? reportXAxis,
      isScatterPlot,
      dimensions,
      metrics,
    )
    // We set null explicitly when the user removes it and we don't want to have a default fallback then
    const validGroup =
      gColor === null
        ? undefined
        : getDefaultGColor(
            gColor ?? reportColor,
            dimensions,
            validXAxis,
            isCompare,
            validSeries.length > 1,
          )

    return {
      xAxis: validXAxis,
      color: validGroup,
      series: validSeries,
    }
  }, [chartState, report?.chart, dimensions, metrics, isCompare])

  return [validState, setChartState]
}
