import DemaDIcon from './custom-icons/Dema-D.svg?react'
import ActualLegendDotIcon from './custom-icons/legend/actual-legend-dot.svg?react'
import ComparisonLegendDotIcon from './custom-icons/legend/comparison-legend-dot.svg?react'
import SolidLegendColumnIcon from './custom-icons/legend/solid-legend-column.svg?react'
import SolidLegendLineIcon from './custom-icons/legend/solid-legend-line.svg?react'
import StripedLegendColumnIcon from './custom-icons/legend/striped-legend-column.svg?react'
import StripedLegendLineIcon from './custom-icons/legend/striped-legend-line.svg?react'

import LoaderIcon from './custom-icons/loader.svg?react'

import AreaWideIcon from './graph-icons/area-wide.svg?react'
import AreaIcon from './graph-icons/area.svg?react'
import BarWideIcon from './graph-icons/bar-wide.svg?react'
import BarIcon from './graph-icons/bar.svg?react'
import ColumnWideIcon from './graph-icons/column-wide.svg?react'
import ColumnIcon from './graph-icons/column.svg?react'
import LineWideIcon from './graph-icons/line-wide.svg?react'
import LineIcon from './graph-icons/line.svg?react'
import PieWideIcon from './graph-icons/pie-wide.svg?react'
import PieIcon from './graph-icons/pie.svg?react'
import ScatterWideIcon from './graph-icons/scatter-wide.svg?react'
import ScatterIcon from './graph-icons/scatter.svg?react'
import SplineWideIcon from './graph-icons/spline-wide.svg?react'
import SplineIcon from './graph-icons/spline.svg?react'
import StackedAreaWideIcon from './graph-icons/stacked-area-wide.svg?react'
import StackedAreaIcon from './graph-icons/stacked-area.svg?react'
import StackedBarWideIcon from './graph-icons/stacked-bar-wide.svg?react'
import StackedBarIcon from './graph-icons/stacked-bar.svg?react'
import StackedColumnWideIcon from './graph-icons/stacked-column-wide.svg?react'
import StackedColumnIcon from './graph-icons/stacked-column.svg?react'

import ArchiveIcon from './streamline/Archive.svg?react'
import ArrowDownCircleIcon from './streamline/Arrow-down-circle.svg?react'
import ArrowDownIcon from './streamline/Arrow-down.svg?react'
import ArrowLeftIcon from './streamline/Arrow-left.svg?react'
import ArrowRightIcon from './streamline/Arrow-right.svg?react'
import ArrowSplitDownIcon from './streamline/Arrow-split-down.svg?react'
import ArrowUpCircleIcon from './streamline/Arrow-up-circle.svg?react'
import ArrowUpIcon from './streamline/Arrow-up.svg?react'
import BarChartIcon from './streamline/Bar-chart.svg?react'
import BulletListIcon from './streamline/Bullet-list.svg?react'
import CalendarAddIcon from './streamline/Calendar-add.svg?react'
import CalendarDayIcon from './streamline/Calendar-day.svg?react'
import CalendarDaysIcon from './streamline/Calendar-days.svg?react'
import CalendarEditIcon from './streamline/Calendar-edit.svg?react'
import ChartAreaStackedIcon from './streamline/Chart-area-stacked.svg?react'
import ChartAreaIcon from './streamline/Chart-area.svg?react'
import ChartBarStackedIcon from './streamline/Chart-bar-stacked.svg?react'
import ChartBarThinIcon from './streamline/Chart-bar-thin.svg?react'
import ChartBarIcon from './streamline/Chart-bar.svg?react'
import ChartColumnStackedIcon from './streamline/Chart-column-stacked.svg?react'
import ChartColumnThinIcon from './streamline/Chart-column-thin.svg?react'
import ChartColumnIcon from './streamline/Chart-column.svg?react'
import ChartLineIcon from './streamline/Chart-line.svg?react'
import ChartPieIcon from './streamline/Chart-pie.svg?react'
import ChartScatterplotIcon from './streamline/Chart-scatterplot.svg?react'
import ChartSplineIcon from './streamline/Chart-spline.svg?react'
import ChatBubbleIcon from './streamline/Chat-bubble.svg?react'
import CheckCircleIcon from './streamline/Check-circle.svg?react'
import CheckIcon from './streamline/Check.svg?react'
import ChevronDoubleLeftIcon from './streamline/Chevron-double-left.svg?react'
import ChevronDoubleRightIcon from './streamline/Chevron-double-right.svg?react'
import ChevronDownIcon from './streamline/Chevron-down.svg?react'
import ChevronLeftIcon from './streamline/Chevron-left.svg?react'
import ChevronRightIcon from './streamline/Chevron-right.svg?react'
import ChevronUpIcon from './streamline/Chevron-up.svg?react'
import ClockIcon from './streamline/Clock.svg?react'
import CloseIcon from './streamline/Close.svg?react'
import CogIcon from './streamline/Cog.svg?react'
import Copy2Icon from './streamline/Copy-2.svg?react'
import CopyIcon from './streamline/Copy.svg?react'
import CustomerSupportIcon from './streamline/Customer-support.svg?react'
import DataAnalytics from './streamline/Data-analytics.svg?react'
import DatabaseIcon from './streamline/Database.svg?react'
import DiamondIcon from './streamline/Diamond.svg?react'
import DollarCoinIcon from './streamline/Dollar-coin.svg?react'
import DollarSignIcon from './streamline/Dollar-sign.svg?react'
import DownloadIcon from './streamline/Download.svg?react'
import EarthIcon from './streamline/Earth.svg?react'
import EditIcon from './streamline/Edit.svg?react'
import ExclamationCircleIcon from './streamline/Exclamation-circle.svg?react'
import ExclamationTriangleIcon from './streamline/Exclamation-triangle.svg?react'
import FilterIcon from './streamline/Filter.svg?react'
import FirstPageIcon from './streamline/First-page.svg?react'
import Flower1Icon from './streamline/Flower-1.svg?react'
import Flower2Icon from './streamline/Flower-2.svg?react'
import FolderAddIcon from './streamline/Folder-add.svg?react'
import FolderNewIcon from './streamline/Folder-new.svg?react'
import FolderOpenIcon from './streamline/Folder-open.svg?react'
import FreezeColumnIcon from './streamline/Freeze-column.svg?react'
import GraphSettingsIcon from './streamline/Graph-settings.svg?react'
import HamburgerMenuIcon from './streamline/Hamburger-menu.svg?react'
import HashtagIcon from './streamline/Hashtag.svg?react'
import HeartIcon from './streamline/Heart.svg?react'
import HomeGearIcon from './streamline/Home-gear.svg?react'
import HomeIcon from './streamline/Home.svg?react'
import HorizontalMenuIcon from './streamline/Horizontal-menu.svg?react'
import InformationCircleIcon from './streamline/Information-circle.svg?react'
import IntegrationsIcon from './streamline/Integrations.svg?react'
import KeyIcon from './streamline/Key.svg?react'
import LastPageIcon from './streamline/Last-page.svg?react'
import LeafIcon from './streamline/Leaf.svg?react'
import LightbulbIcon from './streamline/Lightbulb.svg?react'
import LikeIcon from './streamline/Like.svg?react'
import LinkExternalIcon from './streamline/Link-exteral.svg?react'
import LinkIcon from './streamline/Link.svg?react'
import ListIcon from './streamline/List.svg?react'
import LogoutIcon from './streamline/Logout.svg?react'
import MagnifyingGlassIcon from './streamline/Magnifying-glass.svg?react'
import MapPinIcon from './streamline/Map-pin.svg?react'
import MegaphoneIcon from './streamline/Megaphone.svg?react'
import Number0Icon from './streamline/Number-0.svg?react'
import Number1Icon from './streamline/Number-1.svg?react'
import Number2Icon from './streamline/Number-2.svg?react'
import Number3Icon from './streamline/Number-3.svg?react'
import Number4Icon from './streamline/Number-4.svg?react'
import Number5Icon from './streamline/Number-5.svg?react'
import Number6Icon from './streamline/Number-6.svg?react'
import Number7Icon from './streamline/Number-7.svg?react'
import Number8Icon from './streamline/Number-8.svg?react'
import Number9Icon from './streamline/Number-9.svg?react'
import OfficeIcon from './streamline/Office.svg?react'
import PadlockOpenIcon from './streamline/Padlock-open.svg?react'
import PadlockIcon from './streamline/Padlock.svg?react'
import Percent1Icon from './streamline/Percent-1.svg?react'
import Percent2Icon from './streamline/Percent-2.svg?react'
import PiggyBankIcon from './streamline/Piggy-bank.svg?react'
import PinDisabledIcon from './streamline/Pin-disabled.svg?react'
import PinIcon from './streamline/Pin.svg?react'
import PlusSmallIcon from './streamline/Plus-small.svg?react'
import PlusIcon from './streamline/Plus.svg?react'
import QuestionIcon from './streamline/Question.svg?react'
import ReorderIcon from './streamline/Reorder.svg?react'
import ReportIcon from './streamline/Report.svg?react'
import ReturnIcon from './streamline/Return.svg?react'
import RoadmapIcon from './streamline/Roadmap.svg?react'
import RobotIcon from './streamline/Robot.svg?react'
import RocketIcon from './streamline/Rocket.svg?react'
import RulerIcon from './streamline/Ruler.svg?react'
import SendIcon from './streamline/Send.svg?react'
import ShippingBoxIcon from './streamline/Shipping-box.svg?react'
import ShippingTruckIcon from './streamline/Shipping-truck.svg?react'
import ShoppingBagIcon from './streamline/Shopping-bag.svg?react'
import ShuffleIcon from './streamline/Shuffle.svg?react'
import SlidersIcon from './streamline/Sliders.svg?react'
import StarFilledIcon from './streamline/Star-filled.svg?react'
import StarIcon from './streamline/Star.svg?react'
import StopIcon from './streamline/Stop.svg?react'
import SuitcaseIcon from './streamline/Suitcase.svg?react'
import TableIcon from './streamline/Table.svg?react'
import TagIcon from './streamline/Tag.svg?react'
import ToggleHorizontalIcon from './streamline/Toggle-horizontal.svg?react'
import TrashIcon from './streamline/Trash.svg?react'
import TreeIcon from './streamline/Tree.svg?react'
import UserCircleIcon from './streamline/User-circle.svg?react'
import UserMultipleCircleIcon from './streamline/User-multiple-circle.svg?react'
import UserMultipleCollaborateIcon from './streamline/User-multiple-collaborate.svg?react'
import UserMultipleIcon from './streamline/User-multiple.svg?react'
import UserSettingsIcon from './streamline/User-settings.svg?react'
import UserIcon from './streamline/User.svg?react'
import VisibleIcon from './streamline/Visible.svg?react'
import WalletIcon from './streamline/Wallet.svg?react'

const customIcons = {
  DemaDIcon,
  LoaderIcon,
  ActualLegendDotIcon,
  ComparisonLegendDotIcon,
  SolidLegendColumnIcon,
  SolidLegendLineIcon,
  StripedLegendColumnIcon,
  StripedLegendLineIcon,
}

const streamlineIcons = {
  ArchiveIcon,
  ArrowDownCircleIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowSplitDownIcon,
  ArrowUpCircleIcon,
  ArrowUpIcon,
  BarChartIcon,
  BulletListIcon,
  CalendarAddIcon,
  CalendarDayIcon,
  CalendarDaysIcon,
  CalendarEditIcon,
  ChartAreaStackedIcon,
  ChartAreaIcon,
  ChartBarStackedIcon,
  ChartBarThinIcon,
  ChartBarIcon,
  ChartColumnStackedIcon,
  ChartColumnThinIcon,
  ChartColumnIcon,
  ChartLineIcon,
  ChartPieIcon,
  ChartScatterplotIcon,
  ChartSplineIcon,
  ChatBubbleIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClockIcon,
  CloseIcon,
  CogIcon,
  Copy2Icon,
  CopyIcon,
  CustomerSupportIcon,
  DataAnalytics,
  DatabaseIcon,
  DiamondIcon,
  DollarCoinIcon,
  DollarSignIcon,
  DownloadIcon,
  EarthIcon,
  EditIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  FilterIcon,
  FirstPageIcon,
  Flower1Icon,
  Flower2Icon,
  FolderAddIcon,
  FolderNewIcon,
  FolderOpenIcon,
  FreezeColumnIcon,
  GraphSettingsIcon,
  HamburgerMenuIcon,
  HashtagIcon,
  HeartIcon,
  HomeGearIcon,
  HomeIcon,
  HorizontalMenuIcon,
  InformationCircleIcon,
  IntegrationsIcon,
  KeyIcon,
  LastPageIcon,
  LeafIcon,
  LightbulbIcon,
  LikeIcon,
  LinkExternalIcon,
  LinkIcon,
  ListIcon,
  LogoutIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  MegaphoneIcon,
  Number0Icon,
  Number1Icon,
  Number2Icon,
  Number3Icon,
  Number4Icon,
  Number5Icon,
  Number6Icon,
  Number7Icon,
  Number8Icon,
  Number9Icon,
  OfficeIcon,
  PadlockOpenIcon,
  PadlockIcon,
  Percent1Icon,
  Percent2Icon,
  PiggyBankIcon,
  PinDisabledIcon,
  PinIcon,
  PlusSmallIcon,
  PlusIcon,
  QuestionIcon,
  ReorderIcon,
  ReportIcon,
  ReturnIcon,
  RoadmapIcon,
  RobotIcon,
  RocketIcon,
  RulerIcon,
  SendIcon,
  ShippingBoxIcon,
  ShippingTruckIcon,
  ShoppingBagIcon,
  ShuffleIcon,
  SlidersIcon,
  StarFilledIcon,
  StarIcon,
  SuitcaseIcon,
  StopIcon,
  TableIcon,
  TagIcon,
  ToggleHorizontalIcon,
  TrashIcon,
  TreeIcon,
  UserCircleIcon,
  UserMultipleCircleIcon,
  UserMultipleCollaborateIcon,
  UserMultipleIcon,
  UserSettingsIcon,
  UserIcon,
  VisibleIcon,
  WalletIcon,
} as const

export const graphIcons = {
  AreaWideIcon,
  AreaIcon,
  BarWideIcon,
  BarIcon,
  ColumnWideIcon,
  ColumnIcon,
  LineWideIcon,
  LineIcon,
  PieWideIcon,
  PieIcon,
  ScatterWideIcon,
  ScatterIcon,
  SplineWideIcon,
  SplineIcon,
  StackedAreaWideIcon,
  StackedAreaIcon,
  StackedBarWideIcon,
  StackedBarIcon,
  StackedColumnWideIcon,
  StackedColumnIcon,
} as const

export const icons = { ...customIcons, ...streamlineIcons, ...graphIcons }
