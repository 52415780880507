import {
  type TimeUnit,
  type NormalizedStatistic,
} from 'graphql/statistics/types'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { toISODateRange } from 'utils/dateRange'
import { useFiltersStateAtom } from './filters/useFiltersStateAtom'
import { useDateState } from './useDateState'
import { useDimensionsState } from './useDimensionsState'
import { useMetricsState } from './useMetricsState'

type UseReportsAnalyticsResponse = {
  data: NormalizedStatistic[]
  aggregatedData: NormalizedStatistic[]
  loading: boolean
}

export const useReportsAnalytics = (): UseReportsAnalyticsResponse => {
  const { timezone, currency, frontendIds = [] } = useMerchantInfo()
  const { dateRange, compareDateRange } = useDateState()
  const [startDate, endDate] = toISODateRange(dateRange, timezone)
  const [compareStartDate, compareEndDate] = toISODateRange(
    compareDateRange,
    timezone,
  )
  const [filters] = useFiltersStateAtom()
  const { metricKeys: metrics } = useMetricsState()
  const { dimensionKeys: dimensions } = useDimensionsState()

  const timeUnit: TimeUnit = 'total'

  const shouldFetch =
    Boolean(timezone) &&
    Boolean(currency) &&
    Boolean(startDate) &&
    Boolean(endDate) &&
    frontendIds.length > 0 &&
    dimensions.length > 0 &&
    metrics.length > 0

  const { query: dataQuery, statistics: data } = useStatistics({
    skip: !shouldFetch,
    variables: {
      statisticsParams: {
        frontendIds,
        startDate: startDate as string,
        endDate: endDate as string,
        compareStartDate: compareStartDate as string,
        compareEndDate: compareEndDate as string,
        dimensions,
        metrics,
        timeUnit,
        filter: filters,
      },
    },
  })

  const { query: aggregatedDataQuery, statistics: aggregatedData } =
    useStatistics({
      skip: !shouldFetch,
      variables: {
        statisticsParams: {
          frontendIds,
          startDate: startDate as string,
          endDate: endDate as string,
          compareStartDate: compareStartDate as string,
          compareEndDate: compareEndDate as string,
          dimensions,
          metrics,
          aggregateDimensions: [],
          timeUnit,
          filter: filters,
        },
      },
    })

  return {
    data,
    aggregatedData,
    loading: dataQuery.loading || aggregatedDataQuery.loading,
  }
}
