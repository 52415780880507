import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { BaseView } from '../../shared/BaseView'
import { teamIconColors, teamIconNames } from '../consts'
import { useCreateTeam } from '../graphql/useCreateTeam'
import { IconAndNameForm } from '../shared/IconAndNameForm'

export const CreateNewTeamView: React.FC = () => {
  const [teamName, setTeamName] = useState('')
  const [icon, setIcon] = useState({
    name: teamIconNames[0],
    color: teamIconColors[0],
  })
  const [createTeamMutation, createTeamState] = useCreateTeam()
  const navigate = useNavigate()

  return (
    <BaseView title="Create new team">
      <IconAndNameForm
        teamName={teamName}
        setTeamName={setTeamName}
        icon={icon}
        setIcon={setIcon}
        boxProps={{ mb: 12 }}
      />

      <Flex gap={2}>
        <Button
          size="sm"
          colorScheme="gray"
          variant="outline"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          isLoading={createTeamState.loading}
          isDisabled={!teamName}
          onClick={() => {
            createTeamMutation({
              iconName: icon.name,
              iconColor: icon.color,
              name: teamName,
            })
          }}
        >
          Create team
        </Button>
      </Flex>
    </BaseView>
  )
}
