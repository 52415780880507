import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'
import type { HistoricalHistoryContribution } from '../../../hooks/useHistoricalHistoryAnalysis'
import { getChart } from './getChart'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)

interface ChannelContributionProps {
  contributions: HistoricalHistoryContribution[]
}

export const ChannelContribution: React.FC<ChannelContributionProps> = ({
  contributions,
}) => {
  const { currency } = useMerchantInfo()

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChart({
        contributions,
        currency: currency,
      })}
      containerProps={{ style: { width: '100%' } }}
    />
  )
}
