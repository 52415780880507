import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { ClerkProvider } from '@clerk/clerk-react'
import { type Clerk } from '@clerk/types'
import { ErrorBoundary } from '@sentry/react'
import { createApolloClient } from 'apollo'
import config from 'config'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { UnexpectedError } from 'shared'
import { router } from 'shared/Routes'
import 'styles/index.scss'
import { initializeSentry } from 'utils/sentry'
import reportWebVitals from './reportWebVitals'
import { theme } from './ui/theme'

initializeSentry()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log(`Running app on version: %c${config.APP_VERSION}`, 'color: #4760c6')

// Clerk populates window object and we can use this to access Clerk without hooks
declare global {
  interface Window {
    Clerk?: Clerk
  }
}

root.render(
  <ClerkProvider publishableKey={config.CLERK_KEY}>
    <ApolloProvider client={createApolloClient()}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary fallback={<UnexpectedError />}>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </ChakraProvider>
    </ApolloProvider>
  </ClerkProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
