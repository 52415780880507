import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type TaxonomyInput } from '../../types'
import { TAXONOMY_INPUTS_QUERY } from './useTaxonomyInputs'

const CREATE_TAXONOMY_INPUT_MUTATION = graphql(/* GraphQL */ `
  mutation CreateTaxonomyInputMutation($params: CreateTaxonomyInput!) {
    createTaxonomyInput(params: $params) {
      ...TaxonomyInputFields
    }
  }
`)

type CreateTaxonomyInputParams = {
  frontendId: string
  taxonomyInput: TaxonomyInput
}

export const useCreateTaxonomyInput = (): [
  typeof createTaxonomyInput,
  typeof state,
] => {
  const [mutation, state] = useMutation(CREATE_TAXONOMY_INPUT_MUTATION, {
    refetchQueries: [TAXONOMY_INPUTS_QUERY],
  })

  const createTaxonomyInput = ({
    frontendId,
    taxonomyInput,
  }: CreateTaxonomyInputParams) => {
    const newTaxonomyInput = {
      type: taxonomyInput.type,
      rootMatcher: taxonomyInput.rootMatcher,
    }

    return mutation({
      variables: {
        params: { frontendId, ...newTaxonomyInput },
      },
    })
  }

  return [createTaxonomyInput, state]
}
