import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type HistoricalHistoryAnalysisQueryQuery } from 'generated/graphql/graphql'
import { useHistoricalAnalysisFiltersAtom } from '../components/Settings/Filter'

const HISTORICAL_HISTORY_ANALYSIS_QUERY = graphql(/* GraphQL */ `
  query HistoricalHistoryAnalysisQuery(
    $historicalHistoryAnalysisInput: HistoricalHistoryAnalysisInput!
  ) {
    viewer {
      id
      merchant {
        id
        historicalHistoryAnalysis(params: $historicalHistoryAnalysisInput) {
          historicalContributions
          historicalReport
        }
      }
    }
  }
`)

export interface HistoricalHistoryContribution {
  date: string
  contributions: {
    id: string
    name: string
    ngp3: number
  }[]
}

export interface HistoricalHistoryReport {
  startDate: string
  endDate: string
  spend: number
  'spend:optimal': number
  sales: number
  'sales:optimal': number
  ngp3: number
  'ngp3:optimal': number
}

export type HistoricalHistoryAnalysis = Omit<
  NonNullable<
    HistoricalHistoryAnalysisQueryQuery['viewer']
  >['merchant']['historicalHistoryAnalysis'],
  'historicalContributions' | 'historicalReport'
> & {
  historicalContributions: HistoricalHistoryContribution[]
  historicalReport: HistoricalHistoryReport[]
}

export const useHistoricalHistoryAnalysis = ({
  market,
}: {
  market: string
}) => {
  const [filters] = useHistoricalAnalysisFiltersAtom()
  const selectedSegment = filters.segment.selected

  const { data, ...query } = useQuery(HISTORICAL_HISTORY_ANALYSIS_QUERY, {
    skip: !selectedSegment,
    variables: {
      historicalHistoryAnalysisInput: {
        segment: selectedSegment ?? '',
        market,
      },
    },
  })

  return {
    // Selected segment gest loaded in the config query
    // This avoids race conditions when segment is not yet loaded
    // so we show a a loading state for that time
    query: { ...query, loading: query.loading || !selectedSegment },
    data: data?.viewer?.merchant?.historicalHistoryAnalysis as
      | HistoricalHistoryAnalysis
      | undefined,
  }
}
