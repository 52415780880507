import { Box, GridItem } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import {
  getOptimalOptimizationMetric,
  optimizationsMetricValuesMap,
  type CompareValueType,
  type MetricOptimizationTableColumn,
  type OptimizationTableColumn,
} from 'features/optimizations/consts'
import { type ContributionData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { Fragment } from 'react/jsx-runtime'
import { CellValue } from './CellValue'
import { tdStyles } from './consts'
import { type GroupedChannel } from './types'
import { getCompareValue } from './utils'

interface Props {
  channel: string
  groups: GroupedChannel[]
  contribution: ContributionData | undefined
  compareValueType: CompareValueType
  columns: OptimizationTableColumn[]
}

export const TableBodyGroup = ({
  channel,
  groups,
  contribution,
  compareValueType,
  columns,
}: Props) => {
  return (
    <>
      {groups.map(({ funnel, id }) => {
        const channelContribution = contribution?.find(
          (item) => item.channel === id,
        )
        const channelFunnelName = `${channel} ${funnel}`

        return (
          <Fragment key={funnel}>
            <GridItem {...tdStyles} minW="150px">
              <Typography
                fontSize="xs"
                color="grey.900"
                isTruncated
                width="full"
                display="block"
                title={channelFunnelName}
              >
                {channelFunnelName}
              </Typography>
            </GridItem>

            <FunnelRowData
              columns={columns}
              channelContribution={channelContribution}
              compareValueType={compareValueType}
            />
          </Fragment>
        )
      })}
    </>
  )
}

const FunnelRowData = ({
  channelContribution,
  compareValueType,
  columns,
}: {
  channelContribution: ContributionData[number] | undefined
  compareValueType: CompareValueType
  columns: OptimizationTableColumn[]
}) => {
  return (
    <>
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map((column) => {
          if (column.isMetric) {
            return (
              <MetricColumn
                key={column.key}
                column={column}
                compareValueType={compareValueType}
                channelContribution={channelContribution}
              />
            )
          }

          return (
            <GridItem {...tdStyles} key={column.key} alignContent="center">
              <column.CellComponent channelContribution={channelContribution} />
            </GridItem>
          )
        })}
    </>
  )
}

interface MetricColumnProps {
  channelContribution: ContributionData[number] | undefined
  column: MetricOptimizationTableColumn
  compareValueType: CompareValueType
}

const MetricColumn = ({
  channelContribution,
  column: { key, includeActualValue, format, isHighlighted },
  compareValueType,
}: MetricColumnProps) => {
  let historicalValue = channelContribution
    ? optimizationsMetricValuesMap[key].getValue(channelContribution)
    : NaN

  historicalValue = isFinite(historicalValue) ? historicalValue : 0
  const scenarioValue = channelContribution
    ? optimizationsMetricValuesMap[getOptimalOptimizationMetric(key)].getValue(
        channelContribution,
      )
    : NaN

  return (
    <Fragment key={key}>
      {includeActualValue && (
        <GridItem {...tdStyles} pr={2}>
          <CellValue value={historicalValue} format={format} skipCompareValue />
        </GridItem>
      )}
      <GridItem {...tdStyles} p={0}>
        <Box
          mr={6}
          py={2}
          pr={4}
          pl={1.5}
          bg={isHighlighted ? 'gray.100' : undefined}
          h="full"
        >
          <CellValue
            value={scenarioValue}
            compareValue={getCompareValue(
              compareValueType,
              historicalValue,
              scenarioValue,
            )}
            compareValueType={compareValueType}
            format={format}
          />
        </Box>
      </GridItem>
    </Fragment>
  )
}
