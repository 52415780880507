import * as Sentry from '@sentry/react'
import config from 'config'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'

// Breadcrumbs for GraphQL are logged in Apollo Link
const excludeGraphQLFetch: NonNullable<
  Sentry.BrowserOptions['beforeBreadcrumb']
> = (breadcrumb) => {
  if (breadcrumb.category === 'fetch') {
    const url: string = breadcrumb.data?.url ?? ''

    if (url.includes('/graphql')) {
      return null
    }
  }

  return breadcrumb
}

export const initializeSentry = () => {
  // No need to send sentry events from dev or test environments
  if (!['staging', 'production'].includes(config.APP_ENV)) {
    return
  }

  Sentry.init({
    // debug: true, // enable for debugging
    dsn: config.SENTRY_DSN_KEY,
    environment: config.APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.breadcrumbsIntegration({
        console: true,
      }),
      Sentry.globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: true,
      }),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [config.API_URL, config.COPILOT_URL],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    beforeBreadcrumb: excludeGraphQLFetch,
  })
}

export const setSentryUser = (properties: Record<string, unknown>) => {
  Sentry.getCurrentScope().setUser(properties)
}
