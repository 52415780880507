import {
  type ButtonProps,
  MenuButton,
  type As,
  forwardRef,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type ReportLabel } from 'generated/graphql/graphql'
import { LabelIcon } from './LabelIcon'

type Props = {
  label?: Omit<ReportLabel, 'reportCount'>
  isDisabled?: boolean
}

const emptyButtonStyles: ButtonProps = {
  size: 'sm',
  colorScheme: 'gray',
  variant: 'ghost',
  px: 2,
}

const nonEmptyButtonStyles: ButtonProps = {
  size: 'xs',
  colorScheme: 'white',
  variant: 'outline',
  borderColor: 'grey.300',
  _disabled: {
    color: 'grey.300',
    cursor: 'not-allowed', // TODO find a way to merge disabled styles from chakra ui
  },
}

export const LabelsMenuButton = forwardRef<Props, As>(
  ({ label, isDisabled }, ref) => {
    return (
      <MenuButton
        ref={ref}
        as={Button}
        isDisabled={isDisabled}
        borderRadius={2}
        display="flex"
        alignItems="center"
        overflowX="hidden"
        textOverflow="ellipsis"
        maxW="fit-content"
        onClick={(e) => e.stopPropagation()}
        leadingComponent={
          label ? (
            <LabelIcon color={label.color} />
          ) : (
            <Icon size="small" name="TagIcon" />
          )
        }
        _hover={isDisabled ? undefined : { bg: 'grey.100' }}
        {...(label ? nonEmptyButtonStyles : emptyButtonStyles)}
      >
        {label ? (
          <Typography fontSize="xs" color="inherit" lineHeight={4}>
            {label.name}
          </Typography>
        ) : (
          'Add label'
        )}
      </MenuButton>
    )
  },
)
