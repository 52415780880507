import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import type { ResultOf } from '@graphql-typed-document-node/core'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { type DropdownOption } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import { graphql } from 'generated/graphql'
import {
  type IntegrationFieldsFragmentDoc,
  type MarketingCostsViewQueryQuery,
} from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { costTypes } from '../AddMarketingCostSidebar/consts'
import { type TaxonomyDataType } from '../AddMarketingCostSidebar/types'
import { RemoveButton } from './RemoveButton'

const marketingCostDimensions = [
  { id: 'country', name: 'Country' },
  { id: 'channel', name: 'Channel' },
  { id: 'channelGroup', name: 'Channel group' },
  { id: 'campaign', name: 'Campaign' },
]

const normalizedMarketingCostDimensions = marketingCostDimensions.reduce<
  Record<string, DropdownOption>
>((acc, next) => {
  acc[next.id] = next

  return acc
}, {})

type Integration = ResultOf<typeof IntegrationFieldsFragmentDoc>
type ManualInputIntegrationConfigDimensions = {
  channelGroup?: string
  channel?: string
  country?: string
  campaign?: string
}

const normalizedTaxonomyDataTypeOptions = costTypes.reduce(
  (acc, next) => {
    acc[next.id as TaxonomyDataType] = next

    return acc
  },
  {} as Record<TaxonomyDataType, DropdownOption>,
)

const columnHelper = createColumnHelper<Integration>()

const columns = [
  columnHelper.accessor('config.dimensions', {
    cell: ({ getValue }) => {
      return (
        <Flex flexDir="column" gap="4px">
          {Object.entries(
            getValue() as ManualInputIntegrationConfigDimensions,
          ).map(([key, value]) => (
            <Flex key={`${key}:${value}`}>
              <Typography fontSize="xs" w="100px">
                {normalizedMarketingCostDimensions[key]?.name}:{' '}
              </Typography>
              <Typography noOfLines={1}>{value}</Typography>
            </Flex>
          ))}
        </Flex>
      )
    },
    header: 'Dimensions',
  }),
  columnHelper.accessor('config.calculationType', {
    cell: ({ getValue }) => {
      const value = getValue() as TaxonomyDataType

      return normalizedTaxonomyDataTypeOptions[value]?.name ?? value
    },
    header: 'Cost type',
  }),
  columnHelper.accessor('config.amount', {
    cell: ({ getValue }) => getValue(),
    header: 'Value',
  }),
  columnHelper.accessor('config.currency', {
    cell: ({ getValue }) => getValue(),
    header: 'Currency',
  }),
  columnHelper.accessor('config.startDate', {
    cell: ({ getValue }) => getValue(),
    header: 'Start date',
  }),
  // End date key is not always available
  columnHelper.accessor('config', {
    cell: ({ getValue }) => getValue()?.endDate ?? '-',
    header: 'End date',
  }),
  columnHelper.accessor('id', {
    cell: ({ getValue }) => <RemoveButton integrationId={getValue()} />,
    id: 'removeButton',
    header: 'Remove',
  }),
]

export const MarketingCostManualInputs_MerchantSiteFragment = graphql(
  /* GraphQL */ `
    fragment MarketingCostManualInputs_MerchantSite on MerchantSite {
      marketingCostManualInputs {
        ...IntegrationFields
      }
    }
  `,
)

type MarketingCostTableProps = {
  merchantSite: NonNullable<
    MarketingCostsViewQueryQuery['viewer']
  >['merchantSite']
  isReadOnly: boolean
}

export const MarketingCostTable = ({
  merchantSite,
  isReadOnly,
}: MarketingCostTableProps) => {
  const integrations = useMemo(
    () =>
      merchantSite?.marketingCostManualInputs?.filter(
        (integration) => integration.connected,
      ) ?? [],
    [merchantSite],
  )

  const filteredColumns = useMemo(() => {
    return isReadOnly
      ? columns.filter((column) => column.id !== 'removeButton')
      : columns
  }, [isReadOnly])

  const table = useReactTable({
    data: integrations,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableContainer>
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header as string,
                      header.getContext(),
                    )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} maxW="360px">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
