import { type As, type InputProps, forwardRef } from '@chakra-ui/react'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Input } from 'components/Input'
import { type FC } from 'react'
import { FilterGroup } from './shared/FilterGroup/FilterGroup'
import { GroupHeader } from './shared/GroupHeader'
import {
  FilterOperator,
  type AddFilterArgsBase,
  type BaseFilterProps,
} from './types'
import { useSelectedFilterGroup } from './useSelectedFilterGroup'

export type AddFilterArgsMultiSelectSearch = AddFilterArgsBase & {
  value: string | string[]
}

type Props = BaseFilterProps & {
  addFilter?: (filter: AddFilterArgsMultiSelectSearch) => void
}

const FilterGroupOptions = [
  FilterOperator.isAnyOf,
  FilterOperator.isNoneOf,
  FilterOperator.contains,
  FilterOperator.doesNotContain,
]

export const AdvancedMultiSelectFilter: FC<Props> = ({
  filterLabel,
  filterId,
  options,
  defaultSelectedFilter,
  selectedOptions,
  isOpen,
  onOpen,
  onClose,
  isOpenByDefault = false,
  isLoading,
  addFilter,
  removeFilter,
  popupButton,
}) => {
  const { selectedFilterGroup, setSelectedFilterGroup } =
    useSelectedFilterGroup({
      filterId,
      defaultSelectedFilter,
      fallback: FilterOperator.isAnyOf,
    })

  const isContainsFilter = getIsContainsFilter(selectedFilterGroup)

  const updateFilterSelection = (
    newSelected: string | string[] | undefined,
  ) => {
    // the user deselected all options, so we need to remove this filter
    if (!newSelected || newSelected?.length === 0) {
      removeFilter?.(filterId)

      return
    }

    // The condition checks if either:
    // - isContainsFilter is true AND draftSelectedOptions is an array, OR
    // - isContainsFilter is false AND draftSelectedOptions is not an array.
    const shouldRemoveFilter = isContainsFilter === Array.isArray(newSelected)

    if (shouldRemoveFilter) {
      removeFilter?.(filterId)

      return
    }

    addFilter?.({
      filterId,
      selectedFilterGroup,
      value: newSelected,
    })
  }

  const sharedProps = {
    customMenuButton: popupButton,
    isOpen,
    onOpen,
    onClose,
    isOpenByDefault,
    options,
    setSelected: updateFilterSelection,
    isLoading,
    isFullHeight: true,
    matchWidth: false,
    listProps: { maxW: 80 },
    containerProps: { maxW: 'full' }, // Used for text truncation
    customHeader: (
      <>
        {filterLabel && <GroupHeader label={filterLabel} />}
        <FilterGroup
          options={FilterGroupOptions}
          defaultValue={selectedFilterGroup}
          setSelectedFilter={setSelectedFilterGroup}
        />
      </>
    ),
  }

  return (
    <>
      {isContainsFilter ? (
        <ComboBox
          selectionMode={SELECTION_MODE.SINGLE}
          customInput={
            <ContainsInputField
              onSubmit={(newSelected: string | undefined) => {
                updateFilterSelection(newSelected)
                onClose?.()
              }}
            />
          }
          selected={
            Array.isArray(selectedOptions) ? undefined : selectedOptions
          }
          {...sharedProps}
        />
      ) : (
        <ComboBox
          selectionMode={SELECTION_MODE.MULTIPLE}
          selected={
            Array.isArray(selectedOptions) ? selectedOptions : undefined
          }
          {...sharedProps}
        />
      )}
    </>
  )
}

const getIsContainsFilter = (selectedFilterGroup: FilterOperator) =>
  [FilterOperator.contains, FilterOperator.doesNotContain].includes(
    selectedFilterGroup,
  )

type ContainsInputFieldProps = Omit<InputProps, 'onSubmit'> & {
  onSubmit: (value: string | undefined) => void
}
const ContainsInputField = forwardRef<ContainsInputFieldProps, As>(
  ({ onSubmit, ...rest }, ref) => (
    <Input
      {...rest}
      ref={ref}
      type="text"
      onKeyUp={(ev) => {
        // Form submits by default when pressing enter when there is a single input field but not if there are multiple.
        // https://stackoverflow.com/questions/4196681/form-not-submitting-when-pressing-enter
        if (ev.key === 'Enter') {
          onSubmit(String(rest.value))
        }
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus // autoFocus guarantees that the input will be focused when the component is mounted even with isOpenByDefault
    />
  ),
)
