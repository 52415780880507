import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'

const REPORT_BY_ID_QUERY = graphql(/* GraphQL */ `
  query ReportById($id: ID!) {
    viewer {
      id
      report(id: $id) {
        ...ReportWithOwnerFields
      }
    }
  }
`)

export const useReportById = (reportId: string) => {
  const query = useQuery(REPORT_BY_ID_QUERY, {
    variables: {
      id: reportId,
    },
    skip: !reportId,
  })

  return {
    report: query.data?.viewer?.report,
    query,
  }
}
