import { useQuery, type QueryResult } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type Exact,
  type MerchantSiteInput,
  type TaxonomyInputsQueryQuery,
} from 'generated/graphql/graphql'
import { type TaxonomyInput } from '../../types'

export const TAXONOMY_INPUTS_QUERY = graphql(/* GraphQL */ `
  query TaxonomyInputsQuery($merchantSiteParams: MerchantSiteInput!) {
    viewer {
      id
      merchantSite(params: $merchantSiteParams) {
        id
        frontendId
        taxonomyInputs {
          ...TaxonomyInputFields
        }
      }
    }
  }
`)

type UseTaxonomyInputsParams = {
  frontendId: string
}

export const useTaxonomyInputs = ({
  frontendId,
}: UseTaxonomyInputsParams): {
  taxonomyInputs: TaxonomyInput[]
  query: QueryResult<
    TaxonomyInputsQueryQuery,
    Exact<{
      merchantSiteParams: MerchantSiteInput
    }>
  >
} => {
  const query = useQuery(TAXONOMY_INPUTS_QUERY, {
    variables: { merchantSiteParams: { frontendId } },
    notifyOnNetworkStatusChange: true,
  })
  const taxonomyInputs = (query.data?.viewer?.merchantSite?.taxonomyInputs ??
    []) as unknown as TaxonomyInput[]

  return { taxonomyInputs, query }
}
