import { Box, Flex } from '@chakra-ui/react'
import { captureException } from '@sentry/core'
import { type Row } from '@tanstack/react-table'
import { Button } from 'components/buttons'
import { type ButtonProps } from 'components/buttons/Button'
import { ButtonIcon, type ButtonSize } from 'components/buttons/ButtonIcon'
import { type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { compact } from 'lodash-es'
import { useMemo, type FC } from 'react'
import { MANUAL_INPUT_COLUMN } from '../../consts'
import { getInvalidColumns } from '../../ManualInput/utils/getErrors'
import { type ManualInputColumns } from '../../types'
import { TaxonomyDimensionSelector } from './TaxonomyDimensionSelector'

type FirstRowProps = Pick<Props, 'initialValue'> & {
  sharedButtonStyles: ButtonProps
  disabled: boolean
}

const FirstRow: FC<FirstRowProps> = ({
  initialValue,
  sharedButtonStyles,
  disabled,
}) => {
  return (
    <Button {...sharedButtonStyles} isDisabled={disabled}>
      <Typography size="sm" fontWeight={500} color="grey.800">
        {initialValue?.name}
      </Typography>
    </Button>
  )
}

const hasGroupName = (item: unknown): item is { groupName: string } => {
  return typeof (item as { groupName?: unknown }).groupName === 'string'
}

const hasName = (item: unknown): item is { name: string } => {
  return typeof (item as { name?: unknown }).name === 'string'
}

// Gets values for all rows on same level as current row
const getSameRowDetails = (
  columnId: string,
  row: Row<ManualInputColumns> | undefined,
  rowsOnSameLevel: Row<ManualInputColumns>[] | undefined,
) => {
  const rowsColumn = rowsOnSameLevel
    ?.filter((curRow) => curRow.id !== row?.id) // Skip row we're on
    .map((row) => row.getValue(columnId))

  const groupName = rowsColumn
    ?.map((rowCol) => (hasGroupName(rowCol) ? rowCol : undefined))
    .find((rowCol) => rowCol?.groupName)?.groupName

  const prevDimensionValues = compact(
    rowsColumn?.map((rowCol) => (hasName(rowCol) ? rowCol.name : undefined)),
  )

  return { groupName, prevDimensionValues }
}

// Get all dimension ids on previous levels, skip the first level
const getParentDimensionId = (
  row: Row<ManualInputColumns> | undefined,
  columnId: string,
  ids: string[],
): string[] => {
  const parentRow = row?.getParentRow()
  const parentRowColumn = parentRow?.getValue(columnId)

  if (!parentRow?.parentId || row?.depth === 0) {
    return ids
  }

  if (!hasGroupName(parentRowColumn)) {
    const { groupName } = getSameRowDetails(
      columnId,
      parentRow,
      parentRow?.getParentRow()?.subRows,
    )

    if (groupName) {
      ids.push(groupName)
    } else {
      captureException('Manual input: No groupName found on nested parent')
    }
  } else {
    ids.push(parentRowColumn.groupName)
  }

  return getParentDimensionId(parentRow, columnId, ids)
}

export type TaxonomyCell = {
  groupName?: string
  name?: string
}

type Props = {
  columnId: string
  row: Row<ManualInputColumns>
  initialValue?: TaxonomyCell
  isReadOnly?: boolean
  setSelected: (taxonomy: Partial<TaxonomyCell>) => void
  setError: (errorMessage?: string) => void
}

const columnsToValidate = [
  MANUAL_INPUT_COLUMN.PER_ORDER,
  MANUAL_INPUT_COLUMN.PER_PRODUCT,
  MANUAL_INPUT_COLUMN.PERCENTAGE,
]

export const TaxonomyCell: FC<Props> = ({
  columnId,
  row,
  initialValue,
  isReadOnly,
  setSelected,
  setError,
}) => {
  const isFirstRow = row.depth === 0
  const canExpandRow = row.subRows.length > 0
  const isExpanded = row.getIsExpanded()
  const rowsOnSameLevel = row?.getParentRow()?.subRows

  const { groupName, prevDimensionValues } = useMemo(
    () => getSameRowDetails(columnId, row, rowsOnSameLevel),
    [columnId, row, rowsOnSameLevel],
  )

  const prevDimensionIds = useMemo(
    () => getParentDimensionId(row, columnId, []),
    [columnId, row],
  )

  const sharedButtonStyles = {
    size: 'sm' as ButtonSize,
    variant: 'ghost',
    colorScheme: 'grey',
    trailingIcon: canExpandRow
      ? {
          name: (isExpanded
            ? 'ChevronDownIcon'
            : 'ChevronRightIcon') as IconName,
        }
      : undefined,
    title: canExpandRow ? (isExpanded ? 'Collapse cell' : 'Expand cell') : '',
    onClick: canExpandRow ? row.getToggleExpandedHandler() : undefined,
  }

  const { trailingIcon, ...buttonStyles } = { ...sharedButtonStyles }

  const rowHasValue = getInvalidColumns({ columnsToValidate, row }).some(
    (val) => !val,
  )

  return (
    <Box pl={`${row.depth * 1.5 - 1}rem`}>
      <Flex alignItems="center">
        {isFirstRow ? (
          <FirstRow
            initialValue={initialValue}
            sharedButtonStyles={sharedButtonStyles}
            disabled={!canExpandRow}
          />
        ) : (
          <>
            <Box mr={canExpandRow ? 0 : 8}>
              {canExpandRow && trailingIcon?.name && (
                <ButtonIcon {...buttonStyles} name={trailingIcon.name} mr={2} />
              )}
            </Box>

            <TaxonomyDimensionSelector
              initialValue={{
                ...initialValue,
                groupName: initialValue?.groupName ?? groupName,
              }}
              prevDimensionIds={prevDimensionIds}
              prevDimensionValues={prevDimensionValues}
              depth={row.depth}
              isReadOnly={isReadOnly}
              setSelected={setSelected}
              setError={setError}
              rowHasValue={rowHasValue}
            />
          </>
        )}
      </Flex>
    </Box>
  )
}
