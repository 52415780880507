import { type AddFilterArgsMultiSelectSearch } from 'components/Filters/AdvancedMultiSelectFilter'
import { type AddNumberFilterArgs } from 'components/Filters/NumberFilter/NumberFilter'
import { isValidFilterTuple } from 'components/Filters/NumberFilter/useNumberFilter'
import { type AddFilterArgsMultiSelect } from 'components/Filters/SimpleMultiSelectFilter'
import { getFilterNumericValue } from 'features/reports/utils/utils'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useFiltersState, useFiltersStateQueryParam } from './useFiltersState'

const useAddFilter = () => {
  const filters = useFiltersState()
  const [, setFilters] = useFiltersStateQueryParam()
  const normalizedMetrics = useNormalizedMetrics()
  const [trackEvent] = useTrackEvent()

  return ({
    filterId,
    value,
    selectedFilterGroup,
  }:
    | AddFilterArgsMultiSelectSearch
    | AddFilterArgsMultiSelect
    | AddNumberFilterArgs) => {
    const newFilters = { ...filters }
    const { format } = normalizedMetrics[filterId] ?? {}

    const formatNumber = (v: string | number) =>
      format
        ? String(getFilterNumericValue(v.toString(), format))
        : v.toString()

    const trackEventAddFilter = (value: string[] | string) => {
      trackEvent({
        eventName: 'Report Filter Added',
        eventProperties: {
          filterType: selectedFilterGroup,
          key: filterId,
          value,
        },
      })
    }

    if (isValidFilterTuple(value, selectedFilterGroup)) {
      const newValue = value.map(formatNumber)

      newFilters[filterId] = [
        {
          comparisonId: selectedFilterGroup,
          value: newValue[0],
          maxValue: newValue[1],
        },
      ]
      trackEventAddFilter([newValue[0], newValue[1]])
    } else {
      const updatedValue = Array.isArray(value)
        ? value.map(formatNumber)
        : formatNumber(value)

      newFilters[filterId] = [
        { comparisonId: selectedFilterGroup, value: updatedValue },
      ]
      trackEventAddFilter(updatedValue)
    }

    setFilters(newFilters)
  }
}

export default useAddFilter
