import {
  Box,
  Divider,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnSort,
} from '@tanstack/react-table'
import { Button } from 'components/buttons'
import { FilterOperator } from 'components/Filters/types'
import { Icon } from 'components/Icon/Icon'
import { Input } from 'components/Input'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import {
  ReportPanelOpenState,
  useReportPanelSetAtom,
} from 'features/reports/atoms/reportPanelOpenAtom'
import { useSelectReport } from 'features/reports/components/ReportSidebar/hooks/useSelectReport'
import { REPORT_PAGES } from 'features/reports/routePages'
import { VISIBILITY_ICON_BG_VARIABLE } from 'features/reports/views/shared/ReportVisibility/ReportVisibility'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type ReportResult } from 'graphql/reports/utils'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { startTransition, useState, type FC } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { colorTheme } from 'ui/theme/colors'
import { debounce } from 'utils/debounce'
import { initialFilters } from './ReportListFilter/consts'
import { ReportListFilter } from './ReportListFilter/ReportListFilter'
import {
  type FilterOptionValue,
  type ReportListFilterValues,
  type ReportListFilters,
} from './ReportListFilter/types'
import { useFilterReports } from './useFilterReports'
import { useReportListColumns } from './useReportListColumns'

type EmptyTableProps = { searchInput: string }
const EmptyTable: FC<EmptyTableProps> = ({ searchInput }) => {
  const emptyText = searchInput
    ? 'Could not find any reports'
    : "You haven't created any report yet"

  return (
    <Tr>
      <Td border="unset">
        <Flex alignItems="center" my={100} flexDir="column" gap={10}>
          <Image src="/images/EmptyReport.svg" alt="No reports" boxSize={200} />
          <Typography>{emptyText}</Typography>
        </Flex>
      </Td>
    </Tr>
  )
}

type Props = {
  pageTitle: string | undefined
  reports: ReportResult[]
  isLoading?: boolean
  addReportCallback?: () => void
}

export const ReportListTable: FC<Props> = ({
  pageTitle = 'Reports',
  reports,
  isLoading: isLoadingProp,
  addReportCallback,
}) => {
  const selectReport = useSelectReport()
  const navigate = useNavigate()
  const [trackEvent] = useTrackEvent()
  const [searchInput, setSearchInput] = useState('')
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })
  const [filters, setFilters] = useState<ReportListFilters>(initialFilters)

  const updateFilters = (
    option: FilterOptionValue,
    newFilters: ReportListFilterValues,
  ) => {
    trackEvent({
      eventName: 'Report List Filter Changed',
      eventProperties: {
        type: option,
        oldFilters: filters[option].map((filter) => filter.id),
        newFilters: newFilters.map((filter) => filter.id),
        filterType: FilterOperator.isAnyOf,
      },
    })

    setFilters((prev) => ({
      ...prev,
      [option]: newFilters,
    }))
  }

  const trackSearchInput = debounce((searchInput?: string) => {
    if (!searchInput?.trim()) return

    trackEvent({
      eventName: 'Report List Search Input Changed',
      eventProperties: {
        searchInput,
      },
    })
  }, 1500) // Long timeout to avoid many mixpanel events

  // avoid showing reports if we have not fetched all the members yet
  const isLoading = isLoadingProp || memberships?.isLoading

  const filteredReports = useFilterReports(reports, searchInput, filters)

  const [sorting, setSorting] = useState<ColumnSort[]>([])
  const columns = useReportListColumns()

  const table = useReactTable({
    data: filteredReports,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const setReportPanelOpen = useReportPanelSetAtom()

  const rows = table.getRowModel().rows
  const isEmpty = rows.length === 0
  const [isLargerThanLg] = useBreakpoint('lg')

  return (
    <Box w="full">
      <Table>
        <Thead zIndex={1} position="sticky" top={0} bg="white">
          <Tr>
            <Th border="unset" p={0} colSpan={columns.length}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                px={6}
                py={2}
                gap={2}
                flexWrap={!isLargerThanLg ? 'wrap' : 'nowrap'}
              >
                <Flex alignItems="center" gap={6}>
                  <Typography
                    fontSize="md"
                    fontWeight={600}
                    color="gray.800"
                    lineHeight={6}
                    flexShrink={0}
                  >
                    {pageTitle}
                  </Typography>
                  <ReportListFilter
                    reports={reports}
                    filters={filters}
                    setFilters={updateFilters}
                  />
                </Flex>
                <Flex alignItems="center" gap={2}>
                  <Input
                    size="sm"
                    leadingIcon={{
                      name: 'MagnifyingGlassIcon',
                    }}
                    placeholder="Find a report"
                    onChange={(e) =>
                      startTransition(() => {
                        setSearchInput(e.target.value)
                        trackSearchInput(e.target.value)
                      })
                    }
                  />
                  <Button
                    as={Link}
                    to={REPORT_PAGES.NEW_REPORT}
                    size="sm"
                    onClick={() => {
                      setReportPanelOpen(ReportPanelOpenState.REPORT_DETAILS)
                      addReportCallback?.()
                    }}
                  >
                    Add report
                  </Button>
                </Flex>
              </Flex>
              {!isEmpty && <Divider />}
            </Th>
          </Tr>
          {(isLoading || !isEmpty) &&
            table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted()
                  const headerName = flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )

                  return (
                    <Th
                      key={header.id}
                      textAlign="left"
                      bg="unset"
                      border="unset"
                      textTransform="unset"
                      letterSpacing="unset"
                      pb={2}
                      pt={6}
                      pl={4}
                      _hover={{ cursor: 'pointer' }}
                      _first={{ pl: 14 }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex alignItems="center" gap={2}>
                        {typeof headerName === 'string' && (
                          <Typography fontWeight="500">{headerName}</Typography>
                        )}
                        <Icon
                          visibility={isSorted ? 'visible' : 'hidden'}
                          name={
                            isSorted === 'asc' ? 'ArrowUpIcon' : 'ArrowDownIcon'
                          }
                          size="small"
                        />
                      </Flex>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          <Tr>
            <Th border="unset" px={0} py={0} colSpan={columns.length}>
              <Divider />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <SkeletonTableRows columns={columns.length} rows={5} />
          ) : isEmpty ? (
            <EmptyTable searchInput={searchInput} />
          ) : (
            rows.map((row) => (
              <Tr
                key={row.id}
                height={16}
                // aria-selected={row.original.details.id === selectedReport.id} // TODO: Mark previous report as selected
                _selected={{ bg: 'grey.100' }}
                _hover={{
                  bg: 'grey.50',
                  cursor: 'pointer',
                  [VISIBILITY_ICON_BG_VARIABLE]: colorTheme.grey[50],
                }}
                onClick={() => {
                  const reportToSelect = row.original

                  if (reportToSelect) {
                    selectReport(reportToSelect)
                    navigate(REPORT_PAGES.REPORT_BY_ID(reportToSelect.id))
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    maxW="300px"
                    _first={{ pl: 6 }}
                    _last={{ pr: 8, w: '0px' }}
                    fontSize="sm"
                    lineHeight={5}
                    py={3}
                    px={4}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
