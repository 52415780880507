import { Button } from 'components/buttons'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { format } from 'date-fns'
import { useAnalyticsTableState } from 'features/reports/hooks/useAnalyticsTableState'
import { useDateState } from 'features/reports/hooks/useDateState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMemo, useState, type MouseEventHandler } from 'react'
import { CSVLink } from 'react-csv'
import { getCsvData, getCsvHeaders } from '../AnalyticsTable/utils'

type DownloadCSVButtonProps = {
  data: NormalizedStatistic[]
  selectedReportId?: string
}

export interface CsvHeader {
  label: string
  key: string
  groupKey: string
  isCompare?: boolean
}

interface CsvData {
  data: Record<string, number | string>[]
  headers: CsvHeader[]
}

const emptyData: CsvData = {
  data: [],
  headers: [],
}

export const DownloadCSVButton = ({
  selectedReportId,
  data,
}: DownloadCSVButtonProps) => {
  const { state } = useAnalyticsTableState()
  const [trackEvent] = useTrackEvent()
  const tableColumnIds = state.map((column) => column.id)
  const { dateRange, compareDateRange } = useDateState()
  const isCompare = getIsPopulatedDateRange(compareDateRange)
  const normalizedMetrics = useNormalizedMetrics()
  const normalizedDimensions = useNormalizedDimensions()
  const normalizedAttributionModels = useNormalizedAttributionModels()

  const [csvData, setCsvData] = useState<CsvData>(emptyData)
  const [isLoading, setIsLoading] = useState(false)

  const isDisabled = tableColumnIds?.length === 0 || data?.length === 0

  const fileName = useMemo(() => {
    const startDate = dateRange[0]
    const endDate = dateRange[1]
    const formattedStartDate = startDate ? format(startDate, 'yyMMdd') : ''
    const formattedEndDate = endDate ? format(endDate, 'yyMMdd') : ''

    return formattedStartDate + '-' + formattedEndDate + '_dema_data'
  }, [dateRange])

  const fetchCsvData = (
    event: MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void,
  ) => {
    if (isDisabled || isLoading) {
      done(false)

      return
    }
    setIsLoading(true)

    const headers = getCsvHeaders(
      tableColumnIds,
      normalizedMetrics,
      normalizedDimensions,
      normalizedAttributionModels,
      isCompare,
    )
    const fetchedData = getCsvData(data)

    setCsvData({ data: fetchedData, headers })
    setIsLoading(false)
    done(true)
    trackEvent({
      eventName: 'Report Exported',
      eventProperties: {
        reportId: selectedReportId ?? '',
      },
    })
  }

  return (
    <CSVLink
      asyncOnClick={true}
      {...csvData}
      filename={fileName}
      onClick={fetchCsvData}
    >
      <Button
        size="sm"
        variant="outline"
        colorScheme="gray"
        isDisabled={isDisabled || isLoading}
      >
        Export
      </Button>
    </CSVLink>
  )
}
