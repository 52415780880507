import { NEW_REPORT_ID } from './consts'

export const REPORT_PAGE_TITLES = {
  USER: 'My reports',
  ALL: 'All reports',
  DEMA: 'Dema reports',
} as const

export const REPORT_ROUTE_FRAGMENTS = {
  REPORTS: 'reports',
  USER: 'user',
  ALL: 'all',
  TEAMS: 'teams',
  ORGANISATION: 'organisation',
  DEMA: 'dema',
  NEW: 'new',
} as const

const BASE = `/${REPORT_ROUTE_FRAGMENTS.REPORTS}` as const

export const REPORT_PAGES = {
  BASE,
  REPORT_BY_ID: (reportId: string) => `${BASE}?reportId=${reportId}` as const,
  REPORT_WITH_PARAMS: (params: string | URLSearchParams) =>
    `${BASE}?${params}` as const,
  USER_REPORTS: `${BASE}/${REPORT_ROUTE_FRAGMENTS.USER}`,
  ALL_REPORTS: `${BASE}/${REPORT_ROUTE_FRAGMENTS.ALL}`,
  TEAM_REPORTS: (teamId: string) =>
    `${BASE}/${REPORT_ROUTE_FRAGMENTS.TEAMS}/${teamId}` as const,
  ORGANISATION_REPORTS: `${BASE}/${REPORT_ROUTE_FRAGMENTS.ORGANISATION}`,
  DEMA_REPORTS: `${BASE}/${REPORT_ROUTE_FRAGMENTS.DEMA}`,
  NEW_REPORT: `${BASE}/${REPORT_ROUTE_FRAGMENTS.NEW}?reportId=${NEW_REPORT_ID}`,
} as const
