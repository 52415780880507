import { popoverAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys)

const baseStyle = definePartsStyle(() => ({
  content: {
    borderRadius: 0,
    boxShadow: 'md',
    border: 'none',
    p: 4,
  },
}))

export const popoverTheme = defineMultiStyleConfig({
  baseStyle,
})
