import { type QueryHookOptions, useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type Exact,
  type HistoricalAnalysisQueryQuery,
  type HistoricalAnalysisInput,
} from 'generated/graphql/graphql'
import { type OPTIMIZATION_METRIC } from '../consts'

const HISTORICAL_ANALYSIS_QUERY = graphql(/* GraphQL */ `
  query HistoricalAnalysisQuery(
    $historicalAnalysisInput: HistoricalAnalysisInput!
  ) {
    viewer {
      id
      merchant {
        id
        historicalAnalysis(params: $historicalAnalysisInput) {
          startDate
          endDate
          contribution
          curve {
            channel
            line
            optimalPoint
            actualPoint
            saturationPoint
          }
          summary
        }
      }
    }
  }
`)

export type HistoricalAnalysis = NonNullable<
  HistoricalAnalysisQueryQuery['viewer']
>['merchant']['historicalAnalysis']

export type ContributionData = {
  channel: string
  [OPTIMIZATION_METRIC.NGP3]: number
  [OPTIMIZATION_METRIC.NGP3_OPTIMAL]: number
  [OPTIMIZATION_METRIC.SPEND]: number
  [OPTIMIZATION_METRIC.SPEND_OPTIMAL]: number
  [OPTIMIZATION_METRIC.SALES]: number
  [OPTIMIZATION_METRIC.SALES_OPTIMAL]: number
  spendAnalysis?: {
    id: string
    name: string
    description: string
  }
  modelPerformance: {
    id: string
    name: string
    description: string
  }
}[]

export const modelPerformanceDefaultId = 'as_expected'

export type SummaryData = {
  id: string
  [OPTIMIZATION_METRIC.NGP3]: number
  [OPTIMIZATION_METRIC.NGP3_OPTIMAL]: number
  [OPTIMIZATION_METRIC.SALES]: number
  [OPTIMIZATION_METRIC.SALES_OPTIMAL]: number
}[]

export type SaturationCurveData = HistoricalAnalysis['curve']

export const useHistoricalAnalysisQuery = (
  input: HistoricalAnalysisInput,
  options?: QueryHookOptions<
    HistoricalAnalysisQueryQuery,
    Exact<{
      historicalAnalysisInput: HistoricalAnalysisInput
    }>
  >,
) => {
  const query = useQuery(HISTORICAL_ANALYSIS_QUERY, {
    ...options,
    variables: {
      historicalAnalysisInput: input,
    },
  })

  return query
}
