import { Box, Flex, Show, VStack } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { ChannelBreakdownChart } from 'features/optimizations/components/ChannelBreakdown/ChannelBreakdownChart'
import { LegendRow } from 'features/optimizations/components/LegendRow/LegendRow'
import {
  OPTIMIZATION_METRIC,
  chartMetricMap,
} from 'features/optimizations/consts'
import { type ContributionData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import type React from 'react'

interface OptimizedBudgetProps {
  contributionData: ContributionData
  compareDateLabel: string
}

export const OptimizedBudget: React.FC<OptimizedBudgetProps> = ({
  contributionData,
}) => {
  return (
    <Box p={5} pt={0}>
      <Box mb={4}>
        <Typography mb={2} fontSize="lg" color="gray.900" lineHeight={7}>
          Optimised budget allocation
        </Typography>
      </Box>

      <Flex w="full">
        <ChannelBreakdownChart contributionData={contributionData} />
        <Show above="lg">
          <VStack spacing={6} py={6} flexShrink={0} alignItems="flex-start">
            <Box>
              <LegendRow
                color={chartMetricMap[OPTIMIZATION_METRIC.SPEND].color}
                label={chartMetricMap[OPTIMIZATION_METRIC.SPEND].name}
                type={chartMetricMap[OPTIMIZATION_METRIC.SPEND].type}
              />
              <LegendRow
                color={chartMetricMap[OPTIMIZATION_METRIC.SPEND_OPTIMAL].color}
                label={chartMetricMap[OPTIMIZATION_METRIC.SPEND_OPTIMAL].name}
                type={chartMetricMap[OPTIMIZATION_METRIC.SPEND_OPTIMAL].type}
                stripedBackground={
                  chartMetricMap[OPTIMIZATION_METRIC.SPEND_OPTIMAL].striped
                    .color
                }
              />
            </Box>
            <Box>
              <LegendRow
                color={chartMetricMap[OPTIMIZATION_METRIC.NGP3].color}
                label={chartMetricMap[OPTIMIZATION_METRIC.NGP3].name}
                type={chartMetricMap[OPTIMIZATION_METRIC.NGP3].type}
              />
              <LegendRow
                color={chartMetricMap[OPTIMIZATION_METRIC.NGP3_OPTIMAL].color}
                label={chartMetricMap[OPTIMIZATION_METRIC.NGP3_OPTIMAL].name}
                type={chartMetricMap[OPTIMIZATION_METRIC.NGP3_OPTIMAL].type}
                stripedBackground={
                  chartMetricMap[OPTIMIZATION_METRIC.NGP3_OPTIMAL].striped.color
                }
              />
            </Box>
          </VStack>
        </Show>
      </Flex>
    </Box>
  )
}
