import { gql, useMutation } from '@apollo/client'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Button } from 'components/buttons/Button'
import { type ReportLabel } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'

const DELETE_REPORT_LABEL_MUTATION = gql`
  mutation DeleteReportLabel($id: ID!) {
    deleteReportLabel(id: $id) {
      id
      __typename
    }
  }
`

type DeleteReportLabelProps = {
  label: ReportLabel
}

export const DeleteLabelModal = ({ label }: DeleteReportLabelProps) => {
  const [deleteReportLabel] = useMutation(DELETE_REPORT_LABEL_MUTATION)

  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDeleteLabel = () => {
    deleteReportLabel({
      variables: {
        id: label.id,
      },
      optimisticResponse: {
        deleteReportLabel: {
          id: label.id,
          __typename: 'ReportLabel',
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteReportLabel) return

        cache.evict({
          id: cache.identify(data?.deleteReportLabel),
        })
        cache.gc()
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'Report Label Deleted',
          eventProperties: {
            labelName: label.name,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not delete report label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <ButtonIcon
        onClick={onOpen}
        size="sm"
        variant="ghost"
        title="Delete"
        name="TrashIcon"
      />

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete label</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            The label will be permanently deleted. The label will be removed
            from all reports where it is used.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              type="submit"
              onClick={() => {
                onDeleteLabel()
                onClose()
              }}
            >
              Delete label
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
