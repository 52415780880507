import { Input } from 'components/Input'
import { forwardRef } from 'react'

interface BaseInputFieldProps<InputType, DataType> {
  label?: string
  value: DataType | null
  type?: InputType
  onChange: (value: string) => void
  onSubmit: () => void
}

type Props =
  | BaseInputFieldProps<'text', string>
  | BaseInputFieldProps<'number', number>

export const FreeTextInputField = forwardRef<HTMLInputElement, Props>(
  ({ label, value, type = 'text', onChange, onSubmit }, ref) => (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Input
        ref={ref}
        type={type}
        value={value ?? undefined}
        step="any"
        onChange={(e) => onChange(e.target.value)}
        label={label}
      />
    </form>
  ),
)

FreeTextInputField.displayName = 'FreeTextInputField'
