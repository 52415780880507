import { Button } from 'components/buttons'
import { useToggleIntegration } from 'features/settings/graphql/useToggleIntegration'

interface Props {
  integrationId: string
}

export const RemoveButton = ({ integrationId }: Props) => {
  const [disableIntegration, { loading }] = useToggleIntegration()

  return (
    <Button
      variant="outline"
      colorScheme="grey"
      leadingIcon={{
        name: 'CloseIcon',
      }}
      onClick={() =>
        disableIntegration({
          params: { id: integrationId, connected: false },
        })
      }
      isLoading={loading}
    >
      Remove
    </Button>
  )
}
