import { Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type CellContext,
} from '@tanstack/react-table'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { graphql } from 'generated/graphql'
import { type UtmMappingsViewQueryQuery } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { parameterTypes, sharedCellStyles } from '../shared/consts'
import { EmptyMappings } from '../shared/EmptyMappings'
import { MappingThead } from '../shared/MappingThead'
import { useMappingsAtom } from '../shared/state'
import { CreateMappingPanel } from './CreateMappingPanel'
import { UnhandledMappingsCell } from './UnhandledMappingsCell'

interface UnmappedChannel {
  utmCampaign: string
  utmMedium: string
  utmSource: string
  visits: number
}

interface CustomCellContext extends CellContext<UnmappedChannel, string> {
  isMatchingRow: boolean
  isReadOnly: boolean
}

const columnHelper = createColumnHelper<UnmappedChannel>()
const columns = [
  ...parameterTypes.map(({ id, label }) =>
    columnHelper.accessor(id, {
      cell: (info) => (
        <UnhandledMappingsCell
          value={info.getValue()}
          type={id}
          disabled={!(info as CustomCellContext).isMatchingRow}
          isReadOnly={(info as CustomCellContext).isReadOnly}
        />
      ),
      header: label,
    }),
  ),
  columnHelper.accessor('visits', {
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    header: 'Visits',
  }),
]

export const MappableSessions_MerchantSiteFragment = graphql(/* GraphQL */ `
  fragment MappableSessions_MerchantSite on MerchantSite {
    mappableSessions {
      utmCampaign
      utmMedium
      utmSource
      visits
    }
  }
`)

type Props = {
  merchantSite: NonNullable<UtmMappingsViewQueryQuery['viewer']>['merchantSite']
  isReadOnly: boolean
  isLoading: boolean
}

export const UnhandledMappings = ({
  merchantSite,
  isReadOnly,
  isLoading,
}: Props) => {
  const [mappingsState] = useMappingsAtom()

  const unmappedChannels = useMemo(
    () =>
      (merchantSite?.mappableSessions as unknown as UnmappedChannel[]) ?? [],
    [merchantSite],
  )

  const table = useReactTable({
    data: unmappedChannels,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const tableRows = table.getRowModel().rows

  return (
    <>
      <Typography fontSize="sm">
        Select UTM parameters by clicking on them and then create a mapping to a
        Channel group and Channel. Note that created mappings will only be
        applied to future visits and not to past visits before the mapping was
        created.
      </Typography>
      <Flex gap={12} justifyContent="space-between" flexGrow={1}>
        <TableContainer flexGrow={1}>
          <Table>
            <MappingThead headerGroups={table.getHeaderGroups()} />
            <Tbody>
              {isLoading ? (
                <SkeletonTableRows columns={columns.length} rows={7} />
              ) : tableRows.length === 0 ? (
                <EmptyMappings />
              ) : (
                tableRows.map((row) => {
                  const isMatchingRow = parameterTypes.every(
                    ({ id }) =>
                      !mappingsState[id] ||
                      row.original[id] === mappingsState[id],
                  )

                  return (
                    <Tr key={row.id} opacity={isMatchingRow ? 1 : 0.3}>
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id} {...sharedCellStyles}>
                          {flexRender(cell.column.columnDef.cell, {
                            ...cell.getContext(),
                            isMatchingRow,
                            isReadOnly,
                          })}
                        </Td>
                      ))}
                    </Tr>
                  )
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {!isReadOnly && (
          <Flex pos="sticky" h="fit-content">
            <CreateMappingPanel frontendId={merchantSite?.frontendId} />
          </Flex>
        )}
      </Flex>
    </>
  )
}
