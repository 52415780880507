import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import { useMemo } from 'react'
import { colorTheme } from 'ui/theme/colors'
import { staticChartOptions } from 'utils/chart/constants'
import { baseSaturationChartOptions } from '../baseChart'

import { getTooltipFormatter } from '../shared/getTooltipFormatter'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)

interface SingleChannelGraphProps {
  selectedCurveData: SaturationCurveData[number]
  selectedChannel: string
}

export const actualSpendColor = colorTheme.grey['700']
export const optimalSpendColor = colorTheme.grape['400']
export const saturationSpendColor = colorTheme.violet['500']

export const SingleChannelGraph: React.FC<SingleChannelGraphProps> = ({
  selectedCurveData,
  selectedChannel,
}) => {
  const { currency } = useMerchantInfo()
  const chartOptions = useMemo<Highcharts.Options>(() => {
    const actualSpendX = selectedCurveData.actualPoint[0]
    const lineData = selectedCurveData.line.map(([x, y]) => ({
      x: x,
      y: y,
      marker: {
        enabled: false,
      },
    }))

    return {
      ...baseSaturationChartOptions,
      xAxis: {
        ...baseSaturationChartOptions.xAxis,
        title: {
          text: `Marketing spend (${currency})`,
        },
      },
      yAxis: {
        ...baseSaturationChartOptions.yAxis,
        title: {
          text: `Net gross profit 3 (${currency})`,
        },
      },
      series: [
        {
          type: 'spline',
          data: lineData,
          color: colorTheme.gray['700'], // Line color
          dashStyle: 'Solid',
          marker: {
            enabled: false, // Disables the markers for all points
          },
          name: selectedChannel,
        },
        {
          // Saturation spend point
          type: 'scatter',
          name: `Saturation Spend`,
          data: [
            [
              selectedCurveData.saturationPoint[0],
              selectedCurveData.saturationPoint[1],
            ],
          ],
          color: saturationSpendColor,
          marker: {
            enabled: true,
            radius: 5,
            symbol: 'circle',
          },
          animation: false,
          zIndex: 1,
        },
        {
          // Actual spend point
          type: 'scatter',
          name: `Actual Spend`,
          data: [[actualSpendX, selectedCurveData.actualPoint[1]]],
          color: actualSpendColor,
          marker: {
            enabled: true,
            radius: 5,
            symbol: 'circle',
          },
          animation: false,
          zIndex: 1,
        },
        {
          // Optimal spend point
          type: 'scatter',
          name: `Proposed Spend`,
          data: [
            [
              selectedCurveData.optimalPoint?.[0],
              selectedCurveData.optimalPoint?.[1],
            ],
          ],
          animation: false,
          color: optimalSpendColor,
          marker: {
            enabled: true,
            radius: 5,
            symbol: 'circle',
          },
          zIndex: 1,
        },
      ],
      tooltip: {
        ...staticChartOptions.tooltip,
        formatter: getTooltipFormatter({ currency }),
      },
    }
  }, [selectedCurveData, selectedChannel, currency])

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      containerProps={{ style: { width: '100%', minWidth: '400px' } }}
    />
  )
}
