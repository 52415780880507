import { Tag } from 'components/Tag/Tag'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { useRef } from 'react'

interface Props {
  value: string
  maxWidth?: string
  channelValue?: boolean
}

export const ExistingMappingsCell = ({
  value,
  maxWidth,
  channelValue,
}: Props) => {
  const textRef = useRef<HTMLElement>(null)
  const isOverflowing = useIsOverflowing(textRef)

  return (
    <Tooltip label={value} placement="top" isDisabled={!isOverflowing}>
      <Tag
        size="lg"
        variant="solid"
        minW="full"
        maxW={maxWidth}
        colorScheme={channelValue ? 'primary' : 'grey'}
        label={value}
      />
    </Tooltip>
  )
}
