import { Flex, Menu, MenuList, Portal, useDisclosure } from '@chakra-ui/react'
import { GroupHeader } from 'components/Filters/shared/GroupHeader'
import { useMultiSelectLabel } from 'components/Filters/useMultiSelectLabel'
import { Loader } from 'components/Loader/Loader'
import { useCombobox } from 'downshift'
import {
  cloneElement,
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MenuButton } from '../../components/MenuButton'
import { MenuOverlay } from '../../components/MenuOverlay'
import { NothingFound } from '../../NothingFound'
import {
  type DropdownOption,
  type ComboBoxMultiProps,
  type ComboBoxOption,
} from '../../types'
import { ComboBoxItems } from '../components/ComboBoxItems'
import { FilterInput } from '../components/FilterInput'
import {
  getFilteredOptions,
  handleStateChanges,
  hasGroupOptions,
  isGroupOption,
  sortOptions,
  useFullHeight,
  useSelectDeselect,
} from '../utils'

const emptyArray: string[] = []

export const ComboBoxMulti = <
  TItem extends DropdownOption,
  T extends ComboBoxOption<TItem>,
>({
  options,
  customHeader,
  customInput,
  customMenuButton,
  customEmptyState,
  customFooter,
  buttonText,
  headerLabel,
  placeholder,
  searchPlaceholder,
  selected = emptyArray,
  variant = 'outline',
  size = 'md',
  menuProps,
  listProps,
  matchWidth = true,
  error,
  hasSelectDeselect = true,
  isLoading,
  isDisabled = false,
  isReadOnly = false,
  isFullHeight,
  isOpenByDefault,
  isOpen: isOpenProps,
  onOpen: onOpenProps,
  onClose: onCloseProps,
  setSelected,
  onOpenChange,
  itemWrapper,
}: ComboBoxMultiProps<TItem, T>) => {
  const [inputValue, setInputValue] = useState('')
  const [draftSelected, setDraftSelected] = useState<string[]>(selected)
  const sortedOptions = useMemo(
    () => sortOptions(options, selected),
    [options, selected],
  )
  const filteredOptions = useMemo(() => {
    return getFilteredOptions(sortedOptions, inputValue)
  }, [sortedOptions, inputValue])
  const flattenedFilteredOptions = useMemo(() => {
    return filteredOptions.flatMap((item) =>
      isGroupOption(item) ? item.items : item,
    )
  }, [filteredOptions])

  const inputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: isOpenByDefault,
    isOpen: isOpenProps,
    onOpen: onOpenProps,
    onClose: onCloseProps,
  })
  const buttonRef = useRef<HTMLButtonElement>(null)
  const popupHeight = useFullHeight(buttonRef, isFullHeight)

  const showSelectDeselect = hasSelectDeselect && !isLoading
  // Input field select/deselect all is disabled with groups since we have it per group
  const isInputSelectDeselectEnabled =
    showSelectDeselect && !hasGroupOptions(options)
  const { toggleAllSelected, isAllSelected } = useSelectDeselect({
    options: flattenedFilteredOptions,
    selected: draftSelected,
  })

  useEffect(() => {
    if (isOpen) {
      // We reset input value on open to avoid some visual glitch that would happen when resetting when closing
      setInputValue('')
      // We reset draftSelected to selected on open to make sure state is in sync
      setDraftSelected(selected)
    }
    onOpenChange?.(isOpen)
  }, [isOpen, onOpenChange, selected])

  const firstSelectedItem = selected[0]
  const firstSelectedName = useMemo(
    () =>
      sortedOptions.reduce<string | undefined>((acc, option) => {
        if (!firstSelectedItem) return acc

        if (isGroupOption(option)) {
          const foundItem = option.items.find(
            (item) => item.id === firstSelectedItem,
          )

          if (foundItem) {
            return foundItem.name
          }
        } else if (option.id === firstSelectedItem) {
          return option.name
        }

        return acc
      }, undefined),
    [firstSelectedItem, sortedOptions],
  )

  const defaultButtonText = useMultiSelectLabel({
    selectedIds: selected,
    defaultFirstLabel: firstSelectedName,
  })

  const {
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    isOpen,
    inputValue,
    items: flattenedFilteredOptions,
    isItemDisabled(item) {
      return item?.disabled ?? false
    },
    itemToString: (item) => item?.name ?? '',
    selectedItem: null,
    onInputValueChange: ({ inputValue }) => {
      startTransition(() => setInputValue(inputValue ?? ''))
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return

      const selectedItemId = selectedItem.id.toString()

      const selected = draftSelected.includes(selectedItemId)
        ? draftSelected.filter((item) => item !== selectedItemId)
        : [...draftSelected, selectedItemId]

      setDraftSelected(selected)
    },
    stateReducer: handleStateChanges,
  })

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={() => {
        setSelected(draftSelected)
        onClose()
      }}
      variant={variant}
      isLazy={true}
      gutter={2} // offset from button
      matchWidth={matchWidth}
      size={size}
      closeOnSelect={false}
      initialFocusRef={inputRef}
      {...menuProps}
    >
      <MenuButton
        buttonProps={getToggleButtonProps({ ref: buttonRef })}
        error={error}
        size={size}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        isOpen={isOpen}
        customMenuButton={customMenuButton}
        buttonText={buttonText ?? defaultButtonText}
        placeholder={placeholder}
        variant={variant}
      />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList
          listStyleType="none"
          py={0}
          maxH={isFullHeight ? `max(350px,${popupHeight}px)` : '350px'}
          maxW="600px"
          h="fit-content"
          display="flex"
          flexDir="column"
          overflow="hidden"
          onClick={(e) => e.stopPropagation()}
          {...listProps}
          {...getMenuProps({ ref: listRef }, { suppressRefError: true })}
        >
          {customHeader || (headerLabel && <GroupHeader label={headerLabel} />)}
          {customInput ? (
            cloneElement(customInput, {
              ...getInputProps(
                { ref: inputRef, value: inputValue },
                { suppressRefError: true },
              ),
            })
          ) : (
            <FilterInput
              labelProps={getLabelProps()}
              inputProps={getInputProps(
                { ref: inputRef, value: inputValue },
                { suppressRefError: true },
              )}
              placeholder={searchPlaceholder}
              hasSelectDeselect={isInputSelectDeselectEnabled}
              isAllSelected={isAllSelected}
              toggleAllSelected={() => setDraftSelected(toggleAllSelected)}
            />
          )}
          <Flex flex={1} minH={0} flexDir="column">
            {isLoading ? (
              <Flex p={3} justifyContent="center" alignItems="center">
                <Loader size="small" />
              </Flex>
            ) : filteredOptions.length === 0 ? (
              customEmptyState || <NothingFound />
            ) : (
              <ComboBoxItems
                size={size}
                filteredOptions={filteredOptions}
                selected={draftSelected}
                getItemProps={getItemProps}
                isOption={true}
                highlightedIndex={highlightedIndex}
                hasSelectDeselect={showSelectDeselect}
                setSelected={setDraftSelected}
                itemWrapper={itemWrapper}
              />
            )}
          </Flex>
          {customFooter}
        </MenuList>
      </Portal>
    </Menu>
  )
}
