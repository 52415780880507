import { useCopilotIsOpen } from 'features/copilot/state'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { NavIconLink } from '../NavLinks'

export const CopilotButton = () => {
  const flags = useFeatureFlags()
  const [, setIsOpen] = useCopilotIsOpen()

  if (!flags.enableCopilot) return null

  return (
    <NavIconLink
      iconName="LightbulbIcon"
      onClick={() => setIsOpen(true)}
      tooltip="Dema Copilot"
    />
  )
}
