import { useToast } from '@chakra-ui/react'
import { RedirectToSignIn, useSignUp, useUser } from '@clerk/clerk-react'
import { isClerkAPIResponseError } from '@clerk/clerk-react/errors'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { Alert } from 'components/Alert/Alert'
import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { ContainerWrapper } from 'features/auth/components/ContainerWrapper'
import { userFullNameSchema } from 'features/profile/views/Personal/ChangeNameModal'
import { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

// userFullNameSchema contains the validation for fullName
const validationSchema = userFullNameSchema.merge(
  z.object({
    password: z.string().min(8, 'Password must be at least 8 characters long'),
  }),
)

type ValidationSchema = z.infer<typeof validationSchema>

// we do not control the key name, it comes from clerk
const INVITATION_PARAM_KEY = '__clerk_ticket'

const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms))

export const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showInvalidTicketAlert, setShowInvalidTicketAlert] = useState(false)
  const [searchParams] = useSearchParams()
  const ticket = searchParams.get(INVITATION_PARAM_KEY)
  const toast = useToast()

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    shouldFocusError: true,
  })

  const {
    formState: { errors, isSubmitting },
    setError,
  } = methods
  const { isLoaded: isSignUpLoaded, signUp, setActive } = useSignUp()
  const { isLoaded: isUserLoaded, isSignedIn } = useUser()

  // if you do not have a ticket, that means you are not invited and should not be able to sign up
  // this is only client side validation, the server will also check for this via webhooks in our api
  if (!ticket) return <RedirectToSignIn redirectUrl={'/'} />

  if (!isSignUpLoaded) return null

  const onSubmit: SubmitHandler<ValidationSchema> = async ({
    fullName,
    password,
  }) => {
    try {
      const names = fullName.split(' ')
      const firstName = names.shift()
      const lastName = names.join(' ')

      if (!lastName) {
        setError('fullName', { message: 'Please provide a last name' })

        return
      }

      const result = await signUp.create({
        strategy: 'ticket',
        ticket,
        password,
        firstName,
        lastName,
      })

      if (result.status === 'complete') {
        // adding wait for the webhook from clerk to our api to finish
        // 3000ms is pretty arbitrary but should be well enough for our webhook to finish
        await sleep(3000)
        const reloadedResult = await result.reload()

        setActive({ session: reloadedResult.createdSessionId })
        toast({ status: 'success', description: 'Signed up' })
      }
    } catch (error) {
      isClerkAPIResponseError(error)
        ? captureException('Clerk API response error: ' + error)
        : captureException('Unknown sign up error: ' + error)

      if (
        isClerkAPIResponseError(error) &&
        error.errors[0].code === 'form_password_pwned' &&
        error.errors[0]?.longMessage
      ) {
        // password is not secure enough
        setError('password', { message: error.errors[0].longMessage })

        return
      }

      setShowInvalidTicketAlert(true)
    }
  }

  return (
    <ContainerWrapper>
      <Form display="flex" methods={methods} onSubmit={onSubmit}>
        <Typography
          fontSize="3xl"
          fontWeight="600"
          lineHeight="36px"
          textAlign="center"
          mb={6}
          color="grey.100"
        >
          Welcome to the team!
        </Typography>
        <Input
          w="full"
          colorMode="dark"
          name="fullName"
          type="text"
          label="Full name"
          placeholder="John Doe"
          wrapperProps={{ mb: 4 }}
          error={errors.fullName}
        />
        <Input
          w="full"
          colorMode="dark"
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          autoComplete="current-password"
          trailingIcon={{ name: 'VisibleIcon' }}
          onTrailingIconClick={() =>
            setShowPassword((showPassword) => !showPassword)
          }
          error={errors.password}
        />
        {!errors.password && (
          <Typography mt={1} color="gray.100" fontSize="xs">
            Use a password with at least 8 characters
          </Typography>
        )}

        {showInvalidTicketAlert && (
          <Alert
            status="error"
            closable={false}
            colorMode="dark"
            mt={6}
            title="Could not sign up."
            content="This is likely due to your invitation link being invalid. Ask your administrator to invite you again or contact Dema support if the error persists."
          />
        )}

        <Button
          mt={8}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          w="full"
          visibility={isUserLoaded && !isSignedIn ? 'visible' : 'hidden'}
        >
          Create account
        </Button>
      </Form>
    </ContainerWrapper>
  )
}
