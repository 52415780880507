import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { type FC } from 'react'
import { FilterGroup } from './shared/FilterGroup/FilterGroup'
import { GroupHeader } from './shared/GroupHeader'
import {
  FilterOperator,
  type AddFilterArgsBase,
  type BaseFilterProps,
} from './types'
import { useSelectedFilterGroup } from './useSelectedFilterGroup'

export type AddFilterArgsMultiSelect = AddFilterArgsBase & {
  value: string[]
}

export type MultiSelectProps = BaseFilterProps & {
  addFilter?: (filter: AddFilterArgsMultiSelect) => void
  selectedOptions?: string[]
}

const emptySelectedOptions: string[] = []

export const SimpleMultiSelectFilter: FC<MultiSelectProps> = ({
  filterId,
  filterLabel,
  options,
  defaultSelectedFilter,
  selectedOptions = emptySelectedOptions,
  isOpen,
  onOpen,
  onClose,
  isOpenByDefault = false,
  isLoading,
  addFilter,
  removeFilter,
  popupButton,
}) => {
  const { selectedFilterGroup, setSelectedFilterGroup } =
    useSelectedFilterGroup({
      filterId,
      defaultSelectedFilter,
      fallback: FilterOperator.isAnyOf,
    })

  const updateFilterSelection = (newOptions: string[]) => {
    // the user deselected all options, so we need to remove this filter
    if (newOptions.length === 0) {
      removeFilter?.(filterId)

      return
    }

    addFilter?.({
      filterId,
      selectedFilterGroup,
      value: newOptions,
    })
  }

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      customHeader={
        <>
          {filterLabel && <GroupHeader label={filterLabel} />}
          <FilterGroup
            options={[FilterOperator.isAnyOf, FilterOperator.isNoneOf]}
            defaultValue={selectedFilterGroup}
            setSelectedFilter={setSelectedFilterGroup}
          />
        </>
      }
      customMenuButton={popupButton}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isOpenByDefault={isOpenByDefault}
      options={options}
      selected={selectedOptions}
      setSelected={updateFilterSelection}
      isLoading={isLoading}
      isFullHeight
      matchWidth={false}
      listProps={{ maxW: 80, minW: '290px' }}
      containerProps={{ maxW: 'full' }} // Used for text truncation
    />
  )
}
