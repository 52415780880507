import { z } from 'zod'

export const GLOBAL_FILTER = {
  STOREFRONT: 'merchantSite',
  CHANNEL: 'channel',
  MARKET: 'market',
  FUNNEL_CAMPAIGN: 'funnelCampaign',
} as const

export type GlobalFilterId = (typeof GLOBAL_FILTER)[keyof typeof GLOBAL_FILTER]

export const GlobalFilterSchema = z.object({
  label: z.string(),
  selected: z.array(z.string()).optional().default([]),
})

export type GlobalFilter = z.infer<typeof GlobalFilterSchema>

export const globalFilters: Record<GlobalFilterId, GlobalFilter> = {
  [GLOBAL_FILTER.STOREFRONT]: {
    label: 'Storefront',
    selected: [],
  },
  [GLOBAL_FILTER.CHANNEL]: {
    label: 'Channel',
    selected: [],
  },
  [GLOBAL_FILTER.MARKET]: {
    label: 'Market',
    selected: [],
  },
  [GLOBAL_FILTER.FUNNEL_CAMPAIGN]: {
    label: 'Funnel',
    selected: [],
  },
}
