import config from 'config'
import { hotjar } from 'react-hotjar'

export const identifyUser = (
  id: string,
  properties: Record<string, unknown>,
) => {
  if (config.APP_ENV === 'production' && hotjar.initialized()) {
    hotjar.identify(id, properties)
  }
}
