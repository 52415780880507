import { useTeamReports } from 'graphql/reports/useTeamReports'
import { useTeamById } from 'graphql/teams/useTeamById'
import { useSetAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { NotFound } from 'shared/NotFound'
import { newReportViewStateAtom } from './NewReportView/atom'
import { ReportListTable } from './shared/ReportListTable'

export const TeamReportsView = () => {
  const { teamId = '' } = useParams()
  const { team, query: teamQuery } = useTeamById(teamId)
  const { reports, query } = useTeamReports(teamId)
  const isLoading = query.loading || teamQuery.loading
  const setNewReportViewState = useSetAtom(newReportViewStateAtom)

  if (!teamQuery.loading && (!teamId || !team)) return <NotFound />

  return (
    <ReportListTable
      pageTitle={team?.name}
      reports={reports}
      isLoading={isLoading}
      key={teamId}
      addReportCallback={() => {
        if (!team) return

        setNewReportViewState((prev) => {
          return {
            ...prev,
            visibility: [team],
          }
        })
      }}
    />
  )
}
