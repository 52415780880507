import { gql, useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { type ReportLabel } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { REPORT_LABELS_QUERY } from 'graphql/reports/useReportLabels'
import { type DefaultDisclosure } from 'hooks/useDefaultDisclosure'
import { cloneDeep, set } from 'lodash-es'
import { ReportLabelPicker } from './ReportLabelPicker'

const CREATE_REPORT_LABEL_MUTATION = gql`
  mutation CreateReportLabel($params: JSONObject!) {
    createReportLabel(params: $params) {
      id
      name
      color
      id
      __typename
      reportCount
    }
  }
`

interface AddLabelModalProps {
  onLabelAddedComplete?: (label: ReportLabel) => void
  modalButton?: React.ReactElement
  disclosure?: DefaultDisclosure
}

export const AddLabelModal: React.FC<AddLabelModalProps> = ({
  onLabelAddedComplete,
  modalButton,
  disclosure,
}) => {
  const [addReportLabel] = useMutation(CREATE_REPORT_LABEL_MUTATION)

  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const onApply = (label: Omit<ReportLabel, 'id'>) => {
    if (!label.name || !label.color) return

    addReportLabel({
      variables: {
        params: {
          name: label.name,
          color: label.color,
        },
      },
      optimisticResponse: {
        createReportLabel: {
          name: label.name,
          color: label.color,
          id: 'optimistic',
          __typename: 'ReportLabel',
          reportCount: 0,
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery(
          { query: REPORT_LABELS_QUERY, variables: { withReportCount: false } },
          (queryData) => {
            if (!queryData?.viewer?.merchant.reportLabels) return queryData

            const queryDataClone = cloneDeep(queryData)

            set(queryDataClone, 'viewer.merchant.reportLabels', [
              ...queryData.viewer.merchant.reportLabels,
              data.createReportLabel,
            ])

            return queryDataClone
          },
        )
      },
      onCompleted: ({ createReportLabel }) => {
        trackEvent({
          eventName: 'Report Label Created',
          eventProperties: {
            labelName: createReportLabel.name,
          },
        })
        onLabelAddedComplete?.(createReportLabel)
      },
      onError: () => {
        toast({
          description: 'Could not add report label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <ReportLabelPicker
      currentLabel={undefined}
      modalButton={modalButton ?? <Button size="sm">Add label</Button>}
      onApply={onApply}
      disclosure={disclosure}
    />
  )
}
