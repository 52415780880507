import { Box, Fade, Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useDimensionGroups } from 'features/reports/components/ReportSidebar/hooks/useDimensionGroups'
import { useMetricGroups } from 'features/reports/components/ReportSidebar/hooks/useMetricGroups'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { isEqual } from 'lodash-es'
import { useState, type FC } from 'react'
import { BaseView } from '../../shared/BaseView'
import { useEditTeamById } from '../graphql/useEditTeamById'
import { DataPicker } from './DataPicker'

interface Props {
  team: MerchantTeam
}

export const DataView: FC<Props> = ({ team }) => {
  const [editTeam] = useEditTeamById()
  const [preferredDimensions, setPreferredDimensions] = useState(
    team.preferredDimensions,
  )
  const [preferredMetrics, setPreferredMetrics] = useState(
    team.preferredMetrics,
  )

  const dimensionGroups = useDimensionGroups({ showDeselect: true })
  const metricGroups = useMetricGroups({
    allowInvalidMetricCombinations: true,
    showDeselect: true,
  })

  const hasDimensionsChanged = !isEqual(
    preferredDimensions,
    team.preferredDimensions,
  )
  const hasMetricsChanged = !isEqual(preferredMetrics, team.preferredMetrics)
  const hasChanged = hasDimensionsChanged || hasMetricsChanged

  return (
    <BaseView
      title="Data"
      description={
        <>
          Manage which dimensions and metrics that should be set as preferred
          for the{' '}
          <Typography as="span" fontWeight={600}>
            {team.name}
          </Typography>{' '}
          team.
        </>
      }
    >
      <Flex direction="column" gap={4} w="full" maxW="360px">
        <DataPicker
          groups={dimensionGroups}
          title="Dimensions"
          subTitle={`${preferredDimensions.length} dimensions selected`}
          selectedItems={preferredDimensions}
          onSelectedItemsChange={(newDimensions) => {
            setPreferredDimensions(newDimensions)
          }}
        />
        <DataPicker
          groups={metricGroups}
          title="Metrics"
          subTitle={`${preferredMetrics.length} Metrics selected`}
          selectedItems={preferredMetrics}
          onSelectedItemsChange={(newMetrics) => {
            setPreferredMetrics(newMetrics)
          }}
        />
      </Flex>

      <Fade in={hasChanged}>
        <Box mt={4}>
          <Button
            variant="solid"
            colorScheme="primary"
            onClick={() => {
              editTeam(team.id, {
                ...team,
                preferredMetrics,
                preferredDimensions,
              })
            }}
          >
            Save
          </Button>
        </Box>
      </Fade>
    </BaseView>
  )
}
