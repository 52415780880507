export const METRIC_FORMAT = {
  INTEGER: 'integer',
  SECONDS: 'seconds',
  CURRENCY: 'currency',
  PERCENT: 'percent',
  PERCENT_INT: 'percent_int',
  DECIMAL: 'decimal',
  WIDGET_NUMBER: 'widgetNumber',
  WIDGET_DECIMAL: 'widgetDecimal',
} as const
