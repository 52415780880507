import {
  getCompareDatePreset,
  getDatePreset,
  type COMPARE_DYNAMIC_DATE_ID,
  type DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import { endOfDay, startOfDay, sub } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import { DEFAULT_ATTRIBUTION } from 'graphql/statistics/useAttributionModels'
import {
  type Dimension,
  type NormalizedDimensions,
} from 'graphql/statistics/useDimensions'
import {
  type AttributedMetric,
  type Metric,
  type NormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import {
  CHART_TYPE_ID,
  chartTypes,
  multiMetricChartTypes,
  type ChartTypeId,
} from 'utils/chart/chartTypes'
import { compareColor, dayDimension, isTimeUnit } from 'utils/chart/constants'
import { type ChartSerie } from './chart/types'

export const getDefaultDateRange = (
  urlDateRange: (string | number | null)[] | null | undefined,
  timezone: string | undefined,
  compare = false,
): DateRange | null | undefined => {
  if (!urlDateRange) {
    return urlDateRange
  }
  const now = new Date()
  const [first, second] = urlDateRange

  const startDate =
    first && timezone
      ? toZonedTime(Number(first), timezone)
      : compare
        ? null
        : sub(startOfDay(now), { weeks: 4 })
  const endDate =
    second && timezone
      ? toZonedTime(Number(second), timezone)
      : compare
        ? null
        : endOfDay(now)

  return [startDate, endDate]
}

export const getPresetFromUrlDynamicDate = (
  urlDynamicDate: string | null | undefined,
) => {
  return urlDynamicDate
    ? getDatePreset(urlDynamicDate as DYNAMIC_DATE_ID)
    : undefined
}

export const getPresetFromUrlCompareDynamicDate = (
  urlDynamicDate: string | null | undefined,
  dateRange: DateRange,
) =>
  getCompareDatePreset(
    urlDynamicDate as COMPARE_DYNAMIC_DATE_ID,
    dateRange[0],
    dateRange[1],
  )

export const defaultDimensions = ['channelGroup']

export const defaultMetrics = [
  'session:count',
  `order:total:${DEFAULT_ATTRIBUTION.id}`,
  `cost:roas:${DEFAULT_ATTRIBUTION.id}`,
  `cost:pRoas:${DEFAULT_ATTRIBUTION.id}`,
  `order:netGrossProfit3:${DEFAULT_ATTRIBUTION.id}`,
  `session:conversionRate`,
]

const isValidXAxis = (
  xAxis: string,
  isScatterPlot: boolean,
  dimensions: string[],
  metrics: string[],
) => {
  return (
    (!isScatterPlot &&
      (dimensions.some((dim) => dim === xAxis) || isTimeUnit(xAxis))) ||
    (isScatterPlot && metrics.some((metric) => metric === xAxis))
  )
}
const isValidYAxis = (yAxis: string, metrics: string[]) => {
  return metrics.some((metric) => metric === yAxis)
}

const isValidGColor = (
  gColor: string,
  dimensions: string[],
  xAxis: string,
  isCompare: boolean,
  isMultiMetric: boolean,
) => {
  return (
    (!isMultiMetric &&
      xAxis !== gColor &&
      dimensions.some((dim) => dim === gColor)) ||
    (gColor === compareColor.id && isCompare)
  )
}
const isValidGType = (gType: ChartTypeId) => {
  return !!chartTypes[gType]
}

export const getDefaultXAxis = (
  urlXAxis: string | null | undefined,
  isScatterPlot: boolean,
  dimensions: string[],
  metrics: string[],
) => {
  if (urlXAxis && isValidXAxis(urlXAxis, isScatterPlot, dimensions, metrics)) {
    return urlXAxis
  }
  if (isScatterPlot) {
    return metrics[0]
  }

  return dayDimension.id
}

export const getDefaultYAxis = (
  urlYAxis: string | null | undefined,
  metrics: string[],
) => {
  if (urlYAxis && isValidYAxis(urlYAxis, metrics)) return urlYAxis

  return metrics[0]
}

export const getDefaultSeries = (
  series: ChartSerie[] | undefined,
  metrics: string[],
) => {
  if ((!metrics || metrics.length === 0) && !series) return []

  const defaultSeries: ChartSerie[] = [
    {
      key: metrics[0],
      type: CHART_TYPE_ID.LINE,
    },
  ]

  if (!series) {
    return defaultSeries
  }

  const validSeries = series.filter(({ key }) => isValidYAxis(key, metrics))

  if (validSeries.length > 1) {
    // Default to line chart type if type is not allow for multi-metric charts
    validSeries.forEach((serie) => {
      if (!multiMetricChartTypes.includes(serie.type)) {
        serie.type = CHART_TYPE_ID.LINE
      }
    })
  }

  return validSeries.length > 0 ? validSeries : defaultSeries
}

export const getDefaultGColor = (
  urlGColor: string | null | undefined,
  dimensions: string[],
  xAxis: string,
  isCompare: boolean,
  isMultiMetric: boolean,
) => {
  if (
    urlGColor &&
    isValidGColor(urlGColor, dimensions, xAxis, isCompare, isMultiMetric)
  ) {
    return urlGColor
  }

  // Default color to compare date when no valid color is used and user has not directly cleared the group
  if (urlGColor !== null && isTimeUnit(xAxis) && isCompare) {
    return compareColor.id
  }

  return undefined
}

export const getValidCompareDateRange = (
  dateRange: DateRange,
  compareDateRange: DateRange,
): DateRange => {
  const [, end] = dateRange
  const [compareStart, compareEnd] = compareDateRange
  let newCompareEnd = compareEnd

  if (compareStart === null || compareEnd === null) return compareDateRange

  // compare range has to be earlier or equal than start range
  if (end !== null && compareEnd > end) {
    newCompareEnd = new Date(end)
  }

  return [compareStart, newCompareEnd]
}

export const getDefaultGType = (urlGType: ChartTypeId | null | undefined) => {
  if (urlGType && isValidGType(urlGType)) return urlGType

  return 'line'
}

export const getActualMetrics = (
  metrics: string[],
  normalizedMetrics: NormalizedMetrics,
) => {
  const metricsSet = new Set(metrics)

  // We loop through normalizedMetrics to guarantee showing the metrics in the same order. Ids could have different order
  return Object.entries(normalizedMetrics)
    .filter(([key]) => metricsSet.has(key))
    .map(([key, value]) => {
      if (
        value.isAttributionAllowed &&
        !(value as AttributedMetric).attributionId
      ) {
        return normalizedMetrics[`${key}:${DEFAULT_ATTRIBUTION.id}`] ?? value
      }

      return value
    })
}

export const getActualDimensions = (
  dimensions: string[],
  normalizedDimensions: NormalizedDimensions,
) => {
  const dimensionsSet = new Set(dimensions)

  // We loop through normalizedDimensions to guarantee showing the dimensions in the same order. Ids could have different order
  return Object.entries(normalizedDimensions)
    .filter(([key]) => dimensionsSet.has(key))
    .map(([, value]) => value)
}

export const initializeMetricsAttribution = (
  selectedItems: Metric[],
): Metric[] => {
  return selectedItems.map((item) => {
    if (item.isAttributionAllowed) {
      const attributionId =
        'attributionId' in item ? item.attributionId : DEFAULT_ATTRIBUTION.id

      return {
        ...item,
        groupKey: item.key,
        key: `${item.key}:${attributionId}`,
        attributionGroupKey: `${item.key}:${attributionId}`,
        attributionId,
      }
    }

    return { ...item }
  })
}

export const getFilterNumericValue = (
  value: string | undefined,
  format: MetricFormat = METRIC_FORMAT.INTEGER,
) => {
  let numberValue = Number(value)

  if (format.toLowerCase().includes(METRIC_FORMAT.PERCENT)) {
    numberValue /= 100
  }

  // Cutoff at 4 decimal places to allow decimal percentages
  return numberValue.toFixed(4)
}

export const getMetricLabelWithAttribution = (
  metric?: Metric,
  attributionLabel?: string,
) => {
  if (!metric) {
    return ''
  }

  return `${metric.label}${attributionLabel ? ` - ${attributionLabel}` : ''}`
}

export const getValidDimensions = (
  dimensions: Dimension[],
  metrics: Metric[],
) => {
  const uniqueMetricDependencies = Array.from(
    new Set(metrics.flatMap((metric) => metric.dependencies)),
  )
  const validDimensions: Dimension[] = []

  for (const dimension of dimensions) {
    const processors = new Set(dimension.processors)
    const hasAllDependencies = uniqueMetricDependencies.every((dependency) =>
      processors.has(dependency),
    )

    if (hasAllDependencies) {
      validDimensions.push(dimension)
    }
  }

  return validDimensions
}

export const getValidMetrics = (
  prevMetrics: Metric[],
  normalizedMetrics: NormalizedMetrics,
  validMetricGroups: string[],
) => {
  const filteredMetrics = prevMetrics
    .map<Metric>((item) => {
      return normalizedMetrics[item.key]
    })
    .filter((metric) =>
      metric?.dependencies.every((dependency) =>
        validMetricGroups.includes(dependency),
      ),
    )

  return filteredMetrics
}

export const getValidMetricProcessors = (
  dimensions: (Dimension | undefined)[],
): string[] => {
  const allMetricProcessors = Array.from(
    new Set(dimensions.flatMap((dim) => dim?.processors)),
  )

  return dimensions.reduce((acc, next) => {
    return acc.filter<string>(
      (processor): processor is string =>
        !!processor &&
        !!next?.processors &&
        next.processors.includes(processor),
    )
  }, allMetricProcessors as string[])
}
