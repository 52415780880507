import {
  type MetricFormat,
  type NormalizedStatistic,
} from 'graphql/statistics/types'
import { type NormalizedDimensions } from 'graphql/statistics/useDimensions'
import type Highcharts from 'highcharts'
import { escape } from 'lodash-es'
import { chartTypes } from 'utils/chart/chartTypes'
import {
  getAxisType,
  getXAxisDateFormat,
  sortByNumberOrString,
  xAxisFormatter,
  yAxisFormatter,
  getXAxisLabel,
  getXAxisValue,
} from 'utils/chart/common'
import {
  isTimeUnit,
  maxNumberOfGroupsForScatterChart,
  normalizedTimeDimensions,
  staticChartOptions,
} from 'utils/chart/constants'
import type { GroupedScatterChartData } from 'utils/chart/types'
import { formatMetricLabel } from 'utils/formatMetricLabel'
import { formatMetric } from 'utils/numberFormats'
import { v4 as uuid } from 'uuid'
import { getGroupLabel } from './common'
import {
  type ScatterChartOptionsProps,
  type GroupedChartDataProps,
} from './types'

const flattenedSeriesName = 'flat'

export const getGroupedChartDataForScatterChart = ({
  data,
  groupBy,
}: GroupedChartDataProps) => {
  const newGroupedData = (data ?? []).reduce<GroupedScatterChartData>(
    (acc, row) => {
      const color = getGroupLabel(groupBy, row)
      const group = acc[color] ?? []

      group.push(row)
      acc[color] = group

      return acc
    },
    {},
  )

  return newGroupedData
}

export const getChartOptionsForScatterChart = ({
  currency,
  groupBy,
  groupedData,
  normalizedDimensions,
  normalizedMetrics,
  xAxis,
  serie: { key, type },
}: ScatterChartOptionsProps) => {
  const groupedDataEntries = Object.entries(groupedData)

  const xAxisLabel = getXAxisLabel(
    xAxis,
    type,
    normalizedDimensions[xAxis],
    normalizedMetrics[xAxis],
    currency,
  )
  const xAxisType = getAxisType(xAxis, !!normalizedMetrics[xAxis])
  const yAxisLabel = formatMetricLabel(normalizedMetrics[key], currency)
  const chartId = `${type}-${xAxis}-${key}-${groupBy}`
  const xAxisId = `${chartId}-xAxis`
  const scatterType = chartTypes[type]
    .type as Highcharts.SeriesScatterOptions['type']

  let series = []

  const sortByXAxisValue = (
    first: NormalizedStatistic,
    second: NormalizedStatistic,
  ) =>
    sortByNumberOrString(
      getXAxisValue(xAxisType, second[xAxis].value ?? ''),
      getXAxisValue(xAxisType, first[xAxis].value ?? ''),
    )

  if (groupedDataEntries.length > maxNumberOfGroupsForScatterChart) {
    const flatData = groupedDataEntries
      .flatMap(([, value]) => value)
      .sort(sortByXAxisValue) // Sorting is needed to avoid some visual glitches

    groupedData = { [flattenedSeriesName]: flatData }

    series = [
      {
        type: scatterType,
        name: flattenedSeriesName,
        data: flatData.map((row) => [
          getXAxisValue(xAxisType, row[xAxis].value ?? ''),
          Number(row[key].value),
        ]),
        id: `${chartId}-${uuid()}`,
        xAxis: xAxisId,
        color: staticChartOptions.colors[0],
      } as Highcharts.SeriesScatterOptions,
    ]
  } else {
    series = groupedDataEntries.map(([dataKey, value], index) => {
      const name = dataKey

      value.sort(sortByXAxisValue) // Sorting is needed to avoid some visual glitches

      return {
        type: scatterType,
        data: value.map((row) => [
          getXAxisValue(xAxisType, row[xAxis].value ?? ''),
          Number(row[key].value),
        ]),
        name,
        id: `${chartId}-${name}-${uuid()}`,
        xAxis: xAxisId,
        color:
          staticChartOptions.colors[index % staticChartOptions.colors.length],
      }
    })
  }

  return {
    ...staticChartOptions,
    plotOptions: {
      ...staticChartOptions.plotOptions,
      series: {
        ...staticChartOptions.plotOptions.series,
        stickyTracking: false,
        lineWidth: 0,
      },
    },
    chart: {
      zooming: { type: 'xy' } as Highcharts.ChartZoomingOptions,
      inverted: false,
    },
    title: { ...staticChartOptions.title },
    xAxis: [
      {
        ...staticChartOptions.xAxis,
        type: xAxisType,
        title: {
          ...staticChartOptions.xAxis.title,
          text: xAxisLabel,
        },
        visible: true,
        labels: {
          ...staticChartOptions.xAxis.labels,
          formatter: xAxisFormatter(xAxis, normalizedMetrics[xAxis]),
        },
        id: xAxisId,
      } as Highcharts.XAxisOptions,
    ],
    yAxis: {
      ...staticChartOptions.yAxis,
      title: {
        ...staticChartOptions.yAxis.title,
        text: yAxisLabel,
      },
      labels: {
        formatter: yAxisFormatter(normalizedMetrics[key]?.format),
      },
      visible: true,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      className: undefined,
      outside: true,
      formatter: tooltipFormatterForScatterChart(
        xAxis,
        xAxisLabel,
        normalizedMetrics[xAxis]?.format,
        yAxisLabel,
        normalizedMetrics[key]?.format,
        groupedData,
        normalizedDimensions,
      ),
    },
    legend: {
      ...staticChartOptions.legend,
      enabled: !!groupBy && series.length > 1,
    },
    series,
    boost: {
      enabled: true,
      useGPUTranslations: true,
      usePreallocated: true,
    },
  }
}

const tooltipFormatterForScatterChart = (
  xAxis: string,
  xAxisLabel?: string,
  xAxisFormat?: MetricFormat,
  yAxisLabel?: string,
  yAxisFormat?: MetricFormat,
  data?: GroupedScatterChartData,
  normalizedDimensions?: NormalizedDimensions,
) =>
  function (this: Highcharts.TooltipFormatterContextObject) {
    let yValue: number | null | undefined | string = this.y

    if (yAxisFormat && this.y) {
      yValue = formatMetric(
        yAxisFormat === 'currency' ? 'integer' : yAxisFormat,
        this.y,
      )
    }
    let xValue: number | null | undefined | string = this.x

    if (xAxisFormat && this.x) {
      xValue = formatMetric(
        xAxisFormat === 'currency' ? 'integer' : xAxisFormat,
        Number(this.x),
      )
    }
    const isXAxisTimeUnit = isTimeUnit(xAxis)
    const escapedPointName = escape(this.point.name)
    const rawPointData = data?.[this.series.name]?.[this.point.index]
    const dimensionsLabel =
      rawPointData && normalizedDimensions
        ? Object.entries(rawPointData)
            .filter(([key]) => normalizedDimensions[key])
            .map(([key, value]) => {
              return `${normalizedDimensions[key].label}:<br/><b>${escape(
                value.formattedValue,
              )}</b><br/>`
            })
            .join('')
        : ''

    return (
      dimensionsLabel +
      '<br/>' +
      (isXAxisTimeUnit ? normalizedTimeDimensions[xAxis].name : xAxisLabel) +
      ':<br/><b>' +
      (isXAxisTimeUnit && this.x
        ? getXAxisDateFormat(xAxis, Number(this.x))
        : xAxisFormat
          ? xValue
          : escapedPointName) +
      '</b><br/><br/>' +
      yAxisLabel +
      ':<br/><b>' +
      yValue +
      '</b>'
    )
  }
