import { Box, Flex } from '@chakra-ui/react'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { useReportStateAtom } from 'features/reports/hooks/useReportState'
import { newReportViewStateAtom } from 'features/reports/views/NewReportView/atom'
import { useEditReportLabels } from 'features/reports/views/shared/ReportLabelsPicker/useEditReportLabels'
import { useReportLabels } from 'graphql/reports/useReportLabels'
import { useAtom } from 'jotai'
import { type FC, useMemo } from 'react'
import {
  ReportLabelsPicker,
  type ReportLabelsPickerProps,
} from '../../../views/shared/ReportLabelsPicker/ReportLabelsPicker'
import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

interface LabelsSectionProps {
  isCreateNewReport: boolean
}

export const LabelsSection: FC<LabelsSectionProps> = ({
  isCreateNewReport,
}) => {
  const { editReportLabels } = useEditReportLabels()
  const { reportLabels, query: reportLabelsQuery } = useReportLabels({
    withReportCount: false,
  })
  const selectedReport = useReportStateAtom()

  const [newReportViewState, setNewReportViewState] = useAtom(
    newReportViewStateAtom,
  )
  const { labels = [] } = isCreateNewReport
    ? newReportViewState
    : (selectedReport ?? {})

  const selectedIds = useMemo(() => labels.map((label) => label.id), [labels])

  const canEditReport = useCanEditReport(selectedReport)

  const commonProps = {
    reportLabels,
    isLoading: reportLabelsQuery.loading,
    selectedReport,
    isDisabled: !canEditReport,
    onLabelsChanged: (newLabels) => {
      if (isCreateNewReport) {
        setNewReportViewState((c) => ({ ...c, newLabels }))
      } else if (selectedReport) {
        editReportLabels({
          selectedReport,
          newReportLabels: newLabels,
          oldReportLabels: labels,
        })
      }
    },
  } satisfies Partial<ReportLabelsPickerProps>

  return (
    <Box>
      <ReportDetailsHeader title="Labels" />

      {labels.length === 0 ? (
        <ReportLabelsPicker {...commonProps} selectedLabelIds={[]} />
      ) : (
        <Flex gap={1} flexWrap="wrap">
          {labels.map((label) => (
            <ReportLabelsPicker
              key={label.id}
              {...commonProps}
              currentLabel={label}
              selectedLabelIds={selectedIds}
            />
          ))}
        </Flex>
      )}
    </Box>
  )
}
