import { useOrganization, useUser } from '@clerk/clerk-react'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { getReportOwnerName } from 'graphql/reports/utils'
import uniqBy from 'lodash-es/uniqBy'
import { useMemo, type FC } from 'react'
import { type ReportListFilterPickerChildProps } from '../types'

export const FilterOptionOwner: FC<ReportListFilterPickerChildProps> = ({
  selectedFilterOption,
  customMenuButton,
  reports,
  isOpenByDefault,
  selectedIds,
  setFilters,
  setSelectedFilterOption,
}) => {
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })
  const { user } = useUser()

  const uniqueOptions = useMemo(() => {
    const options: { id: string; name: string }[] = []

    reports.forEach(({ owner }) => {
      const name = getReportOwnerName({
        owner,
        user,
        memberships: memberships?.data,
      })

      options.push({ ...owner, name })
    })

    return uniqBy(options, 'id')
  }, [memberships?.data, reports, user])

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      isOpenByDefault={isOpenByDefault}
      customMenuButton={customMenuButton}
      options={uniqueOptions}
      selected={selectedIds}
      setSelected={(selected) =>
        setFilters(
          selectedFilterOption,
          uniqueOptions.filter((option) => selected.includes(option.id)),
        )
      }
      onClose={() => setSelectedFilterOption(undefined)}
    />
  )
}
