import { Flex } from '@chakra-ui/react'
import { type ReportResult } from 'graphql/reports/utils'
import { type FC, useState } from 'react'
import { FilterBadge } from './FilterBadge'
import { FilterMenuButton } from './FilterMenuButton'
import { FilterOptionPicker } from './FilterOptionPicker'
import {
  type ReportListFilters,
  type FilterOptionValue,
  type ReportListFilterValues,
} from './types'

type Props = {
  reports: ReportResult[]
  filters: ReportListFilters
  setFilters: (
    option: FilterOptionValue,
    values: ReportListFilterValues,
  ) => void
}

export const ReportListFilter: FC<Props> = ({
  reports,
  filters,
  setFilters,
}) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    FilterOptionValue | undefined
  >(undefined)

  return (
    <Flex gap={2} wrap="wrap" alignItems="center" maxW="100%">
      {Object.entries(filters).map(
        ([option, values]) =>
          values.length > 0 && (
            <FilterBadge
              key={`${option}${values.map(({ id }) => id)}`}
              values={values}
              option={option as FilterOptionValue}
              reports={reports}
              filters={filters}
              setFilters={setFilters}
              onRemoveFilter={() => setFilters(option as FilterOptionValue, [])}
            />
          ),
      )}
      <FilterOptionPicker
        isOpenByDefault={true}
        selectedFilterOption={selectedFilterOption}
        reports={reports}
        filters={filters}
        customMenuButton={<FilterMenuButton />}
        setFilters={setFilters}
        setSelectedFilterOption={setSelectedFilterOption}
      />
    </Flex>
  )
}
