const grey = {
  50: '#FAFAF8',
  100: '#F4F4F1',
  200: '#E8E8E3',
  300: '#CFCFC9',
  400: '#B2B2AC',
  500: '#8F8F8A',
  600: '#757570',
  700: '#54544C',
  800: '#3c3c38',
  900: '#272725',
  950: '#171714',
} as const

const blue = {
  50: '#EDF5FF',
  100: '#D1E1FF',
  200: '#A4BEFF',
  300: '#7B9FFF',
  400: '#4E7FFF',
  500: '#1C58FD',
  600: '#0040EB',
  700: '#002FBD',
  800: '#00218F',
  900: '#001666',
} as const

export const chartColors = {
  jade: {
    300: '#d3f8f3',
    400: '#9bede5',
    500: '#52e0d7',
    600: '#199a96',
    700: '#055e61',
  },
  sky: {
    300: '#CBE0F6',
    400: '#90bfeb',
    500: '#3A8FDF',
    600: '#1361AE',
    700: '#093B6D',
  },
  grape: {
    300: '#d6d0fb',
    400: '#9c8ef0',
    500: '#6c52e0',
    600: '#4215ac',
    700: '#2f096d',
  },
  violet: {
    300: '#eed2f9',
    400: '#d493ec',
    500: '#b242d6',
    600: '#840ea5',
    700: '#58006B',
  },
  terracotta: {
    300: '#ffe7cc',
    400: '#f9c386',
    500: '#ef9d39',
    600: '#c77305',
    700: '#7f4a00',
  },
  dandelion: {
    300: '#fff5cc',
    400: '#FFE87F',
    500: '#fada38',
    600: '#d4b102',
    700: '#8a7300',
  },
} as const

export const chartColorKeys = Object.keys(
  chartColors,
) as (keyof typeof chartColors)[]

export const colorTheme = {
  dema: {
    50: '#FCFFEB',
    100: '#F8FFD1',
    200: '#F0FFA3',
    300: '#E6FF80',
    400: '#D8FF43',
    500: '#C2F806',
    600: '#9AC803',
    700: '#7AA200',
    800: '#5E7F00',
    900: '#2C3800',
  },
  primary: blue,
  blue,
  gray: grey,
  grey,
  green: {
    50: '#E9FEE1',
    100: '#D2FCC5',
    200: '#B9EFA9',
    300: '#94E37D',
    400: '#63D148',
    500: '#409F28',
    600: '#378224',
    700: '#277614',
    800: '#195C0A',
    900: '#0C3602',
  },
  orange: {
    50: '#FEEFE1',
    100: '#FDDFC4',
    200: '#FCC89C',
    300: '#F7AB66',
    400: '#FA8D2D',
    500: '#E96D02',
    600: '#B45100',
    700: '#8F4200',
    800: '#703400',
    900: '#472100',
  },
  red: {
    50: '#FEE2E7',
    100: '#FAC6D0',
    200: '#F4A4B1',
    300: '#EF7187',
    400: '#E43F59',
    500: '#CD1D35',
    600: '#A3142A',
    700: '#880C1C',
    800: '#680815',
    900: '#48050D',
  },
  ...chartColors,

  ////// TODO these colors below are legacy and should be removed
  ///// they are not part of the new brand and design
  teal: {
    50: '#d9fbfb',
    100: '#9ef0f0',
    200: '#3ddbd9',
    300: '#08bdba',
    400: '#009d9a',
    500: '#007d79',
    600: '#005d5d',
    700: '#004144',
    800: '#022b30',
    900: '#081a1c',
  },
  purple: {
    50: '#f6f2ff',
    100: '#e8daff',
    200: '#d4bbff',
    300: '#be95ff',
    400: '#a56eff',
    500: '#8a3ffc',
    600: '#6929c4',
    700: '#491d8b',
    800: '#31135e',
    900: '#1c0f30',
  },
  magenta: {
    50: '#fff0f7',
    100: '#ffd6e8',
    200: '#ffafd2',
    300: '#ff7eb6',
    400: '#ee5396',
    500: '#d12771',
    600: '#9f1853',
    700: '#740937',
    800: '#510224',
    900: '#2a0a18',
  },
  yellow: {
    50: '#fcf4d6',
    100: '#fddc69',
    200: '#f1c21b',
    300: '#d2a106',
    400: '#b28600',
    500: '#8e6a00',
    600: '#684e00',
    700: '#483700',
    800: '#302400',
    900: '#1c1500',
  },
} as const

type ChartColorTheme = keyof typeof chartColors
type ChartColorShadeValue = keyof (typeof colorTheme)[ChartColorTheme]

// We need to exclude the chart colors here as they have different shade values, so the types will be messed up
type ColorValue = Exclude<keyof typeof colorTheme, ChartColorTheme>
type ShadeValue = keyof (typeof colorTheme)[ColorValue]
type ChartColorHexValue =
  (typeof colorTheme)[ChartColorTheme][ChartColorShadeValue]
export type HexColor =
  | (typeof colorTheme)[ColorValue][ShadeValue]
  | ChartColorHexValue

export type ColorType =
  // Gray color palette has different shade range
  | `${Exclude<ColorValue, 'grey' | 'gray' | ChartColorTheme>}.${ShadeValue}`
  | `grey.${keyof typeof grey}`
  | `gray.${keyof typeof grey}`
  | `${ChartColorTheme}.${ChartColorShadeValue}`
  | 'white'
  | 'black'
  | 'transparent'
  | 'inherit'

const additionalColors = ['white', 'black']

export const getColor = (
  colorType: ColorType | undefined,
): string | undefined => {
  if (!colorType) {
    return undefined
  }
  if (additionalColors.includes(colorType)) {
    return colorType
  }

  const [colorKey, shadeKey] = colorType.split('.') as [ColorValue, ShadeValue]
  const color = colorTheme[colorKey]

  if (color) {
    return color[shadeKey]
  }

  return undefined
}

export const colorToRgba = (color: ColorType, opacity: number) => {
  const hex = getColor(color)

  if (!hex) return undefined
  const hexWithoutHash = hex.substring(1)
  const [r, g, b] = [0, 2, 4].map((offset) =>
    parseInt(hexWithoutHash.substring(offset, offset + 2), 16),
  )

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const colorTypeMap: Record<HexColor, ColorType> = Object.entries(
  colorTheme,
).reduce<Record<string, ColorType>>((acc, [colorKey, shades]) => {
  Object.entries(shades).map(([shadeKey, hex]) => {
    acc[hex] = `${colorKey}.${shadeKey}` as ColorType
  })

  return acc
}, {})

export const hexToColorType = (hexColor: HexColor) => colorTypeMap[hexColor]
