import { Tag, type TagProps } from 'components/Tag/Tag'
import { type ParameterTypeId } from '../shared/consts'
import { MappingsActionType, useMappingsAtom } from '../shared/state'

interface Props {
  value: string
  type: ParameterTypeId
  disabled?: boolean
  isReadOnly?: boolean
}

export const UnhandledMappingsCell = ({
  value,
  type,
  disabled,
  isReadOnly,
}: Props) => {
  const [mappingState, setMappingsState] = useMappingsAtom()
  const isSelected = mappingState[type] === value

  const onClick = () => {
    if (disabled || isReadOnly) return

    setMappingsState({
      type: MappingsActionType.SetMappingTypeValue,
      payload: {
        type,
        value: isSelected ? undefined : value,
      },
    })
  }

  const sharedProps: Pick<TagProps, 'size' | 'label' | 'minW' | 'onClick'> = {
    size: 'lg',
    label: value === '' ? '<Empty>' : value,
    minW: 'full',
    onClick,
  }

  if (isSelected) {
    return <Tag variant="solid" colorScheme="blackAlpha" {...sharedProps} />
  }

  return <Tag variant="selectable" colorScheme="grey" {...sharedProps} />
}
