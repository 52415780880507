import { useUser } from '@clerk/clerk-react'
import { useUserReports } from 'graphql/reports/useUserReports'
import { useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { REPORT_PAGE_TITLES } from '../routePages'
import { newReportViewStateAtom } from './NewReportView/atom'
import { ReportListTable } from './shared/ReportListTable'

export const UserReportsView = () => {
  const { userReports, query } = useUserReports()
  const { user } = useUser()
  const setNewReportViewState = useSetAtom(newReportViewStateAtom)

  const privateReports = useMemo(
    () =>
      userReports.filter(
        (report) => report.owner.id === user?.publicMetadata.dema_id,
      ),
    [userReports, user?.publicMetadata.dema_id],
  )

  return (
    <ReportListTable
      pageTitle={REPORT_PAGE_TITLES.USER}
      reports={privateReports}
      isLoading={query.loading}
      addReportCallback={() => {
        setNewReportViewState((prev) => {
          return {
            ...prev,
            visibility: [],
          }
        })
      }}
    />
  )
}
