import { Flex } from '@chakra-ui/react'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { useEffect, useState, type ChangeEvent, type FC } from 'react'
import { type FilterTupleWithNull, type FilterTuple } from '../types'

interface Props {
  label?: string
  defaultValue?: FilterTupleWithNull
  onChange: (value: FilterTupleWithNull) => void
  onSubmit: () => void
}

const emptyDefaultValue: FilterTupleWithNull = [null, null]

export const BetweenFilterInputField: FC<Props> = ({
  label,
  defaultValue = emptyDefaultValue,
  onChange,
  onSubmit,
}) => {
  const [value, setValue] = useState<FilterTupleWithNull>(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    indexToUpdate: number,
  ) => {
    const numberValue = parseFloat(e.target.value)
    const newValue = isFinite(numberValue) ? numberValue : null
    const newTupleValue = value.map((val, ix) =>
      ix === indexToUpdate ? newValue : val,
    ) as FilterTuple

    setValue(newTupleValue)
    onChange(newTupleValue)
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Flex gap={3} alignItems="center">
        <Input
          label={label}
          type="number"
          value={value[0] ?? undefined}
          onChange={(e) => onInputChange(e, 0)}
        />

        <Typography fontSize="xs">and</Typography>

        <Input
          type="number"
          value={value[1] ?? undefined}
          onChange={(e) => onInputChange(e, 1)}
          onKeyUp={(ev) => {
            // Form submits by default when pressing enter when there is a single input field but not if there are multiple.
            // https://stackoverflow.com/questions/4196681/form-not-submitting-when-pressing-enter
            if (ev.key === 'Enter') {
              onSubmit()
            }
          }}
        />
      </Flex>
    </form>
  )
}
