import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  TableContainer,
} from '@chakra-ui/react'
import { DiffBadge } from 'components/Badge/DiffBadge'
import { ButtonIcon } from 'components/buttons'
import { WidgetErrorState } from 'components/Error/WidgetErrorState'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { BaseWidget } from 'features/optimizations/components/BaseWidget'
import { TARGETS } from 'features/optimizations/consts'
import { OPTIMIZATIONS_PAGES } from 'features/optimizations/routePages'
import { usePredictionAtomSetter } from 'features/optimizations/views/Prediction/atoms/atom'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { Link } from 'react-router-dom'
import type { HistoricalOverviewMarket } from '../../hooks/useHistoricalOverviewAnalysis'

interface MissedMarketsProps {
  markets: HistoricalOverviewMarket[] | undefined
  isLoading: boolean
  hasError: boolean
}

const TITLE = 'Market overview'

export const MissedMarkets: React.FC<MissedMarketsProps> = ({
  markets,
  isLoading,
  hasError,
}) => {
  const { currency } = useMerchantInfo()
  const setPredictionState = usePredictionAtomSetter()

  if (isLoading) {
    return (
      <BaseWidget title={TITLE} titleMargin={8}>
        <Table>
          <Tbody>
            <SkeletonTableRows rows={6} columns={4} />
          </Tbody>
        </Table>
      </BaseWidget>
    )
  }

  if (hasError) {
    return (
      <BaseWidget title={null} titleMargin={8}>
        <Box h="full">
          <WidgetErrorState />
        </Box>
      </BaseWidget>
    )
  }

  return (
    <BaseWidget title={TITLE} titleMargin={8}>
      <Box>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th
                  fontSize="sm"
                  color="gray.900"
                  lineHeight={5}
                  fontWeight={600}
                  border="none"
                >
                  Market
                </Th>
                <Th
                  fontSize="sm"
                  color="gray.900"
                  lineHeight={5}
                  fontWeight={600}
                  border="none"
                >
                  Spend
                </Th>
                <Th
                  fontSize="sm"
                  color="gray.900"
                  lineHeight={5}
                  fontWeight={600}
                  border="none"
                >
                  Potential
                </Th>
                <Th w="1px" border="none"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {markets?.map((market) => {
                return (
                  <Tr key={market.id}>
                    <Td color="gray.700" fontSize="sm" lineHeight={5} py={3}>
                      {market.name}
                    </Td>
                    <Td py={3}>
                      <Flex align="center">
                        <DiffBadge
                          value={market.optimalSpend}
                          compareValue={market.actualSpend}
                          format={METRIC_FORMAT.CURRENCY}
                          currency={currency}
                          includePrefix
                          badgeProps={{
                            py: 2,
                            h: 'unset',
                            fontWeight: 600,
                          }}
                        />
                      </Flex>
                    </Td>
                    <Td py={3}>
                      <Flex align="center">
                        <DiffBadge
                          value={market.missedPotential}
                          compareValue={1}
                          format={METRIC_FORMAT.DECIMAL}
                          currency={currency}
                          includePrefix
                          displayType="value"
                          badgeProps={{
                            py: 2,
                            h: 'unset',
                            fontWeight: 600,
                          }}
                        />
                      </Flex>
                    </Td>
                    <Td py={3} width="max-content">
                      <Flex gap={1}>
                        <ButtonIcon
                          as={Link}
                          to={OPTIMIZATIONS_PAGES.MARKET_WEEKLY_ANALYSIS(
                            market.id,
                          )}
                          name="DataAnalytics"
                          title="Weekly analysis"
                          size="sm"
                          colorScheme="gray"
                          variant="ghost"
                        />
                        <ButtonIcon
                          as={Link}
                          to={OPTIMIZATIONS_PAGES.MARKET_YEARLY_ANALYSIS(
                            market.id,
                          )}
                          name="ClockIcon"
                          title="Yearly overview"
                          size="sm"
                          colorScheme="gray"
                          variant="ghost"
                        />
                        <ButtonIcon
                          as={Link}
                          to={OPTIMIZATIONS_PAGES.MARKET_PREDICTION(market.id)}
                          onClick={() =>
                            setPredictionState(
                              {
                                target: TARGETS.BUDGET,
                                targetValue: Math.round(market.actualSpend),
                                channelBounds: [],
                              },
                              {
                                datePresetId: DYNAMIC_DATE_ID.NEXT_WEEK,
                                compareDatePresetId:
                                  COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
                              },
                            )
                          }
                          name="PlusSmallIcon"
                          title="Future scenario"
                          size="sm"
                          colorScheme="gray"
                          variant="ghost"
                        />
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </BaseWidget>
  )
}
