import { type Filter } from 'constants/types'
import type {
  OrganizationMembershipResource,
  UserResource,
  OrganizationResource,
} from '@clerk/types'
import { ORGANIZATION_ID } from 'features/reports/consts'
import { type TableState } from 'features/reports/hooks/useAnalyticsTableState'
import { type ChartState } from 'features/reports/utils/chart/types'
import {
  type MerchantFieldsFragment,
  type OwnerFieldsFragment,
  type ReportFieldsFragment,
  type ReportWithOwnerFieldsFragment,
  type TeamFieldsFragment,
} from 'generated/graphql/graphql'

type ReportVisibilityType = 'private' | 'team' | 'organisation'

export const REPORT_VISIBILITY = {
  PRIVATE: 'private',
  TEAM: 'team',
  ORGANISATION: 'organisation',
} satisfies Record<string, ReportVisibilityType>

export const REPORT_VISIBILITY_SORT_ORDER = {
  [REPORT_VISIBILITY.PRIVATE]: 0,
  [REPORT_VISIBILITY.ORGANISATION]: 1,
  [REPORT_VISIBILITY.TEAM]: 2,
} satisfies Record<ReportVisibilityType, number>

export const getReportVisibilityType = (
  visibility?: ReportVisibility[],
): ReportVisibilityType => {
  if (!visibility || visibility.length === 0) return REPORT_VISIBILITY.PRIVATE
  if (getIsMerchantVisibility(visibility)) return REPORT_VISIBILITY.ORGANISATION

  return REPORT_VISIBILITY.TEAM
}

export const getIsDemaReport = (owner?: OwnerFieldsFragment) => {
  return owner && owner.id === ''
}

export const getIsMerchantVisibility = (visibility: ReportVisibility[]) =>
  visibility[0]?.__typename === 'Merchant'

export const getMerchantVisibilityFromOrganization = (
  organization: OrganizationResource | null | undefined,
): MerchantFieldsFragment[] => {
  return [
    {
      id: String(organization?.publicMetadata.dema_id),
      name: String(organization?.name),
      __typename: 'Merchant',
    },
  ]
}

export const mapToVisibility = (visibility?: ReportVisibility[]) =>
  visibility?.map(({ __typename, id }) =>
    __typename === 'Merchant' ? ORGANIZATION_ID : id,
  ) || []

type Props = {
  owner: OwnerFieldsFragment
  user?: UserResource | null | undefined
  memberships: OrganizationMembershipResource[] | undefined
}

export const getReportOwnerName = ({ owner, user, memberships }: Props) => {
  const userId = user?.publicMetadata.dema_id
  const ownerDetails = memberships?.find(
    (member) => member.publicUserData.userId === owner?.externalId,
  )

  if (userId === owner?.id) return 'You'
  if (getIsDemaReport(owner)) return 'Dema'

  const { firstName, lastName } = ownerDetails?.publicUserData ?? {}

  return `${firstName} ${lastName}`
}

export const transformReport = (report: InputReport[number]): ReportResult => {
  return report as unknown as ReportResult
}

export const transformReports = (reports: InputReport) => {
  return reports?.map(transformReport)
}

type EnhancedReportTypes = {
  filters: Record<string, Filter[]>
  tableState: TableState
  chart: ChartState
}
type ReportVisibility = TeamFieldsFragment | MerchantFieldsFragment

type InputReport = ReportWithOwnerFieldsFragment[]

export type ReportResult = Omit<
  ReportFieldsFragment,
  'filters' | 'tableState' | 'chart' | 'visibility' | 'owner'
> & {
  owner: OwnerFieldsFragment
  visibility: ReportVisibility[]
} & EnhancedReportTypes
