import { Flex, Skeleton } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { calcPercentageDiff } from 'utils/calcPercentageDiff'
import { formatMetric } from 'utils/numberFormats'
import { getCompareCellColor } from 'utils/tableUtils'
import { type HeaderTotalSectionProps } from '../types'

export const HeaderTotalSection = ({
  metric,
  aggregatedData,
  isCompare,
  isLoading,
}: HeaderTotalSectionProps) => {
  if (!metric) {
    return null
  }
  if (isLoading) {
    return (
      <Flex flexDir="column">
        <Skeleton w="80px" h="16px" alignSelf="flex-end" />
        <Skeleton w="50px" h="14px" mt="4px" alignSelf="flex-end" />
      </Flex>
    )
  }

  const baseStatistic = aggregatedData?.[0]?.[metric.key]

  const compareValue = baseStatistic
    ? Number(baseStatistic.comparisonValue ?? 0)
    : undefined
  const baseValue = Number(baseStatistic?.value ?? 0)

  const difference =
    compareValue && isFinite(compareValue) && compareValue !== 0
      ? calcPercentageDiff(baseValue, compareValue) / 100
      : undefined
  const formattedDifference = difference
    ? formatMetric(METRIC_FORMAT.PERCENT, difference)
    : 0
  // Display currency as integer
  const value =
    metric.format === METRIC_FORMAT.CURRENCY
      ? formatMetric(METRIC_FORMAT.INTEGER, Number(baseStatistic?.value))
      : baseStatistic?.formattedValue
  const displayDifference =
    difference && isFinite(difference)
      ? `${difference > 0 ? '+' : ''}${formattedDifference}`
      : 'N/A'

  if (!value) {
    return null
  }
  const isReversePositive = !!metric.isReversePositive

  return (
    <Flex flexDir="column">
      <Typography
        textAlign="end"
        noOfLines={1}
        color="grey.900"
        fontSize="md"
        whiteSpace="nowrap"
        display="block"
      >
        {value}
      </Typography>
      {isCompare && (
        <Typography
          noOfLines={1}
          lineHeight="18px"
          fontSize="11px"
          fontWeight={300}
          color={getCompareCellColor(difference, isReversePositive)}
          textAlign="end"
        >
          {displayDifference}
        </Typography>
      )}
    </Flex>
  )
}
