import { TARGETS, type TargetType } from 'features/optimizations/consts'
import { type PredictionStore, type PredictionState } from '../../atoms/atom'
import { type SidebarFormState } from './types'

const defaultTargetState = {
  [TARGETS.BUDGET]: undefined,
  [TARGETS.ROAS]: undefined,
  [TARGETS.EP_ROAS]: undefined,
}

export const getFormValuesFromState = (
  state: PredictionState,
): SidebarFormState => {
  const { target, targetValue, channelBounds } = state

  return {
    channelBounds: channelBounds.reduce<Record<string, [number, number]>>(
      (acc, { channel, range }) => {
        acc[channel] = range

        return acc
      },
      {},
    ),
    target: {
      ...defaultTargetState,
      [target]: targetValue ? String(targetValue) : undefined,
    } as Record<TargetType, string | undefined>,
    selectedTarget: target,
  }
}

export const getStateValueFromForm = (
  formState: SidebarFormState,
): PredictionStore => {
  const { target, channelBounds, selectedTarget } = formState

  const targetValue = Number(target[selectedTarget])

  return {
    target: selectedTarget,
    targetValue: isFinite(targetValue) ? targetValue : 0,
    channelBounds: Object.entries(channelBounds).map(([channel, range]) => ({
      channel,
      range,
    })),
  }
}
