import { getDatePreset } from 'constants/getDatePresets'
import { DatePreview } from 'components/Datepicker/DatePreview'
import { RangeDatepicker } from 'components/Datepicker/RangeDatepicker/RangeDatepicker'
import { type DatepickerApplyStateProps } from 'components/Datepicker/RangeDatepicker/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import {
  compareDynamicDates,
  dynamicDates,
  useDateState,
  useDateStateQueryParam,
} from 'features/reports/hooks/useDateState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { dateRangeToTimestamp, toUTCDateRange } from 'utils/dateRange'

export const DateRangePicker = () => {
  const { dynamicDate, dateRange, compareDateRange, compareDynamicDate } =
    useDateState()
  const [, setDateState] = useDateStateQueryParam()
  const { timezone } = useMerchantInfo()
  const dateLabel = dynamicDate ? getDatePreset(dynamicDate)?.name : undefined
  const [trackEvent] = useTrackEvent()

  const onApply = ({
    dateRange: newDateRange,
    dynamicDate: newDynamicDate,
    compareDateRange: newCompareDateRange,
    compareDynamicDate: newCompareDynamicDate,
  }: DatepickerApplyStateProps) => {
    const timestampDateRange = dateRangeToTimestamp(
      toUTCDateRange(newDateRange, timezone),
    )
    const timestampCompareDateRange = dateRangeToTimestamp(
      toUTCDateRange(newCompareDateRange, timezone),
    )

    const newDateValues = {
      dynamicDate: newDynamicDate,
      dateRange: getIsPopulatedDateRange(timestampDateRange)
        ? timestampDateRange
        : undefined,
      compareDateRange: getIsPopulatedDateRange(timestampCompareDateRange)
        ? timestampCompareDateRange
        : null,
      compareDynamicDate: newCompareDynamicDate,
      isCompare: getIsPopulatedDateRange(timestampCompareDateRange),
    } satisfies Parameters<typeof setDateState>['0']

    trackEvent({
      eventName: 'Report Dates Changed',
      eventProperties: newDateValues,
    })

    setDateState(newDateValues)
  }

  const hasCompareDate = getIsPopulatedDateRange(compareDateRange)

  return (
    <RangeDatepicker
      popupButton={
        <DatePreview
          actualDate={dateRange}
          compareDate={hasCompareDate ? compareDateRange : undefined}
          badgeLabel={dateLabel}
        />
      }
      dateRange={dateRange}
      dynamicDate={dynamicDate}
      dynamicDateOptions={dynamicDates}
      compareDynamicDateOptions={compareDynamicDates}
      compareDateRange={compareDateRange}
      compareDynamicDate={compareDynamicDate}
      key={`${dateRange.join(',')}:${compareDateRange.join(',')}:${dynamicDate}:${compareDynamicDate}`}
      onApply={onApply}
    />
  )
}
