import { type SortDirection } from 'ag-grid-community'
import { Icon } from 'components/Icon/Icon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { type PropsWithChildren } from 'react'
import { getHeaderIcon } from 'utils/tableUtils'

interface Props {
  isMetric?: boolean
  showIcon?: boolean
  sortDirection?: SortDirection
  description: string | undefined
}
export const HeaderText = ({
  isMetric,
  children,
  showIcon,
  sortDirection,
  description,
}: PropsWithChildren<Props>) => {
  const headerIcon = sortDirection && getHeaderIcon(sortDirection)

  return (
    <Tooltip label={description} placement="top">
      <Typography
        fontSize="sm"
        fontWeight={600}
        lineHeight="20px"
        noOfLines={3}
        textAlign={isMetric ? 'end' : 'start'}
        color="grey.900"
        _hover={{ color: 'primary.500' }}
        overflowWrap="anywhere"
        as="span"
      >
        {children}
        {showIcon && headerIcon && (
          <Icon
            name={headerIcon}
            size="small"
            verticalAlign="middle"
            className="icon"
            display={sortDirection ? 'inline' : 'none'}
          />
        )}
      </Typography>
    </Tooltip>
  )
}
