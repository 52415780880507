import { Box } from '@chakra-ui/react'
import { Button } from 'components/buttons'

import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { addYears, format, startOfYear, subYears } from 'date-fns'
import { useEffect, useState, type FC } from 'react'
import DatePicker from 'react-datepicker'
import type { FieldError } from 'react-hook-form'
import { DATE_FORMAT } from '../consts'
import { CustomDay } from '../CustomDay'
import CustomHeader from '../CustomHeader'
import '../datepicker.scss'

type Props = {
  className?: string
  error?: FieldError
  label?: string
  placeholder?: string
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
  fullWidth?: boolean
}

const startOfCurrentYear = startOfYear(new Date())
const minDate = subYears(startOfCurrentYear, 4)
const maxDate = addYears(startOfCurrentYear, 4)

export const SingleDatepicker: FC<Props> = ({
  className,
  error,
  label,
  placeholder,
  selectedDate,
  fullWidth = false,
  setSelectedDate,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (selectedDate) {
      setIsOpen(false)
    }
  }, [selectedDate])

  return (
    <Box w={fullWidth ? '100%' : 'auto'}>
      {label && (
        <Typography fontSize="xs" mb={2}>
          {label}
        </Typography>
      )}
      <Box pos="relative" overflowY="hidden" className={className}>
        <Popup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          placement="bottom"
          size="small"
          popupButton={
            <Button
              leadingIcon={{ name: 'CalendarDayIcon' }}
              variant="outline"
              colorScheme="grey"
              minWidth="100%"
              justifyContent="flex-start"
              borderColor={error ? 'red.500' : undefined}
              fontWeight={400}
            >
              {selectedDate
                ? format(selectedDate, DATE_FORMAT)
                : (placeholder ?? '')}
            </Button>
          }
        >
          <Box className="datepicker-container single-datepicker">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              renderCustomHeader={(params) =>
                CustomHeader({ ...params, minDate, maxDate })
              }
              renderDayContents={CustomDay}
              calendarStartDay={1}
              minDate={minDate}
              maxDate={maxDate}
              inline
            />
          </Box>
        </Popup>
      </Box>
      {error && (
        <Typography fontSize="xs" color={error ? 'red.500' : undefined}>
          {error.message}
        </Typography>
      )}
    </Box>
  )
}
