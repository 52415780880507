import { useFiltersState, useFiltersStateQueryParam } from './useFiltersState'

export const useRemoveFilter = () => {
  const filters = useFiltersState()
  const [, setFilters] = useFiltersStateQueryParam()

  return (filterId: string) => {
    const newFilters = { ...filters }

    delete newFilters[filterId]

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters)

      return
    }
    setFilters(null)
  }
}
