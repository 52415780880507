import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'

const { abs, floor } = Math

const DEFAULT_LOCALE = 'en-GB'

const getIntlFormat = ({
  style,
  minimumFractionDigits,
  maximumFractionDigits,
  currency,
  notation,
}: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat(DEFAULT_LOCALE, {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    currency,
    notation,
  })
}

const intlIntegerFormat = getIntlFormat({
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})
const intlDecimalFormat = getIntlFormat({
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
const intlWidgetIntegerFormat = getIntlFormat({
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  notation: 'compact',
})
const intlWidgetSingleDecimalFormat = getIntlFormat({
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  notation: 'compact',
})
const intlWidgetDecimalFormat = getIntlFormat({
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  notation: 'compact',
})
const intlNoDecimalPercentFormat = getIntlFormat({
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})
const intlSingleDecimalPercentFormat = getIntlFormat({
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})
const intlDoubleDecimalPercentFormat = getIntlFormat({
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const currencyFormatCache: Record<string, Intl.NumberFormat> = {}

const formatCurrency = (amount: number, currency?: string) => {
  if (!currency) return String(amount)

  if (!currencyFormatCache[currency]) {
    currencyFormatCache[currency] = getIntlFormat({
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currency,
    })
  }

  return formatValue(currencyFormatCache[currency], amount)
}

const formatTimeRange = (dirtyTimeStart: number, dirtyTimeEnd?: number) => {
  const timeStart = new Date(dirtyTimeStart)
  const timeEnd =
    dirtyTimeEnd === undefined ? new Date(0) : new Date(dirtyTimeEnd)
  const range = abs(timeEnd.getTime() - timeStart.getTime())

  const seconds = floor(range / 1000) % 60
  const minutes = floor(range / 1000 / 60) % 60
  const hours = floor(range / 1000 / 60 / 60)

  return (
    (hours > 0 ? String(hours).padStart(2, '0') + ':' : '') +
    String(minutes).padStart(2, '0') +
    ':' +
    String(seconds).padStart(2, '0')
  )
}

const formatWidget = (type: 'number' | 'decimal', value: number) => {
  let formatter = intlWidgetSingleDecimalFormat

  if (Math.abs(value) < 1000) {
    if (type === 'decimal') {
      formatter = intlWidgetDecimalFormat
    } else {
      formatter = intlWidgetIntegerFormat
    }
  }

  return formatter.format(value)
}

const formatValue = (formatter: Intl.NumberFormat, value: number) =>
  formatter.format(value).replaceAll(',', ' ')

type NumberOfDecimals = 0 | 1 | 2

const numberOfDecimalsMapping: Record<NumberOfDecimals, Intl.NumberFormat> = {
  0: intlNoDecimalPercentFormat,
  1: intlSingleDecimalPercentFormat,
  2: intlDoubleDecimalPercentFormat,
}

export const formatPercentage = (
  value: number,
  numberOfDecimals: NumberOfDecimals,
) => {
  const intlPercentFormat = numberOfDecimalsMapping[numberOfDecimals]

  return formatValue(intlPercentFormat, value)
}

export const formatMetric = (
  format: MetricFormat | undefined = undefined,
  value: number,
  currency?: string,
) => {
  if (!isFinite(value)) return ''

  switch (format) {
    case METRIC_FORMAT.DECIMAL:
      return formatValue(intlDecimalFormat, value)
    case METRIC_FORMAT.PERCENT:
      return formatValue(intlDoubleDecimalPercentFormat, value)
    case METRIC_FORMAT.PERCENT_INT:
      return formatValue(intlNoDecimalPercentFormat, value)
    case METRIC_FORMAT.CURRENCY:
      return formatCurrency(value, currency)
    case METRIC_FORMAT.SECONDS:
      return formatTimeRange(value * 1000)
    case METRIC_FORMAT.WIDGET_NUMBER:
      return formatWidget('number', value)
    case METRIC_FORMAT.WIDGET_DECIMAL:
      return formatWidget('decimal', value)
    case METRIC_FORMAT.INTEGER:
    default:
      return formatValue(intlIntegerFormat, value)
  }
}
