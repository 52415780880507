import {
  ReportPanelOpenState,
  useReportPanelOpenAtom,
} from 'features/reports/atoms/reportPanelOpenAtom'
import { type FC } from 'react'
import { ChartSettingsPanel } from './ChartSettingsPanel/ChartSettingsPanel'
import { ReportDetailsPanel } from './ReportDetailsPanel/ReportDetailsPanel'

interface Props {
  isCreateNewReport?: boolean
}

export const ReportPanel: FC<Props> = ({ isCreateNewReport = false }) => {
  const [reportPanelOpen] = useReportPanelOpenAtom()

  if (reportPanelOpen === ReportPanelOpenState.CHART_SETTINGS) {
    return <ChartSettingsPanel />
  }

  if (reportPanelOpen === ReportPanelOpenState.REPORT_DETAILS) {
    return <ReportDetailsPanel isCreateNewReport={isCreateNewReport} />
  }

  return null
}
