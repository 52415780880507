import { Box, Flex, List, type HTMLChakraProps } from '@chakra-ui/react'
import {
  defaultRangeExtractor,
  useVirtualizer,
  type Range,
} from '@tanstack/react-virtual'
import { SelectDeselectButton } from 'components/Filters/SelectDeselectButton'
import { Typography } from 'components/Typography'
import {
  Fragment,
  useCallback,
  useMemo,
  useRef,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { menuSizes } from 'ui/components/menu'
import { type DropdownGroup, type DropdownOption } from '../../types'
import { useSelectDeselect } from '../utils'
import { ComboBoxItem } from './ComboBoxItem'
import { type ComboBoxItemsProps } from './ComboBoxItems'

// Inspired by tanstack sticky example: https://tanstack.com/virtual/latest/docs/framework/react/examples/sticky
export const GroupedListItems = <TItem extends DropdownOption>({
  size,
  items,
  selected,
  isOption,
  hasSelectDeselect,
  setSelected,
  highlightedIndex,
  getItemProps,
  itemWrapper,
}: Omit<ComboBoxItemsProps<TItem>, 'filteredOptions'> & {
  items: DropdownGroup<TItem>[]
}) => {
  const activeStickyIndexRef = useRef(0)

  const listRef = useRef<HTMLUListElement | null>(null)

  const flattenedItems = useMemo(() => {
    const result: (TItem | DropdownOption)[] = []

    for (const group of items) {
      result.push({ id: group.name, name: group.name })
      for (const item of group.items) {
        result.push(item)
      }
    }

    return result
  }, [items])
  const stickyIndexes = useMemo(
    () =>
      items.map((item) => flattenedItems.findIndex((i) => i.id === item.name)),
    [flattenedItems, items],
  )
  const isSticky = useCallback(
    (index: number) => stickyIndexes.includes(index),
    [stickyIndexes],
  )
  const estimateSize = useCallback(() => {
    return (menuSizes[size]?.item.h || 0) * 4
  }, [size])

  const isActiveSticky = (index: number) =>
    activeStickyIndexRef.current === index

  const rowVirtualizer = useVirtualizer({
    count: flattenedItems.length,
    getScrollElement: () => listRef.current,
    estimateSize,
    overscan: 10,
    rangeExtractor: useCallback(
      (range: Range) => {
        const newActive =
          [...stickyIndexes]
            .reverse()
            .find((index) => range.startIndex >= index) ?? 0

        activeStickyIndexRef.current = newActive

        const next = new Set([newActive, ...defaultRangeExtractor(range)])

        return [...next].sort((a, b) => a - b)
      },
      [stickyIndexes],
    ),
  })

  return (
    <List variant="menu" ref={listRef} width="full" overflowY="auto">
      <Box
        style={{
          position: 'relative',
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer
          .getVirtualItems()
          .map(({ index, start, size: virtualRowSize }) => {
            const option = flattenedItems[index]
            const optionId = option.id.toString()
            const isSelected = Array.isArray(selected)
              ? selected.includes(optionId)
              : selected === optionId
            // used for rendering the different groups
            // because we render using the groups, we need to account for the offset index
            // without this, selecting, hovering and up/down arrows will not work as expected
            // This value is how many groups are above the current index
            const groupOffset = stickyIndexes.filter((i) => i < index).length
            const indexWithoutGroupOffset = index - groupOffset
            const isHighlighted = highlightedIndex === indexWithoutGroupOffset
            const itemStyles: HTMLChakraProps<'li'> = {
              ...(isSticky(index)
                ? {
                    background: '#fff',
                    borderTop: '1px solid',
                    borderColor: 'gray.200',
                    zIndex: 1,
                  }
                : {}),
              ...(isActiveSticky(index)
                ? {
                    position: 'sticky',
                    borderTop: '1px solid',
                    borderColor: 'transparent',
                  }
                : {
                    position: 'absolute',
                    transform: `translateY(${start}px)`,
                  }),
              top: 0,
              left: 0,
              h: `${virtualRowSize}px`,
              maxH: `${virtualRowSize}px`,
            }

            if (isSticky(index) || isActiveSticky(index)) {
              const group = items.find((group) => group.name === option.name)

              return (
                <GroupHeaderItem
                  key={option.id}
                  option={option}
                  itemStyles={itemStyles}
                  hasSelectDeselect={hasSelectDeselect && group?.showDeselect}
                  groupItems={group?.items ?? []}
                  selected={selected}
                  setSelected={setSelected}
                />
              )
            }

            const itemComponent = (
              <ComboBoxItem
                isHighlighted={isHighlighted}
                isOption={isOption}
                isSelected={isSelected}
                itemProps={getItemProps({
                  index: indexWithoutGroupOffset,
                  item: option,
                  'aria-selected': isSelected,
                })}
                itemStyles={itemStyles}
                leftItem={option.leftItem}
                rightItem={option.rightItem}
                option={option}
              />
            )

            // This is only to be able to use JSX syntax
            const ItemWrapper = itemWrapper

            return ItemWrapper ? (
              // DropdownOption is only a type in option since we push the groups to the flattened array to have sticky headers. Otherwise it is always a TItem
              <ItemWrapper key={index} index={index} item={option as TItem}>
                {itemComponent}
              </ItemWrapper>
            ) : (
              <Fragment key={index}>{itemComponent}</Fragment>
            )
          })}
      </Box>
    </List>
  )
}

interface GroupHeaderItemProps {
  option: DropdownOption
  itemStyles: HTMLChakraProps<'li'>
  hasSelectDeselect?: boolean
  groupItems: DropdownOption[]
  selected?: string[] | string
  setSelected?: Dispatch<SetStateAction<string[]>>
}

const GroupHeaderItem = ({
  option,
  itemStyles,
  hasSelectDeselect,
  groupItems,
  selected,
  setSelected,
}: GroupHeaderItemProps) => {
  const { isAllSelected, toggleAllSelected } = useSelectDeselect({
    options: groupItems,
    selected,
    enabled: hasSelectDeselect,
  })

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="full"
      px={3}
      sx={itemStyles}
    >
      <Typography fontWeight={600} color="gray.700" fontSize="sm">
        {`${option.name} `}
        {option.subLabel && (
          <Typography as="span" fontSize="xs" fontWeight={400}>
            {option.subLabel}
          </Typography>
        )}
      </Typography>
      {hasSelectDeselect && (
        <SelectDeselectButton
          isAllSelected={isAllSelected}
          toggleAllSelected={() => setSelected?.(toggleAllSelected)}
        />
      )}
    </Flex>
  )
}
