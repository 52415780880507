import { type DropdownOption } from 'components/Dropdown'
import { startOfDay } from 'date-fns'
import { type RefinementCtx } from 'zod'
import { type MarketingDimensions } from './types'

export const dateFormat = 'yyyy-MM-dd'
export const startOfToday = startOfDay(new Date())

export const TAXONOMY_DATA_TYPES = {
  PER_ORDER: 'perOrder',
  FIXED: 'fixed',
  PER_VISIT: 'perVisit',
  REVENUE_SHARE: 'revenueShare',
} as const

export const costTypes: DropdownOption[] = [
  { id: TAXONOMY_DATA_TYPES.PER_ORDER, name: 'Per order' },
  { id: TAXONOMY_DATA_TYPES.FIXED, name: 'Fixed cost per day' },
  { id: TAXONOMY_DATA_TYPES.PER_VISIT, name: 'Per visit' },
  { id: TAXONOMY_DATA_TYPES.REVENUE_SHARE, name: 'Revenue share' },
]

export const hasAtLeastOneInputFilled = (
  marketingDimensions: MarketingDimensions,
  ctx: RefinementCtx,
) => {
  const isAllFieldsEmpty = Object.values(marketingDimensions).every(
    (md) => !md || md.length === 0,
  )

  if (isAllFieldsEmpty) {
    Object.keys(marketingDimensions).forEach((key) =>
      ctx.addIssue({
        code: 'custom',
        message: 'Input at least one dimension',
        fatal: true,
        path: [key],
      }),
    )
  }
}
