import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type ReportWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { transformReports } from './utils'

const DEMA_REPORTS_QUERY = graphql(/* GraphQL */ `
  query DemaReportsQuery {
    demaReports {
      ...ReportWithOwnerFields
    }
  }
`)

const emptyArray: ReportWithOwnerFieldsFragment[] = []

export const useDemaReports = () => {
  const query = useQuery(DEMA_REPORTS_QUERY)

  const reportsData = query.data?.demaReports ?? emptyArray

  const reports = useMemo(() => {
    return transformReports(reportsData)
  }, [reportsData])

  return { demaReports: reports, query }
}
