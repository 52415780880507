import { useToast } from '@chakra-ui/react'
import { useChartState } from 'features/reports/hooks/useChartState'
import { useDateState } from 'features/reports/hooks/useDateState'
import {
  getChartOptions,
  getGroupedSeriesData,
} from 'features/reports/utils/chart/common'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type Highcharts from 'highcharts'
import { useEffect, useState } from 'react'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import {
  maxNumberOfGroupsForScatterChart,
  staticChartOptions,
} from 'utils/chart/constants'

export const useChartOptions = (
  data: NormalizedStatistic[],
  isLoading: boolean,
) => {
  const { currency, timezone } = useMerchantInfo()
  const [{ xAxis, color: chartGroup, series }, setChartState] = useChartState()
  const dateState = useDateState()

  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()

  const [options, setOptions] = useState<Highcharts.Options>(
    staticChartOptions as Highcharts.Options,
  )
  const toast = useToast()

  useEffect(() => {
    if (isLoading) {
      return
    }

    const groupedData = getGroupedSeriesData({
      data,
      groupBy: chartGroup,
      series,
      xAxis,
      timezone,
    })
    const scatterSeries = series.find(
      ({ type }) => type === CHART_TYPE_ID.SCATTER,
    )
    const groupsCount = scatterSeries
      ? Object.keys(groupedData[scatterSeries.key] ?? {}).length
      : 0

    if (scatterSeries && groupsCount > maxNumberOfGroupsForScatterChart) {
      setChartState({ gColor: null })
      toast({
        status: 'error',
        title: 'Unable to group chart',
        description: `${
          normalizedDimensions[chartGroup ?? ''].label
        } has too many values. Consider filtering or selecting a different dimension with fewer values`,
        position: 'top-right',
        containerStyle: {
          width: '400px',
        },
        isClosable: true,
      })

      return
    }

    const options = getChartOptions({
      currency,
      groupBy: chartGroup,
      series,
      groupedData,
      normalizedDimensions,
      normalizedMetrics,
      xAxis,
      dateState,
      data,
      timezone,
    })

    setOptions(options)
  }, [
    currency,
    data,
    chartGroup,
    isLoading,
    normalizedDimensions,
    normalizedMetrics,
    xAxis,
    timezone,
    setChartState,
    toast,
    series,
    dateState,
  ])

  return options
}
