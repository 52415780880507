import { type QueryHookOptions } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type DimensionsValueQueryQuery,
  type DimensionsValueQueryQueryVariables,
} from 'generated/graphql/graphql'
import { useQueryWithRetry } from 'hooks/useQueryWithRetry'
import { type Statistic } from './types'

const DIMENSIONS_VALUE_QUERY = graphql(/* GraphQL */ `
  query DimensionsValueQuery($dimensionValuesParams: DimensionValuesInput!) {
    viewer {
      id
      merchant {
        id
        dimensionValues(params: $dimensionValuesParams)
      }
    }
  }
`)

const emptyArray: Statistic[] = []

export const useDimensionValues = (
  options?: QueryHookOptions<
    DimensionsValueQueryQuery,
    DimensionsValueQueryQueryVariables
  >,
): {
  query: typeof query
  dimensionValues: Statistic[]
} => {
  const query = useQueryWithRetry(DIMENSIONS_VALUE_QUERY, {
    ...options,
  })

  return {
    query,
    dimensionValues:
      (query.data?.viewer?.merchant?.dimensionValues as Statistic[]) ??
      emptyArray,
  }
}
