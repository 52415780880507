import { BaseView } from '../shared/BaseView'
import { TrackerTable } from './TrackerTable/TrackerTable'

export const TrackerView = () => {
  return (
    <BaseView
      title="Tracker"
      description="Use the instructions below to set up your trackers and capture your data to Dema."
    >
      <TrackerTable />
    </BaseView>
  )
}
