import { Box, SimpleGrid } from '@chakra-ui/react'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { calcTotalDifference } from 'utils/calcTotalDifference'
import { formatMetric } from 'utils/numberFormats'
import { InfoAlert } from './components/InfoAlert/InfoAlert'
import {
  type MetricCard,
  MetricCards,
} from './components/MetricCards/MetricCards'
import { MissedMarkets } from './components/MissedMarkets/MissedMarkets'
import { ProfitBreakdown } from './components/ProfitBreakdown/ProfitBreakdown'
import { type HistoricalOverviewMetricCard } from './hooks/types'
import { useHistoricalOverviewAnalysis } from './hooks/useHistoricalOverviewAnalysis'

export const HistoricalOverviewView: React.FC = () => {
  const { currency } = useMerchantInfo()
  const { query, historicalOverviewAnalysis } = useHistoricalOverviewAnalysis()
  const isLoading = query.loading
  const hasError = !!query.error

  const metricCards = formatMetricsForDisplay(
    historicalOverviewAnalysis?.metricCards,
    currency,
  )

  return (
    <Box p={6} pt={0}>
      <InfoAlert mb={6} />

      <Box mb={6}>
        <MetricCards
          metrics={metricCards}
          isLoading={isLoading}
          hasError={hasError}
        />
      </Box>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
        <ProfitBreakdown
          data={historicalOverviewAnalysis?.missedPotentialAggregated}
          isLoading={isLoading}
          hasError={hasError}
        />
        <MissedMarkets
          markets={historicalOverviewAnalysis?.missedPotentialMarkets}
          isLoading={isLoading}
          hasError={hasError}
        />
      </SimpleGrid>
    </Box>
  )
}

const metricTypesMetadata = [
  {
    id: 'spend',
    label: 'spend',
    optimalLabelPrefix: 'Proposed',
  },
  {
    id: 'gross_sale',
    label: 'sales',
    optimalLabelPrefix: 'Predicted',
  },
  {
    id: 'ngp3',
    label: 'profit',
    optimalLabelPrefix: 'Predicted',
  },
]
const formatMetricsForDisplay = (
  metricCards: HistoricalOverviewMetricCard[] | undefined,
  currency: string | undefined,
): MetricCard[] | undefined => {
  if (!metricCards || !currency) return undefined

  const FORMAT = METRIC_FORMAT.WIDGET_NUMBER

  return metricTypesMetadata.flatMap(({ id, label, optimalLabelPrefix }) => {
    const metric = metricCards.find((m) => m.id === id)

    if (!metric) return []

    const proposed = calcTotalDifference({
      value: metric.optimalValue,
      compareValue: metric.actualValue,
      format: FORMAT,
      currency,
      isOppositeDiffColor: false,
    })

    return [
      {
        label: `Actual ${label} (${currency})`,
        number: formatMetric(FORMAT, metric.actualValue, currency),
        iconName: null,
        status: null,
      },
      {
        label: `${optimalLabelPrefix} ${label} (${currency})`,
        number: proposed.formattedValue,
        iconName: proposed.iconName,
        status: proposed.status,
        helpText: proposed.formattedPercentageDifference,
      },
    ]
  })
}
