import {
  filtersParamsAtom,
  filtersStateAtom,
} from 'features/reports/atoms/filterAtom'
import { URL_PARAM_KEYS } from 'features/reports/consts'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { JsonParam, useQueryParam } from 'use-query-params'
import { type FiltersFromParams } from '../types'

export const filtersParamConfigMap = { [URL_PARAM_KEYS.FILTERS]: JsonParam }

export const useFiltersState = () => useAtomValue(filtersStateAtom)
export const useFiltersStateQueryParam = () =>
  useQueryParam<FiltersFromParams>(
    URL_PARAM_KEYS.FILTERS,
    filtersParamConfigMap[URL_PARAM_KEYS.FILTERS],
  )
export const useFiltersStateSync = () => {
  const [queryFilters] = useFiltersStateQueryParam()
  const [filters, setFilters] = useAtom(filtersParamsAtom)

  useEffect(() => {
    if (queryFilters !== filters) {
      setFilters(queryFilters)
    }
  }, [filters, queryFilters, setFilters])
}
