import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { ReportHeaderWrapper } from 'features/reports/components/ReportHeader/ReportHeaderWrapper'
import { ReportNameDescription } from 'features/reports/components/ReportHeader/ReportNameDescription'
import { ReportPanelControl } from 'features/reports/components/ReportHeader/ReportPanelControl'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { newReportViewStateAtom } from './atom'
import { CreateNewReportButton } from './CreateNewReportButton'

const reportNameSchema = z.object({
  reportName: z.string().min(2),
  reportDescription: z.string().optional(),
})

export const NewReportHeader = () => {
  const [newReportViewState, setNewReportViewState] = useAtom(
    newReportViewStateAtom,
  )

  const isValidReportNaming = reportNameSchema.safeParse({
    reportName: newReportViewState.name,
    reportDescription: newReportViewState.description,
  }).success

  const navigate = useNavigate()

  return (
    <ReportHeaderWrapper>
      <ReportNameDescription
        reportState={newReportViewState}
        setReportState={setNewReportViewState}
      />
      <Flex gap={4} alignItems="start">
        <Flex alignItems="start" gap={2}>
          <Button
            size="sm"
            colorScheme="gray"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <CreateNewReportButton isValidReportNaming={isValidReportNaming} />
        </Flex>

        <ReportPanelControl />
      </Flex>
    </ReportHeaderWrapper>
  )
}
