import { Box, Divider, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { AddLabelModal } from 'features/settings/views/OrganisationViews/OrganisationLabelsView/AddLabelModal'
import { useEditReport } from 'graphql/reports/useEditReport'
import { type ReportLabelWithoutCount } from 'graphql/reports/useReportLabels'
import { getIsDemaReport, type ReportResult } from 'graphql/reports/utils'
import { useIsGuestUser } from 'hooks/useIsGuestUser'
import { isEqual } from 'lodash-es'
import { type ReactElement, useMemo, useState, type FC } from 'react'
import { LabelIcon } from './LabelIcon'
import { LabelsMenuButton } from './LabelsMenuButton'

export type ReportLabelsPickerProps = {
  customMenuButton?: ReactElement
  reportLabels: ReportLabelWithoutCount[]
  isLoading?: boolean
  isDisabled?: boolean
  isOpenByDefault?: boolean
  matchWidth?: boolean
  currentLabel?: ReportLabelWithoutCount
  selectedLabelIds: string[]
  selectedReport?: ReportResult
  onLabelsChanged: (labels: ReportLabelWithoutCount[]) => void
  onClose?: () => void
}

export const ReportLabelsPicker: FC<ReportLabelsPickerProps> = ({
  customMenuButton,
  reportLabels,
  isLoading = false,
  isDisabled = false,
  isOpenByDefault = false,
  matchWidth = false,
  currentLabel,
  selectedLabelIds,
  selectedReport,
  onLabelsChanged,
  onClose,
}) => {
  const [editReport] = useEditReport()
  const isGuestUser = useIsGuestUser()
  // We need to keep track of the changes made to the label dropdown so that
  // when the user adds a new label, the other changes are also reflected
  const [draftLabels, setDraftLabels] = useState<string[]>(
    reportLabels.map((label) => label.id),
  )

  const menuDisclosure = useDisclosure({
    defaultIsOpen: isOpenByDefault,
    onClose,
  })
  const modalDisclosure = useDisclosure()

  const setLabels = async (newSelectedLabelIds: string[]) => {
    if (modalDisclosure.isOpen) {
      setDraftLabels(newSelectedLabelIds)

      return
    }

    const hasChanged =
      selectedLabelIds.length !== newSelectedLabelIds.length ||
      !isEqual(selectedLabelIds, newSelectedLabelIds)

    if (hasChanged) {
      const newReportLabels = reportLabels.filter((label) =>
        newSelectedLabelIds.includes(label.id),
      )

      onLabelsChanged(newReportLabels)
    }
  }

  const options = useMemo(
    () =>
      reportLabels.map((label) => ({
        ...label,
        leftItem: <LabelIcon color={label.color} />,
      })),
    [reportLabels],
  )

  return (
    <ComboBox
      size="sm"
      options={options}
      selected={selectedLabelIds}
      selectionMode={SELECTION_MODE.MULTIPLE}
      setSelected={setLabels}
      isLoading={isLoading}
      searchPlaceholder="Labels"
      matchWidth={matchWidth}
      isOpen={menuDisclosure.isOpen}
      onOpen={menuDisclosure.onOpen}
      listProps={{ zIndex: 'dropdown' }} // Ensure the combobox popover is below the modal
      onClose={() => {
        if (modalDisclosure.isOpen) return
        menuDisclosure.onClose()
      }}
      hasSelectDeselect={false}
      customMenuButton={
        customMenuButton || (
          <LabelsMenuButton
            label={currentLabel}
            isDisabled={isDisabled || getIsDemaReport(selectedReport?.owner)}
          />
        )
      }
      customFooter={
        isGuestUser ? undefined : (
          <>
            <Divider />
            <Box px={2} pt={2} pb={3}>
              <AddLabelModal
                disclosure={modalDisclosure}
                onLabelAddedComplete={async (newCreatedLabel) => {
                  if (selectedReport) {
                    menuDisclosure.onClose()
                    modalDisclosure.onClose()

                    const newReportLabels = reportLabels.filter((label) =>
                      draftLabels.includes(label.id),
                    )

                    await editReport({
                      id: selectedReport.id,
                      report: {
                        ...selectedReport,
                        labels: [...newReportLabels, newCreatedLabel],
                      },
                    })
                  }
                }}
                modalButton={
                  <Button
                    justifyContent="left"
                    variant="ghost"
                    size="xs"
                    colorScheme="grey"
                    w="full"
                    leadingIcon={{ name: 'PlusSmallIcon' }}
                  >
                    Add new label
                  </Button>
                }
              />
            </Box>
          </>
        )
      }
    />
  )
}
