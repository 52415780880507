import { type Filter } from 'constants/types'
import { useFiltersStateQueryParam } from 'features/reports/hooks/filters/useFiltersState'
import { useAnalyticsTableState } from 'features/reports/hooks/useAnalyticsTableState'
import { useChartState } from 'features/reports/hooks/useChartState'
import { type ReportsState } from 'features/reports/hooks/useCurrentReportsState'
import { useDateStateQueryParam } from 'features/reports/hooks/useDateState'
import { useDimensionsQueryParam } from 'features/reports/hooks/useDimensionsState'
import { useMetricsQueryParam } from 'features/reports/hooks/useMetricsState'
import {
  useReportQueryParam,
  useReportStateAtomSetter,
} from 'features/reports/hooks/useReportState'
import { type ReportWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { getIsDemaReport, type ReportResult } from 'graphql/reports/utils'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useCallback } from 'react'
import { formatChartState, formatDateState } from './useFormatReport'

const useSetReportState = () => {
  const [, setReportId] = useReportQueryParam()
  const [, setMetrics] = useMetricsQueryParam()
  const [, setDimensions] = useDimensionsQueryParam()
  const [, setFilters] = useFiltersStateQueryParam()
  const { setValidTableState } = useAnalyticsTableState()
  const [, setChartState] = useChartState()
  const [, setDateState] = useDateStateQueryParam()
  const { timezone } = useMerchantInfo()

  return useCallback(
    (report: ReportsState) => {
      setReportId(report.id)
      setMetrics(report.metrics)
      setDimensions(report.dimensions)
      setFilters(report.filters as Record<string, Filter[]>)
      setValidTableState({
        tableState: report.tableState,
        dimensions: report.dimensions,
        metrics: report.metrics,
      })
      setChartState(formatChartState(report))
      setDateState(formatDateState(report, timezone))
    },
    [
      setChartState,
      setDateState,
      setDimensions,
      setFilters,
      setMetrics,
      setReportId,
      setValidTableState,
      timezone,
    ],
  )
}

export const useSelectReport = () => {
  const setReportState = useSetReportState()
  const [currentReportId] = useReportQueryParam()
  const reportAtomSetter = useReportStateAtomSetter()
  const [trackEvent] = useTrackEvent()

  return useCallback(
    (report: ReportResult) => {
      if (!report || report.id === currentReportId) {
        return
      }

      const isDemaReport = getIsDemaReport(report.owner)

      trackEvent({
        eventName: 'Report Picked',
        eventProperties: {
          isDemaReport: !!isDemaReport,
          reportId: report.id,
          reportName: report.name,
        },
      })
      reportAtomSetter(report as unknown as ReportWithOwnerFieldsFragment)
      setReportState(report)
    },
    [currentReportId, setReportState, trackEvent, reportAtomSetter],
  )
}
