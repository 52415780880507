import { gql, useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons/ButtonIcon'
import { type ReportLabel } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type FC } from 'react'
import { ReportLabelPicker, type UpdateReportLabel } from './ReportLabelPicker'

const EDIT_REPORT_LABEL_MUTATION = gql`
  mutation EditReportLabel($id: ID!, $params: JSONObject!) {
    editReportLabel(id: $id, params: $params) {
      id
      name
      color
      __typename
    }
  }
`

type Props = {
  currentLabel: ReportLabel | undefined
}

export const EditLabelModal: FC<Props> = ({ currentLabel }) => {
  const [editReportLabel] = useMutation(EDIT_REPORT_LABEL_MUTATION)
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const onEditLabel = (label: UpdateReportLabel) => {
    if (!label.id) return

    editReportLabel({
      variables: {
        id: label.id,
        params: {
          name: label.name,
          color: label.color,
        },
      },
      optimisticResponse: {
        editReportLabel: {
          id: label.id,
          name: label.name,
          color: label.color,
          __typename: 'ReportLabel',
        },
      },
      onCompleted: (data) => {
        trackEvent({
          eventName: 'Report Label Edited',
          eventProperties: {
            labelName: data.name,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not edit report label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <ReportLabelPicker
      currentLabel={currentLabel}
      modalButton={
        <ButtonIcon size="sm" variant="ghost" title="Edit" name="EditIcon" />
      }
      isEditMode
      onApply={onEditLabel}
    />
  )
}
