import { Box, Flex } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import type { PropsWithChildren, ReactElement, ReactNode } from 'react'

type Props = {
  children: ReactNode
  title: string
  description?: string | ReactElement
  buttonsContent?: ReactElement
}

export const BaseView = ({
  children,
  title,
  description,
  buttonsContent,
}: PropsWithChildren<Props>) => {
  return (
    <Box as="main" w="full" h="full" p={6}>
      <Box mb={6} justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            fontSize="2xl"
            lineHeight={8}
            color="grey.900"
            noOfLines={1}
          >
            {title}
          </Typography>
          {description && (
            <Typography fontSize="sm" lineHeight={5} color="gray.800" mt={2}>
              {description}
            </Typography>
          )}
        </Box>
        {buttonsContent && (
          <Flex gap={2} mt={6} justifyContent="flex-end">
            {buttonsContent}
          </Flex>
        )}
      </Box>
      {children}
    </Box>
  )
}
