import { Flex } from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { Input } from 'components/Input'
import { useState } from 'react'
import type { useCreateConversation } from '../graphql/useCreateConversation'
import type { useCreateConversationMessage } from '../graphql/useCreateConversationMessage'
import { sendOptimisticMessage } from '../utils/sendOptimisticMessage'

interface MessageInputProps {
  conversationId: string | null
  setConversationId: React.Dispatch<React.SetStateAction<string | null>>
  isLoading: boolean
  createConversation: ReturnType<typeof useCreateConversation>[0]
  createConversationMessage: ReturnType<typeof useCreateConversationMessage>[0]
}

export const MessageInput: React.FC<MessageInputProps> = ({
  conversationId,
  setConversationId,
  isLoading,
  createConversation,
  createConversationMessage,
}) => {
  const [message, setMessage] = useState('')

  const isValidMessage = message.trim() !== ''

  const handleSendMessage = async () => {
    if (!isValidMessage) return

    sendOptimisticMessage({
      conversationId,
      message,
      createConversation,
      createConversationMessage,
      onSetConversationId: setConversationId,
      onSetMessage: setMessage,
    })
  }

  return (
    <Flex gap={2} w="full">
      <Input
        wrapperProps={{ flex: 1 }}
        placeholder="Write a question to Copilot"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSendMessage()
          }
        }}
        action={
          <ButtonIcon
            name={isLoading ? 'StopIcon' : 'ArrowUpIcon'}
            title="Stop"
            size="sm"
            variant={isValidMessage ? 'solid' : 'ghost'}
            isDisabled={!isValidMessage}
            onClick={handleSendMessage}
          />
        }
        actionWidth={32}
      />
      <Button
        flexShrink={0}
        variant="outline"
        colorScheme="gray"
        onClick={() => {
          setConversationId(null)
        }}
      >
        Clear chat
      </Button>
    </Flex>
  )
}
