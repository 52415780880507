import { Skeleton } from '@chakra-ui/react'
import { type CellRendererSelectorFunc } from 'ag-grid-community'
import { Typography } from 'components/Typography'
import {
  type NormalizedStatistic,
  type Statistic,
} from 'graphql/statistics/types'
import { calcPercentageDiff } from 'utils/calcPercentageDiff'
import { formatMetric } from 'utils/numberFormats'
import { getCompareCellColor } from 'utils/tableUtils'
import { type MetricCellRendererParams } from '../types'
import { CellWrapper } from './CellWrapper'

const MetricCellRenderer = ({
  value,
  metric,
  isCompare,
  data,
}: MetricCellRendererParams) => {
  // Display currency as integer
  const currentDisplayValue =
    metric.format === 'currency'
      ? formatMetric('integer', Number(value?.value))
      : (value?.formattedValue ?? '')

  const baseValue = Number(value?.value)
  const compareValue = Number(data?.[metric.key]?.comparisonValue)
  const difference =
    compareValue !== 0 && isFinite(compareValue)
      ? calcPercentageDiff(baseValue, compareValue) / 100
      : undefined

  const formattedDifference = difference
    ? formatMetric('percent', difference)
    : 0
  const displayDifference =
    difference && isFinite(difference)
      ? `${difference > 0 ? '+' : ''}${formattedDifference}`
      : 'N/A'
  const isReversePositive = !!metric.isReversePositive

  return (
    <CellWrapper>
      <Typography
        textAlign="end"
        lineHeight={4}
        noOfLines={1}
        color="grey.900"
        fontSize="xs"
        whiteSpace="nowrap"
        display="block"
      >
        {currentDisplayValue === '' ? '-' : currentDisplayValue}
      </Typography>
      {isCompare && (
        <Typography
          noOfLines={1}
          lineHeight="18px"
          fontSize="2xs"
          fontWeight={300}
          color={getCompareCellColor(difference, isReversePositive)}
          textAlign="end"
        >
          {displayDifference}
        </Typography>
      )}
    </CellWrapper>
  )
}

const MetricSkeletonLoader = () => {
  return (
    <CellWrapper>
      <Skeleton w="80px" h="14px" alignSelf="flex-end" />
      <Skeleton w="50px" h="12px" mt="4px" alignSelf="flex-end" />
    </CellWrapper>
  )
}

export const metricsCellRendererSelector: CellRendererSelectorFunc<
  NormalizedStatistic,
  Statistic
> = (params) => {
  if (params.data?.['loading']) {
    return {
      component: MetricSkeletonLoader,
    }
  }

  return {
    component: MetricCellRenderer,
  }
}
