import { type Row } from '@tanstack/react-table'
import { Input } from 'components/Input'
import { useState, type ChangeEventHandler, type ComponentProps } from 'react'
import { z } from 'zod'
import { MANUAL_INPUT_COLUMN, MANUAL_INPUT_FORMAT } from '../consts'
import {
  type ColumnValue,
  type ColumnId,
  type InputError,
  type ManualInputColumns,
  type ManualInputFormat,
} from '../types'

interface Props extends ComponentProps<typeof Input> {
  initialValue?: string
  maxValue?: string
  format?: ManualInputFormat
  currency?: string
  columnId: ColumnId
  error?: InputError
  isReadOnly?: boolean
  updateData: (values: Partial<Record<ColumnId, ColumnValue>>) => void
  getErrors?: (
    firstRow: Row<ManualInputColumns>,
    skipSelf?: boolean,
  ) => string[]
}

const noInvalidNumericValue = z
  .string()
  .refine((data) => !/^0\d+$/.test(data), {
    message: 'Value cannot have leading zeros',
  })
  .refine((data) => !/[eE]/.test(data), {
    message: "Value cannot contain 'e'",
  })
  .transform((value) => parseFloat(value))

const numberCurrencySchema = noInvalidNumericValue.pipe(
  z
    .number({ invalid_type_error: 'Value has to be numeric' })
    .min(0, 'Value cannot be less than 0'),
)

const percentSchema = noInvalidNumericValue.pipe(
  z
    .number({ invalid_type_error: 'Value has to be numeric' })
    .min(0, 'Value cannot be less than 0')
    .max(100, 'Value cannot be greater than 100'),
)

export const NumericInputCell = ({
  initialValue,
  maxValue,
  placeholder,
  size = 'sm',
  currency,
  format,
  columnId,
  error,
  isReadOnly,
  updateData,
  ...rest
}: Props) => {
  const [value, setValue] = useState(initialValue)

  const helperText =
    format === MANUAL_INPUT_FORMAT.NUMBER_CURRENCY
      ? currency
      : format === MANUAL_INPUT_FORMAT.PERCENTAGE
        ? '%'
        : undefined

  const validateValue = (input?: string) => {
    const schema =
      format === MANUAL_INPUT_FORMAT.PERCENTAGE
        ? percentSchema
        : numberCurrencySchema

    try {
      schema.parse(input)
    } catch (error) {
      if (error instanceof z.ZodError) {
        return error.errors[0].message
      }
    }
  }

  const onBlur = () => {
    const error = validateValue(value) ?? ''

    const val = value ? { [columnId]: value } : undefined
    const err = {
      [MANUAL_INPUT_COLUMN.ERROR]: {
        [columnId]: error ?? '',
      } as InputError,
    }

    updateData({
      ...val,
      ...err,
    })
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value)
  }

  const preventNonNumeric = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  return (
    <Input
      size={size}
      error={
        error?.[columnId as ColumnId]
          ? { type: '', message: error[columnId as ColumnId] }
          : undefined
      }
      max={maxValue}
      min={0}
      onBlur={onBlur}
      onChange={onChange}
      type="number"
      value={value}
      placeholder={placeholder}
      helperText={helperText}
      minW="120px"
      w="full"
      wrapperProps={{ maxW: '320px' }}
      isReadOnly={isReadOnly}
      onKeyDown={preventNonNumeric}
      {...rest}
    />
  )
}
