import { type DropdownOption } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Icon } from 'components/Icon/Icon'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useMemo, useState, type FC } from 'react'
import { type TaxonomyCell } from './TaxonomyCell'
import { TaxonomyDimensionButton } from './TaxonomyDimensionButton'
import { TaxonomyDropdown } from './TaxonomyDropdown'
import { TaxonomyMenuButton } from './TaxonomyMenuButton'

export const validDimensionIds = [
  'country',
  'warehouse',
  'category',
  'subCategory',
  'brand',
  'color',
  'material',
  'gender',
  'collection',
  'size',
  'shippingProvider',
  'paymentProvider',
  'customAttribute1',
  'customAttribute2',
  'customAttribute3',
  'customAttribute4',
  'customAttribute5',
]
const excludeCustomDimensionText = 'Custom attribute'

type Props = {
  depth: number
  initialValue?: TaxonomyCell
  rowHasValue: boolean
  prevDimensionIds: string[]
  prevDimensionValues: string[]
  isReadOnly?: boolean
  setSelected: (taxonomy: Partial<TaxonomyCell>) => void
  setError: (errorMessage?: string) => void
}

const OPEN_STATE = {
  CLOSED: 'closed',
  DIMENSION: 'dimension',
  TAXONOMY: 'taxonomy',
} as const

export const TaxonomyDimensionSelector: FC<Props> = ({
  depth,
  initialValue,
  rowHasValue,
  prevDimensionIds,
  prevDimensionValues,
  isReadOnly,
  setSelected,
  setError,
}) => {
  const normalizedDimensions = useNormalizedDimensions()
  const [openState, setOpenState] = useState<
    (typeof OPEN_STATE)[keyof typeof OPEN_STATE]
  >(OPEN_STATE.CLOSED)

  const items: DropdownOption[] = useMemo(() => {
    return validDimensionIds
      .filter(
        (dim) =>
          !normalizedDimensions[dim]?.label.includes(
            excludeCustomDimensionText, // We don't want to show custom attributes that have not been renamed
          ),
      )
      .map((dimensionId) => {
        const { id, label } = normalizedDimensions[dimensionId] || {}

        return {
          id,
          name: label,
          rightItem: (
            <Icon name="ChevronRightIcon" color="grey.800" size="small" />
          ),
          disabled: prevDimensionIds.includes(id), // Disable dimensionIds on prev levels
        }
      })
  }, [normalizedDimensions, prevDimensionIds])

  const group = useMemo(
    () => items.find((item) => item.id === initialValue?.groupName),
    [items, initialValue?.groupName],
  )

  return openState === OPEN_STATE.CLOSED ? (
    // This button could be removed and the code could be simplified but we keep it for performance reasons. Rendering many ComboBoxes even when they are closed has a performance impact
    <TaxonomyDimensionButton
      group={group}
      name={initialValue?.name}
      isOpen={false}
      depth={depth}
      rowHasValue={rowHasValue}
      isReadOnly={isReadOnly}
      setError={setError}
      onClick={() => {
        if (!isReadOnly) {
          if (group) {
            setOpenState(OPEN_STATE.TAXONOMY)
          } else {
            setOpenState(OPEN_STATE.DIMENSION)
          }
        }
      }}
    />
  ) : !group ? (
    <ComboBox
      selectionMode={SELECTION_MODE.SINGLE}
      searchPlaceholder="Dimension"
      customMenuButton={
        <TaxonomyMenuButton
          depth={depth}
          name={initialValue?.name}
          isOpen={openState === OPEN_STATE.DIMENSION}
          rowHasValue={rowHasValue}
          setError={setError}
        />
      }
      isReadOnly={isReadOnly}
      isOpen={openState === OPEN_STATE.DIMENSION}
      onOpen={() => setOpenState(OPEN_STATE.DIMENSION)}
      onClose={() => {
        setOpenState(OPEN_STATE.TAXONOMY)
      }}
      options={items}
      setSelected={(item) => setSelected({ groupName: item })}
      containerProps={{ w: 'full' }}
    />
  ) : (
    <TaxonomyDropdown
      name={initialValue?.name}
      dimension={group}
      prevDimensionValues={prevDimensionValues}
      isOpen={openState === OPEN_STATE.TAXONOMY}
      onOpen={() => setOpenState(OPEN_STATE.TAXONOMY)}
      onClose={() => setOpenState(OPEN_STATE.CLOSED)}
      isReadOnly={isReadOnly}
      setSelectedItem={(item) =>
        setSelected({
          name: item,
        })
      }
      depth={depth}
      rowHasValue={rowHasValue}
      setError={setError}
    />
  )
}
