import { listAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const variants = {
  menu: definePartsStyle(() => ({
    item: {
      px: 3,
      py: 2,
      w: 'full',
      _selected: {
        bg: 'grey.200',
        '&:hover': { bg: 'grey.300' },
      },
      _disabled: {
        bg: 'grey.100',
        pointerEvents: 'none',
        cursor: 'default',
      },
    },
  })),
}

export const listTheme = defineMultiStyleConfig({ variants })
