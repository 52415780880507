import { COMPARE_DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { Box, Divider, Flex } from '@chakra-ui/react'
import { ToggleWithIcons } from 'components/ToggleWithIcons/ToggleWithIcons'
import { Typography } from 'components/Typography'
import { differenceInDays } from 'date-fns'
import { SaturationCurves } from 'features/optimizations/components/SaturationCurves/SaturationCurves'
import { RecommendationCell } from 'features/optimizations/components/ScenarioTable/RecommendationCell'
import { ScenarioTable } from 'features/optimizations/components/ScenarioTable/ScenarioTable'
import {
  compareValuesTypes,
  CompareValueType,
  getOptimalOptimizationMetric,
  scenarioColumns,
  type MetricOptimizationTableColumn,
  type OptimizationTableColumn,
} from 'features/optimizations/consts'
import {
  useFutureAnalysisQuery,
  type FutureAnalysis,
} from 'features/optimizations/graphql/useFutureAnalysisQuery'
import {
  useHistoricalAnalysisQuery,
  type ContributionData,
  type HistoricalAnalysis,
  type SummaryData,
} from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { useHistoricalAnalysisFiltersAtom } from 'features/optimizations/views/Historical/components/Settings/Filter'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePredictionAtom } from '../../atoms/atom'
import { EmptyScenario } from './EmptyScenario'
import { LoadingScenario } from './LoadingScenario'
import { OptimizedBudget } from './widgets/OptimizedBudget'

const predictionColumns: OptimizationTableColumn[] = [
  ...scenarioColumns,
  {
    isMetric: false,
    key: 'recommendation',
    label: 'Recommendation',
    CellComponent: RecommendationCell,
  },
]

export const PredictionContent = () => {
  const [compareValueType, setCompareValueType] = useState(
    CompareValueType.Percentage,
  )
  const [state] = usePredictionAtom()
  const [filters] = useHistoricalAnalysisFiltersAtom()
  const selectedSegment = filters.segment.selected
  const { market } = useParams()
  const segments = selectedSegment ? [selectedSegment] : []
  const markets = market ? [market] : []
  const {
    formattedDateRange: [startDate, endDate],
    formattedHistoricalDateRange: [historicalStart, historicalEnd],
    target,
    targetValue,
    channelBounds,
    datePreset,
    historicalDatePreset,
  } = state

  const { data, loading } = useFutureAnalysisQuery({
    target,
    targetValue,
    channelBounds,
    markets,
    segments,
    startDate,
    endDate,
  })

  const { data: historicalData } = useHistoricalAnalysisQuery({
    startDate: historicalStart,
    endDate: historicalEnd,
    markets,
    segments,
  })
  const { data: configData } = useOptimizationConfig()
  const { optimizationConfig } = configData?.viewer?.merchant ?? {}
  const dayDiff =
    differenceInDays(datePreset.value[1] as Date, datePreset.value[0] as Date) +
    1

  if (loading) {
    return <LoadingScenario />
  }
  if (!data || !data.viewer?.merchant.futureAnalysis) {
    return <EmptyScenario />
  }

  const { futureAnalysis } = data.viewer.merchant
  const { historicalAnalysis } = historicalData?.viewer?.merchant ?? {}

  const mergedScenarioData = mergeScenarioData(
    historicalAnalysis,
    futureAnalysis,
    predictionColumns.filter(
      ({ isMetric }) => isMetric,
    ) as MetricOptimizationTableColumn[],
  )

  return (
    <Box h="full" overflowY="auto" w="full">
      <OptimizedBudget
        contributionData={mergedScenarioData.contribution}
        compareDateLabel={historicalDatePreset.name}
      />
      <Divider my={2} />
      <Box p={5} mb={5}>
        <Flex mb={6} justifyContent="space-between" alignItems="center">
          <Typography fontSize="lg" color="gray.900" lineHeight={6}>
            Channel analysis
          </Typography>
          <Flex gap={2} alignItems="center">
            <Typography size="xs" fontWeight={600} color="gray.600">
              Comparison:
            </Typography>
            <ToggleWithIcons
              selected={compareValueType}
              setSelected={setCompareValueType}
              items={compareValuesTypes}
            />
          </Flex>
        </Flex>
        <ScenarioTable
          columns={predictionColumns}
          data={mergedScenarioData}
          optimizationConfig={optimizationConfig}
          compareDateLabel={
            historicalDatePreset.id ===
            COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS
              ? 'Preceding year'
              : historicalDatePreset.name
          }
          compareValueType={compareValueType}
        />
      </Box>
      <SaturationCurves
        isLoading={false}
        hasError={false}
        dayDiff={dayDiff}
        curveData={futureAnalysis.curve}
        toggleButtonsWrapperProps={{ ml: 6 }}
      />
    </Box>
  )
}

const mergeScenarioData = (
  historicalAnalysis: HistoricalAnalysis | undefined,
  futureAnalysis: FutureAnalysis | undefined,
  columns: MetricOptimizationTableColumn[],
) => {
  const result = {
    contribution: [] as ContributionData,
    summary: [] as SummaryData,
  }

  if (!futureAnalysis) {
    return result
  }

  // Merge contributions
  for (const futureContribution of futureAnalysis.contribution) {
    const historicalContribution = historicalAnalysis?.contribution.find(
      (row) => row.channel === futureContribution.channel,
    )

    const mergedContribution = columns.reduce<Record<string, unknown>>(
      (acc, { key }) => {
        const optimalKey = getOptimalOptimizationMetric(key)

        acc[key] = historicalContribution?.[key] ?? NaN // We disregard :optimal values returned in the historical enpoint
        acc[optimalKey] = futureContribution?.[optimalKey] ?? NaN

        return acc
      },
      { channel: futureContribution.channel },
    )

    const dataIsEmpty = Object.entries(mergedContribution)
      .filter(([key]) => key !== 'channel')
      .every(([, value]) => !value)

    if (!dataIsEmpty) {
      result.contribution.push(mergedContribution as ContributionData[number])
    }
  }

  // Merge summaries
  for (const futureSummaryRow of futureAnalysis.summary) {
    const historicalSummary = historicalAnalysis?.summary.find(
      (row) => row.id === futureSummaryRow.id,
    )

    const mergedSummary = columns.reduce<Record<string, unknown>>(
      (acc, { key }) => {
        acc[key] = historicalSummary?.[key] ?? NaN
        acc[getOptimalOptimizationMetric(key)] = futureSummaryRow?.[key] ?? NaN // Summary does not return :optimal values. That is why we use key here

        return acc
      },
      { id: futureSummaryRow.id },
    )

    const dataIsEmpty = Object.entries(mergedSummary)
      .filter(([key]) => key !== 'id')
      .every(([, value]) => !value)

    // Skip rows with no data
    if (!dataIsEmpty) {
      result.summary.push(mergedSummary as SummaryData[number])
    }
  }

  return result
}
