import { useUserReports } from 'graphql/reports/useUserReports'
import { useSetAtom } from 'jotai'
import { REPORT_PAGE_TITLES } from '../routePages'
import { newReportViewStateAtom } from './NewReportView/atom'
import { ReportListTable } from './shared/ReportListTable'

export const AllReportsView = () => {
  const { userReports, query } = useUserReports()
  const setNewReportViewState = useSetAtom(newReportViewStateAtom)

  return (
    <ReportListTable
      pageTitle={REPORT_PAGE_TITLES.ALL}
      reports={userReports}
      isLoading={query.loading}
      addReportCallback={() => {
        setNewReportViewState((prev) => {
          return {
            ...prev,
            visibility: [],
          }
        })
      }}
    />
  )
}
