import { toZonedTime } from 'date-fns-tz'
import { URL_PARAM_KEYS } from 'features/reports/consts'
import { type ReportsState } from 'features/reports/hooks/useCurrentReportsState'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useCallback } from 'react'

export const formatDateState = (report: ReportsState, timezone: string) => {
  return {
    [URL_PARAM_KEYS.DATE_RANGE]: [
      toZonedTime(report.startDate, timezone).getTime(),
      toZonedTime(report.endDate, timezone).getTime(),
    ],
    [URL_PARAM_KEYS.COMPARE_DATE_RANGE]:
      report.compareStartDate && report.compareEndDate
        ? [
            toZonedTime(report.compareStartDate, timezone).getTime(),
            toZonedTime(report.compareEndDate, timezone).getTime(),
          ]
        : undefined,
    [URL_PARAM_KEYS.DYNAMIC_DATE]: report.dynamicDate,
    [URL_PARAM_KEYS.COMPARE_DYNAMIC_DATE]: report.compareDynamicDate,
    [URL_PARAM_KEYS.IS_COMPARE]: !!(
      report.compareStartDate && report.compareEndDate
    ),
  }
}

export const formatChartState = (report: ReportsState) => {
  const { color, xAxis, series } = report?.chart ?? {}

  return {
    [URL_PARAM_KEYS.X_AXIS]: xAxis,
    [URL_PARAM_KEYS.COLOR]: color,
    [URL_PARAM_KEYS.SERIES]: series,
  }
}

export const useFormatReport = () => {
  const { timezone } = useMerchantInfo()

  const formatReport = useCallback(
    (report: ReportsState) => {
      return {
        [URL_PARAM_KEYS.REPORT_ID]: report.id,
        [URL_PARAM_KEYS.METRICS]: report.metrics,
        [URL_PARAM_KEYS.DIMENSIONS]: report.dimensions,
        [URL_PARAM_KEYS.FILTERS]: report.filters,
        [URL_PARAM_KEYS.TABLE_STATE]: report.tableState,
        ...formatChartState(report),
        ...formatDateState(report, timezone),
      }
    },
    [timezone],
  )

  return formatReport
}
