import { Box, Divider } from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { Button } from 'components/buttons'
import { type DropdownOption } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import { useChartState } from 'features/reports/hooks/useChartState'
import { useMetricsState } from 'features/reports/hooks/useMetricsState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { isEqual } from 'lodash-es'
import { useMemo } from 'react'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { ChartOptions } from './ChartOptions'
import { ChartSeriesList } from './ChartSeriesList'

export type UpdateChartOptions = {
  newXAxis?: DropdownOption
  newYAxis?: DropdownOption
  newGColor?: DropdownOption
}

export const ChartSettingsPanel: React.FC = () => {
  const [{ series, xAxis, color: chartGroup }, setChartState] = useChartState()
  const [trackEvent] = useTrackEvent()
  const { uniqueMetrics } = useMetricsState()
  const seriesMetricsSet = useMemo(
    () => new Set(series.map(({ key }) => key)),
    [series],
  )

  const noValues = !series.some(({ key }) => key !== undefined)

  const nonSelectedMetric = useMemo(
    () => uniqueMetrics.find((metric) => !seriesMetricsSet.has(metric.key)),
    [seriesMetricsSet, uniqueMetrics],
  )

  const updateChartOptions = ({ newXAxis, newGColor }: UpdateChartOptions) => {
    const trackProps = {
      xAxis: newXAxis?.id?.toString() || xAxis,
      group: newGColor?.id?.toString() || chartGroup,
    }

    if (
      isEqual(trackProps, {
        xAxis: xAxis,
        group: chartGroup,
      })
    ) {
      return
    }

    if (newXAxis) {
      setChartState({ xAxis: newXAxis.id + '' })
    }
    if (newGColor) {
      setChartState({ gColor: newGColor.id + '' })
    }
    trackEvent({
      eventName: 'Chart Options Changed',
      eventProperties: trackProps,
    })
  }

  const addSerie = () => {
    const newSeries = [...series]
    const nextSerie = {
      key: nonSelectedMetric?.key ?? '',
      type: CHART_TYPE_ID.LINE,
    }

    newSeries.push(nextSerie)

    setChartState({ gSeries: newSeries })

    trackEvent({
      eventName: 'Chart Series Added',
      eventProperties: { ...nextSerie, numberOfSeries: newSeries.length },
    })
  }

  const clearGColor = () => {
    trackEvent({
      eventName: 'Chart Group Removed',
      eventProperties: {
        group: chartGroup,
      },
    })
    // Setting null explicitly to avoid setting a default value
    setChartState({ gColor: null })
  }

  return (
    <Box
      flexShrink={0}
      w="300px"
      borderLeft="solid 1px"
      borderLeftColor="gray.200"
      h="full"
      overflowY="auto"
    >
      <Box p={4} pb={6}>
        <Typography fontSize="md" color="gray.800" lineHeight={6} mb={4}>
          Chart settings
        </Typography>

        {noValues ? (
          <Box>
            <Alert
              status="warning"
              content="You need to select dimensions and metrics before you can apply any chart settings"
              closable={false}
            />
          </Box>
        ) : (
          <>
            <ChartSeriesList
              series={series}
              setChartState={setChartState}
              uniqueMetrics={uniqueMetrics}
            />

            <Button
              size="sm"
              mt={6}
              variant="outline"
              onClick={addSerie}
              isDisabled={!nonSelectedMetric}
            >
              Add metric
            </Button>
          </>
        )}
      </Box>

      <Divider />

      {!noValues && (
        <Box p={4}>
          <ChartOptions
            onChange={updateChartOptions}
            clearGColor={clearGColor}
            uniqueMetrics={uniqueMetrics}
            chartGroup={chartGroup}
            series={series}
            xAxis={xAxis}
          />
        </Box>
      )}
    </Box>
  )
}
