import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { getIsDemaReport, type ReportResult } from 'graphql/reports/utils'
import { type Dispatch, type SetStateAction, type FC } from 'react'
import { type useGenerateReportUrl } from '../hooks/useGenerateReportUrl'
import { type useSelectReport } from '../hooks/useSelectReport'
import { CopyReportURLMenuItem } from './CopyReportURLMenuItem'
import { DeleteReportMenuItem } from './DeleteReportMenuItem'
import { DuplicateReportMenuItem } from './DuplicateReportMenuItem'

interface Props {
  report?: ReportResult
  userId: string | undefined
  isEditable?: boolean
  // we have these as props for performance reasons
  selectReport: ReturnType<typeof useSelectReport>
  generateReportUrl: ReturnType<typeof useGenerateReportUrl>
  setIsEditing?: Dispatch<SetStateAction<boolean>>
}

export const ReportContextualMenu: FC<Props> = ({
  report,
  userId,
  isEditable = false,
  selectReport,
  generateReportUrl,
  setIsEditing,
}) => {
  const canEditReport = useCanEditReport(report)

  if (!report) return null
  const isOwnUser = userId === report.owner.id
  const isDemaReport = getIsDemaReport(report.owner)
  const isDeleteAllowed = !isDemaReport && isOwnUser

  return (
    <Menu computePositionOnMount={false} isLazy size="sm">
      {({ isOpen }) => (
        <>
          <MenuButton
            onClick={
              (e) => e.stopPropagation() // For example when context menu is used in report list, it will stop the report from being selected on click
            }
            as={IconButton}
            size="sm"
            aria-label="Options"
            border="none"
            icon={
              <Icon
                name="HorizontalMenuIcon"
                tooltipLabel="More actions"
                size="small"
              />
            }
            variant="outline"
          />
          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList onClick={(e) => e.stopPropagation()}>
              {isEditable && !isDemaReport && canEditReport && (
                <MenuItem
                  onClick={() => setIsEditing?.((prev) => !prev)}
                  icon={<Icon name="EditIcon" size="small" />}
                >
                  Edit report
                </MenuItem>
              )}
              <DuplicateReportMenuItem
                report={report}
                selectReport={selectReport}
              />

              <MenuDivider />

              <CopyReportURLMenuItem
                report={report}
                generateReportUrl={generateReportUrl}
              />

              {isDeleteAllowed && (
                <>
                  <MenuDivider />
                  <DeleteReportMenuItem report={report} />
                </>
              )}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
