import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import {
  OPTIMIZATION_METRIC,
  chartMetricMap,
} from 'features/optimizations/consts'
import { type OptimizationChannel } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import type { PatternObject, TooltipFormatterContextObject } from 'highcharts'
import { renderToString } from 'react-dom/server'
import { colorTheme, getColor } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'
import { getCompareCellColor } from 'utils/tableUtils'

interface TooltipFormatterProps {
  currency: string | undefined
  channels: OptimizationChannel[]
}

const getMetricFromPoints = (
  points: TooltipFormatterContextObject[],
  color: string,
) => {
  return Number(
    points.find(
      (point) =>
        point.color === color ||
        (point.color as PatternObject)?.pattern?.backgroundColor === color, // for striped colors
    )?.y,
  )
}

const blueDiffMetrics = [
  OPTIMIZATION_METRIC.SPEND,
  OPTIMIZATION_METRIC.SPEND_OPTIMAL,
]
const MetricRow = ({
  value,
  currency,
  metric,
  compareValue,
  format,
}: {
  value: number
  currency: string | undefined
  format: MetricFormat
  metric: (typeof chartMetricMap)[keyof typeof chartMetricMap]
  compareValue?: number
}) => {
  const compareDiff = compareValue && value ? compareValue - value : undefined
  const diffFormat =
    format === METRIC_FORMAT.PERCENT
      ? METRIC_FORMAT.PERCENT
      : METRIC_FORMAT.INTEGER

  const diffColor = blueDiffMetrics.includes(metric.id)
    ? colorTheme.sky[600]
    : getColor(getCompareCellColor(compareDiff))

  return (
    <TooltipMetricRow
      iconColor={metric.color}
      metricName={metric.name}
      value={isFinite(value) ? formatMetric(format, value, currency) : 'N/A'}
      diffColor={diffColor}
      diffValue={
        compareDiff
          ? `${compareDiff > 0 ? '+' : ''}${formatMetric(diffFormat, compareDiff)}`
          : undefined
      }
    />
  )
}

export const getTooltipFormatter = ({
  currency,
  channels,
}: TooltipFormatterProps) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    if (!this.points) return

    const channelName = channels.find(
      (channel) => channel.id === String(this.x),
    )?.name

    const ngp3 = getMetricFromPoints(
      this.points,
      chartMetricMap[OPTIMIZATION_METRIC.NGP3].color,
    )
    const ngp3Optimal = getMetricFromPoints(
      this.points,
      chartMetricMap[OPTIMIZATION_METRIC.NGP3_OPTIMAL].color,
    )
    const spend = getMetricFromPoints(
      this.points,
      chartMetricMap[OPTIMIZATION_METRIC.SPEND].color,
    )
    const spendOptimal = getMetricFromPoints(
      this.points,
      chartMetricMap[OPTIMIZATION_METRIC.SPEND_OPTIMAL].color,
    )

    const element = (
      <>
        <div
          style={{
            marginBottom: '8px',
            color: colorTheme.grey[700],
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
          }}
        >
          {channelName}
        </div>

        <div style={{ marginBottom: '12px' }}>
          <MetricRow
            value={spend}
            metric={chartMetricMap[OPTIMIZATION_METRIC.SPEND]}
            currency={currency}
            format={METRIC_FORMAT.CURRENCY}
            compareValue={spendOptimal}
          />
          <MetricRow
            value={spendOptimal}
            compareValue={spendOptimal}
            metric={chartMetricMap[OPTIMIZATION_METRIC.SPEND_OPTIMAL]}
            currency={currency}
            format={METRIC_FORMAT.CURRENCY}
          />
        </div>

        <div style={{ marginBottom: '12px' }}>
          <MetricRow
            value={ngp3}
            metric={chartMetricMap[OPTIMIZATION_METRIC.NGP3]}
            currency={currency}
            format={METRIC_FORMAT.CURRENCY}
            compareValue={ngp3Optimal}
          />
          <MetricRow
            value={ngp3Optimal}
            metric={chartMetricMap[OPTIMIZATION_METRIC.NGP3_OPTIMAL]}
            currency={currency}
            format={METRIC_FORMAT.CURRENCY}
          />
        </div>
      </>
    )

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
}
