import { Box } from '@chakra-ui/react'
import { useReportStateAtom } from 'features/reports/hooks/useReportState'
import { newReportViewStateAtom } from 'features/reports/views/NewReportView/atom'
import { ReportVisibility } from 'features/reports/views/shared/ReportVisibility/ReportVisibility'
import { useEditReportVisibility } from 'features/reports/views/shared/ReportVisibility/useEditReportVisibility'
import { getReportVisibilityType } from 'graphql/reports/utils'
import { useMerchantTeams } from 'graphql/teams/useMerchantTeams'
import { useAtom } from 'jotai'
import { type FC } from 'react'
import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

interface VisibilitySectionProps {
  isCreateNewReport: boolean
}

export const VisibilitySection: FC<VisibilitySectionProps> = ({
  isCreateNewReport,
}) => {
  const { editReportVisibility } = useEditReportVisibility()
  const selectedReport = useReportStateAtom()
  const { merchantTeams, query: merchantTeamsQuery } = useMerchantTeams()
  const [newReportViewState, setNewReportViewState] = useAtom(
    newReportViewStateAtom,
  )

  if (!selectedReport) return null

  return (
    <Box>
      <ReportDetailsHeader title="Visibility" />
      {isCreateNewReport ? (
        <ReportVisibility
          teamOptions={merchantTeams}
          isLoading={merchantTeamsQuery.loading}
          report={{
            ...selectedReport,
            visibility: newReportViewState.visibility,
          }}
          onVisibilityChanged={(visibility) =>
            setNewReportViewState((c) => ({ ...c, visibility }))
          }
        />
      ) : (
        <ReportVisibility
          teamOptions={merchantTeams}
          isLoading={merchantTeamsQuery.loading}
          key={getReportVisibilityType(selectedReport?.visibility)}
          report={selectedReport}
          onVisibilityChanged={(newVisibility) =>
            editReportVisibility({
              selectedReport,
              newVisibility,
              oldVisibility: selectedReport.visibility.map((v) => v.id) ?? [],
            })
          }
        />
      )}
    </Box>
  )
}
