import { Flex } from '@chakra-ui/react'
import { ClearAllFilterButton } from 'components/Filters/ClearAllFilterButton'
import {
  useFiltersState,
  useFiltersStateQueryParam,
} from 'features/reports/hooks/filters/useFiltersState'
import { AddFilterButton } from './AddFilterButton'
import { DateRangePicker } from './DateRangePicker'
import { SelectedFilterChip } from './SelectedFilterChip/SelectedFilterChip'

export const ReportFiltersContainer = () => {
  const selectedFilterValues = useFiltersState()
  const [, setFilters] = useFiltersStateQueryParam()

  return (
    <Flex
      gap={2}
      justifyContent="space-between"
      flexDir="row-reverse"
      flexWrap="wrap"
      px={6}
      py={1.5}
      overflowX="auto"
    >
      <Flex justifyContent="end">
        <DateRangePicker />
      </Flex>
      <Flex
        gap={2}
        wrap="wrap"
        justifyContent="start"
        flex={1}
        alignItems="center"
        maxW="full"
      >
        {Object.entries(selectedFilterValues).map(([key, [filter]]) => (
          <SelectedFilterChip key={key} filter={filter} filterId={key} />
        ))}

        <AddFilterButton />
        {Object.keys(selectedFilterValues).length > 0 && (
          <ClearAllFilterButton onClick={() => setFilters(null)} />
        )}
      </Flex>
    </Flex>
  )
}
