import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { atom, useAtom, useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'
import { URL_PARAM_KEYS } from '../consts'
import { defaultDimensions, getActualDimensions } from '../utils/utils'
import { reportStateAtom } from './useReportState'

export const dimensionsParamConfigMap = {
  [URL_PARAM_KEYS.DIMENSIONS]: ArrayParam,
}

const dimensionsParamAtom = atom<(string | null)[] | null | undefined>(
  new URLSearchParams(window.location.search).getAll(URL_PARAM_KEYS.DIMENSIONS),
)

export const dimensionsStateAtom = atom((get) => {
  const report = get(reportStateAtom)
  const normalizedDimensions = get(normalizedDimensionsAtom)
  const dimensions = get(dimensionsParamAtom)

  const filteredUrlValues = dimensions?.filter<string>(
    (val): val is string => !!val,
  )

  const newDimensions = filteredUrlValues?.length
    ? filteredUrlValues
    : (report?.dimensions ?? defaultDimensions)

  const actualDimensions = getActualDimensions(
    newDimensions,
    normalizedDimensions,
  )

  return {
    dimensionKeys: actualDimensions.map((d) => d.id),
    dimensions: actualDimensions,
    unsafe_allDimensionsKeys: newDimensions, // could involve invalid dimensions
  }
})
export const useDimensionsQueryParam = () =>
  useQueryParam(
    URL_PARAM_KEYS.DIMENSIONS,
    dimensionsParamConfigMap[URL_PARAM_KEYS.DIMENSIONS],
  )
export const useDimensionsState = () => useAtomValue(dimensionsStateAtom)

export const useDimensionsStateSync = () => {
  const [queryDimensions] = useDimensionsQueryParam()
  const [dimensions, setDimensions] = useAtom(dimensionsParamAtom)

  useEffect(() => {
    if (queryDimensions !== dimensions) {
      setDimensions(queryDimensions)
    }
  }, [dimensions, queryDimensions, setDimensions])
}
