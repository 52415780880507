import { tableAnatomy } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  type TableCellProps,
} from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
  table: {
    bg: 'unset',
    minW: 'full',
  },
  th: {
    textTransform: 'unset',
    letterSpacing: 'unset',
  },
})

const variants = {
  simple: definePartsStyle(() => {
    const sharedCellStyle: TableCellProps = {
      p: 2,
      fontSize: 'sm',
      color: 'gray.900',
      lineHeight: 5,
    }

    return {
      th: {
        ...sharedCellStyle,
        fontWeight: 600,
      },
      td: {
        ...sharedCellStyle,
        fontWeight: 400,
      },
    }
  }),
}

const sizes = {
  xs: definePartsStyle({
    th: {
      px: 2,
      py: 1,
    },
    td: {
      px: 2,
      py: 1,
    },
    caption: {
      px: 2,
      py: 1,
    },
  }),
}

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
})
