import { useDisclosure } from '@chakra-ui/react'
import {
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useMemo } from 'react'
import { OrderTrackingModal } from './OrderTrackingModal/OrderTrackingModal'
import { PageviewModal } from './PageviewModal/PageviewModal'

type Tracker = {
  connect: () => void
  lastReceived?: Date
  status?: string
  tag: string
}

const columnHelper = createColumnHelper<Tracker>()

const columns = [
  columnHelper.accessor('tag', {
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    header: 'Tag',
  }),
  // TODO: include last received and status in the table
  //   columnHelper.accessor('lastReceived', {
  //     cell: ({ getValue }) => (
  //       <Typography>{getValue() ? getValue()?.toDateString() : '-'}</Typography>
  //     ),
  //     header: 'Last Received',
  //   }),
  //   columnHelper.accessor('status', {
  //     cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
  //     header: 'Status',
  //   }),
  columnHelper.accessor('connect', {
    cell: ({ getValue }) => (
      <Button
        leadingIcon={{
          name: 'PlusSmallIcon',
          size: 'small',
        }}
        onClick={getValue()}
        variant="outline"
        colorScheme="grey"
      >
        Install GTM tag
      </Button>
    ),
    header: 'Connect',
  }),
]

export const TrackerTable = () => {
  const orderTrackingDisclosure = useDisclosure()
  const pageViewDisclosure = useDisclosure()

  const mockData: Tracker[] = useMemo(() => {
    return [
      {
        tag: 'Order tag',
        connect: orderTrackingDisclosure.onToggle,
      },
      {
        tag: 'Pageview tag',
        connect: pageViewDisclosure.onToggle,
      },
    ]
  }, [orderTrackingDisclosure.onToggle, pageViewDisclosure.onToggle])

  const table = useReactTable({
    data: mockData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {!header.isPlaceholder &&
                      flexRender(
                        header.column.columnDef.header as string,
                        header.getContext(),
                      )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <PageviewModal
        isOpen={pageViewDisclosure.isOpen}
        onClose={pageViewDisclosure.onClose}
      />
      <OrderTrackingModal
        isOpen={orderTrackingDisclosure.isOpen}
        onClose={orderTrackingDisclosure.onClose}
      />
    </>
  )
}
