import { Flex } from '@chakra-ui/react'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { useReportLabels } from 'graphql/reports/useReportLabels'
import { type ReportResult } from 'graphql/reports/utils'
import { type FC } from 'react'
import {
  ReportLabelsPicker,
  type ReportLabelsPickerProps,
} from './ReportLabelsPicker/ReportLabelsPicker'
import { useEditReportLabels } from './ReportLabelsPicker/useEditReportLabels'

type Props = {
  report: ReportResult
}

const MAX_VISIBLE_LABELS = 3

export const ReportListLabelsCell: FC<Props> = ({ report }) => {
  const { editReportLabels } = useEditReportLabels()
  const { labels } = report
  const { reportLabels, query: reportLabelsQuery } = useReportLabels({
    withReportCount: false,
  })

  const firstLabels = labels.slice(0, MAX_VISIBLE_LABELS)
  const nextLabel = labels[MAX_VISIBLE_LABELS]
    ? {
        ...labels[MAX_VISIBLE_LABELS],
        name: `+ ${labels.length - firstLabels.length} more`,
      }
    : undefined

  const canEditReport = useCanEditReport(report)
  const selectedLabelIds = labels.map((label) => label.id)

  const commonProps = {
    reportLabels,
    selectedLabelIds,
    isLoading: reportLabelsQuery.loading,
    selectedReport: report,
    isDisabled: !canEditReport,
    onLabelsChanged: (newLabels) => {
      editReportLabels({
        selectedReport: report,
        newReportLabels: newLabels,
        oldReportLabels: labels,
      })
    },
  } satisfies Partial<ReportLabelsPickerProps>

  return (
    <Flex flexWrap="wrap" gap={1}>
      {firstLabels?.map((label) => (
        <ReportLabelsPicker
          key={label.id}
          currentLabel={label}
          {...commonProps}
        />
      ))}
      {nextLabel && (
        <ReportLabelsPicker currentLabel={nextLabel} {...commonProps} />
      )}
    </Flex>
  )
}
