import { type Filter } from 'constants/types'
import { URL_PARAM_KEYS } from 'features/reports/consts'
import { type FiltersFromParams } from 'features/reports/hooks/types'
import { dimensionsStateAtom } from 'features/reports/hooks/useDimensionsState'
import { metricsStateAtom } from 'features/reports/hooks/useMetricsState'
import { reportStateAtom } from 'features/reports/hooks/useReportState'
import {
  getValidMetricProcessors,
  getValidDimensions,
  getValidMetrics,
} from 'features/reports/utils/utils'
import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { normalizedMetricsAtom } from 'graphql/statistics/useMetrics'
import { atom } from 'jotai'
import { decodeJson } from 'use-query-params'

export const filtersParamsAtom = atom<FiltersFromParams>(
  (decodeJson(
    new URLSearchParams(window.location.search).get(URL_PARAM_KEYS.FILTERS),
  ) as FiltersFromParams) || undefined,
)

export const filtersStateAtom = atom<Record<string, Filter[]>>((get) => {
  const report = get(reportStateAtom)
  const { dimensions } = get(dimensionsStateAtom)
  const { metrics } = get(metricsStateAtom)
  const normalizedMetrics = get(normalizedMetricsAtom)
  const normalizedDimensions = get(normalizedDimensionsAtom)
  const filters = get(filtersParamsAtom)
  const emptyObject: Record<string, Filter[]> = {}

  const validMetricProcessors = getValidMetricProcessors(dimensions)
  const getValidFilters = (filters: Record<string, Filter[]>) => {
    return Object.entries(filters).reduce(
      (acc, [key, filter]) => {
        const dimension = normalizedDimensions[key]

        if (dimension) {
          const validDimensions = getValidDimensions([dimension], metrics)

          if (validDimensions.length === 0) {
            return acc
          }

          acc[key] = filter
        }
        const metric = normalizedMetrics[key]

        if (!metric || !metrics.includes(metric)) {
          return acc
        }

        const validMetrics = getValidMetrics(
          [metric],
          normalizedMetrics,
          validMetricProcessors,
        )

        if (validMetrics.length === 0) {
          return acc
        }

        acc[key] = filter

        return acc
      },
      {} as Record<string, Filter[]>,
    )
  }

  if (!filters) {
    // We set filters to null explicitly when its emptied by the user and then we don't want to have a default fallback
    if (filters === null || !report?.filters) {
      return emptyObject
    }

    return getValidFilters(report.filters)
  }

  return getValidFilters(filters)
})
