import { getCompareDateString } from 'components/Datepicker/RangeDatepicker/getCompareDateString/getCompareDateString'
import { addWeeks } from 'date-fns'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { renderToString } from 'react-dom/server'
import { colorTheme, getColor } from 'ui/theme/colors'
import { calcTotalDifference } from 'utils/calcTotalDifference'
import { formatMetric } from 'utils/numberFormats'
import { SERIES_MAP } from './consts'

interface TooltipFormatterProps {
  currency: string | undefined
}

export const getChartTooltip = ({ currency }: TooltipFormatterProps) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    const startDateTime = new Date(Number(this.x))
    const endTime = addWeeks(startDateTime, 1)

    const element = (
      <>
        <p
          style={{
            marginBottom: '8px',
            color: colorTheme.grey[700],
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
          }}
        >
          {getCompareDateString(startDateTime, endTime)}
        </p>

        {this.points?.map(({ point }) => {
          const isPotentialPoint = point.series.name === SERIES_MAP.POTENTIAL
          const diff = isPotentialPoint
            ? calcTotalDifference({
                format: METRIC_FORMAT.CURRENCY,
                currency,
                includePrefix: true,
                value: Number(point.y),
                compareValue: this.points?.find(
                  ({ point }) => point.series.name === SERIES_MAP.ACTUAL,
                )?.point.y,
              })
            : null

          return (
            <TooltipMetricRow
              key={point.colorIndex}
              metricName={point.series.name}
              iconColor={String(point.color)}
              diffValue={diff?.formattedDifference}
              diffColor={diff?.color ? getColor(diff.color) : undefined}
              value={
                point.y ? formatMetric('currency', point.y, currency) : 'N/A'
              }
            />
          )
        })}
      </>
    )

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
}
