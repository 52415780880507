import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  OrderedList,
  ListItem,
} from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import 'highlight.js/styles/a11y-light.css'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useEffect, useState } from 'react'
import { TagCopier } from '../TagCopier/TagCopier'
import pageviewSnippet from './pageviewSnippet.txt'

type PageviewProps = {
  isOpen: boolean
  onClose: () => void
}

export const PageviewModal = ({ isOpen, onClose }: PageviewProps) => {
  const [fetchedSnippet, setFetchedSnippet] = useState('')
  const [snippetWithFrontendId, setSnippetWithFrontendId] = useState('')
  const { frontendId = '' } = useMerchantInfo()

  const addFrontendId = (originalSnippet: string, frontendId: string) => {
    return originalSnippet.replace('FRONTEND_ID', frontendId)
  }

  useEffect(() => {
    fetch(pageviewSnippet)
      .then((r) => r.text())
      .then((text) => {
        setFetchedSnippet(text)
      })
  }, [])

  useEffect(() => {
    const addedFrontendId = addFrontendId(fetchedSnippet, frontendId)

    setSnippetWithFrontendId(addedFrontendId)
  }, [fetchedSnippet, frontendId])

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pageview tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <OrderedList mb={4} pl={1}>
            <ListItem>
              <Typography>
                Log in to your GTM account, click on &quot;Tags&quot; in the
                left sidebar navigation and click the &quot;New&quot; button on
                top of the page.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Name the tag&nbsp;
                <Typography as="span" fontWeight={500}>
                  &quot;Dema pageview&quot;
                </Typography>
                .
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Click on &quot;Custom HTML Tag&quot; and copy/paste the
                following code into the text field:
              </Typography>
            </ListItem>
          </OrderedList>
          <TagCopier snippet={snippetWithFrontendId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
