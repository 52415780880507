import { type QueryResult, useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type ReportLabel,
  type ReportLabelsQuery,
} from 'generated/graphql/graphql'

export const REPORT_LABELS_QUERY = graphql(/* GraphQL */ `
  query ReportLabels($withReportCount: Boolean! = false) {
    viewer {
      id
      merchant {
        id
        reportLabels {
          id
          name
          color
          reportCount @include(if: $withReportCount)
        }
      }
    }
  }
`)

export type ReportLabelWithoutCount = Omit<ReportLabel, 'reportCount'>
type ReportLabelsQueryResult<T extends boolean> = {
  reportLabels: T extends true ? ReportLabel[] : ReportLabelWithoutCount[]
  query: QueryResult<ReportLabelsQuery>
}

export const useReportLabels = <T extends boolean>({
  withReportCount,
}: {
  withReportCount: T
}): ReportLabelsQueryResult<T> => {
  const query = useQuery(REPORT_LABELS_QUERY, {
    variables: {
      withReportCount,
    },
  })

  const reportLabels = query.data?.viewer?.merchant.reportLabels ?? []

  return {
    reportLabels,
    query,
  } as ReportLabelsQueryResult<T>
}
