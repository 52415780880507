import { AdvancedMultiSelectFilter } from 'components/Filters/AdvancedMultiSelectFilter'
import { NumberFilter } from 'components/Filters/NumberFilter/NumberFilter'
import { SimpleMultiSelectFilter } from 'components/Filters/SimpleMultiSelectFilter'
import { type BaseFilterProps } from 'components/Filters/types'
import useAddFilter from 'features/reports/hooks/filters/useAddFilter'
import { useFiltersState } from 'features/reports/hooks/filters/useFiltersState'
import { useRemoveFilter } from 'features/reports/hooks/filters/useRemoveFilter'
import {
  useNormalizedDimensions,
  type NormalizedDimensions,
} from 'graphql/statistics/useDimensions'
import { type FC } from 'react'

type PopupType = 'simpleMultiselect' | 'advancedMultiselect' | 'numeric'

const getFilterPopupType = (id: string, dimensions: NormalizedDimensions) => {
  const isDimension = dimensions[id]

  return !isDimension
    ? 'numeric'
    : (popupTypeMappings[id] ?? 'simpleMultiselect')
}

const popupTypeMappings: Record<string, PopupType> = {
  campaign: 'advancedMultiselect',
  voucher: 'advancedMultiselect',
  landingPage: 'advancedMultiselect',
  product: 'advancedMultiselect',
  productName: 'advancedMultiselect',
  variant: 'advancedMultiselect',
  brand: 'advancedMultiselect',
  category: 'advancedMultiselect',
  subCategory: 'advancedMultiselect',
  collection: 'advancedMultiselect',
  material: 'advancedMultiselect',
  customAttribute1: 'advancedMultiselect',
  customAttribute2: 'advancedMultiselect',
  customAttribute3: 'advancedMultiselect',
  customAttribute4: 'advancedMultiselect',
  customAttribute5: 'advancedMultiselect',
}

const emptyArray: string[] = []

export const FilterPopupPicker: FC<BaseFilterProps> = (props) => {
  const normalizedDimensions = useNormalizedDimensions()
  const filterState = useFiltersState()
  const addFilter = useAddFilter()
  const removeFilter = useRemoveFilter()

  const { value: selectedFilterValue, maxValue: selectedFilterValueMax } =
    filterState?.[props.filterId]?.[0] ?? {}
  const selectedOptions = !selectedFilterValue
    ? emptyArray
    : Array.isArray(selectedFilterValue)
      ? selectedFilterValue
      : [selectedFilterValue]

  const popup: PopupType = getFilterPopupType(
    props.filterId,
    normalizedDimensions,
  )

  const propsExtended = { ...props, addFilter, removeFilter }

  if (popup === 'simpleMultiselect')
    return (
      <SimpleMultiSelectFilter
        {...propsExtended}
        selectedOptions={selectedOptions}
      />
    )

  if (popup === 'advancedMultiselect')
    return (
      <AdvancedMultiSelectFilter
        {...propsExtended}
        selectedOptions={selectedFilterValue}
      />
    )

  const numberFilterValue =
    !Array.isArray(selectedFilterValue) && selectedFilterValueMax
      ? [selectedFilterValue, selectedFilterValueMax]
      : selectedFilterValue

  return <NumberFilter {...propsExtended} values={numberFilterValue} />
}
