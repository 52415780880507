import { Box, type BoxProps } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import hljs from 'highlight.js'
import 'highlight.js/styles/a11y-light.css'
import { useEffect, useState, type FC } from 'react'
import styles from './styles.module.scss'

type TagCopierProps = BoxProps & {
  snippet: string
}

export const TagCopier: FC<TagCopierProps> = ({ snippet, ...rest }) => {
  const [copied, setCopied] = useState(false)
  const [snippetToShow, setSnippetToShow] = useState('')

  useEffect(() => {
    const highlightedSnippet = hljs.highlight(snippet, {
      language: 'xml',
    }).value

    setSnippetToShow(highlightedSnippet)
  }, [snippet])

  const handleCopy = () => {
    navigator.clipboard.writeText(snippet)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  return (
    <Box
      border="1px solid"
      borderColor="grey.300"
      borderRadius="8px"
      p="8px 16px 24px"
      {...rest}
    >
      <Button
        variant="outline"
        colorScheme="grey"
        onClick={handleCopy}
        bg="white"
        leadingIcon={
          copied
            ? {
                name: 'CheckCircleIcon',
                size: 'small',
              }
            : undefined
        }
        position="sticky"
        right={0}
        top={0}
        marginLeft="auto"
        display="flex"
      >
        {copied ? 'Copied' : 'Copy to clipboard'}
      </Button>
      <Typography
        as="pre"
        mt="-16px"
        whiteSpace="pre-wrap"
        wordWrap="break-word"
        className={styles.code}
      >
        <code
          className="language-html"
          dangerouslySetInnerHTML={{ __html: snippetToShow }}
        />
      </Typography>
    </Box>
  )
}
