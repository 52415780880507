import { type SortDirection } from 'ag-grid-community'
import { useCallback, useMemo } from 'react'
import { JsonParam, useQueryParam } from 'use-query-params'
import { URL_PARAM_KEYS } from '../consts'
import { useDimensionsState } from './useDimensionsState'
import { useMetricsState } from './useMetricsState'
import { useReportStateAtom } from './useReportState'

export const tableStateParamConfigMap = {
  [URL_PARAM_KEYS.TABLE_STATE]: JsonParam,
}

export type TableState = {
  id: string
  sort: SortDirection
  isPinned: boolean
}[]

const getDefaultColumnState = (metricKeys: string[], dimensionKeys: string[]) =>
  dimensionKeys.concat(metricKeys).map(
    (key) =>
      ({
        id: key,
        isPinned: false,
        sort: metricKeys[0] === key ? 'desc' : null,
      }) as const,
  )

export const getValidTableState = ({
  tableState,
  metricKeys,
  dimensionKeys,
}: {
  tableState: TableState | null | undefined
  metricKeys: string[]
  dimensionKeys: string[]
}): TableState => {
  if (!tableState) {
    return getDefaultColumnState(metricKeys, dimensionKeys)
  }

  const newTableState = [...tableState]

  const dimensionsSet = new Set(dimensionKeys)
  const metricsSet = new Set(metricKeys)

  const dimensionsAndMetrics = [...dimensionKeys, ...metricKeys]

  const columnIds = newTableState.map((column) => column.id)
  const columnIdsSet = new Set(columnIds)
  const missingIds = dimensionsAndMetrics.filter((id) => !columnIdsSet.has(id))

  // insert the missing ids into state in the correct order so that the missing id comes right behind the closest id to the left of it from the metricKeys array
  missingIds.forEach((id) => {
    const indexOfId = dimensionsAndMetrics.indexOf(id)
    let leftMostIndex = 0

    for (let i = indexOfId - 1; i >= 0; i--) {
      const pivotId = dimensionsAndMetrics[i]

      if (columnIdsSet.has(pivotId)) {
        leftMostIndex = columnIds.indexOf(pivotId) + 1
        break
      }
    }

    newTableState.splice(leftMostIndex, 0, { id, sort: null, isPinned: false })
    columnIds.splice(leftMostIndex, 0, id)
    columnIdsSet.add(id)
  })

  // Keep only selected metrics or dimensions
  const validColumns = newTableState.filter(
    (column) => metricsSet.has(column.id) || dimensionsSet.has(column.id),
  )

  if (!validColumns.some((column) => column.sort)) {
    const firstMetricIndex = validColumns.findIndex((column) =>
      metricsSet.has(column.id),
    )

    validColumns[firstMetricIndex] = {
      ...validColumns[firstMetricIndex],
      sort: 'desc',
    }
  }

  return validColumns
}

export const useAnalyticsTableState = () => {
  const report = useReportStateAtom()
  const [queryTableState, setTableState] = useQueryParam<
    TableState | null | undefined
  >(
    URL_PARAM_KEYS.TABLE_STATE,
    tableStateParamConfigMap[URL_PARAM_KEYS.TABLE_STATE],
  )

  const tableState = queryTableState ?? report?.tableState

  const { metricKeys } = useMetricsState()
  const { dimensionKeys } = useDimensionsState()

  const sanitizedState = useMemo<TableState>(() => {
    return getValidTableState({ tableState, dimensionKeys, metricKeys })
  }, [tableState, dimensionKeys, metricKeys])

  const setTableSorting = useCallback(
    (columnId: string, sortOrder: SortDirection) => {
      const newState: TableState = sanitizedState.map((column) => ({
        ...column,
        sort: columnId === column.id ? sortOrder : null,
      }))

      setTableState(newState)
    },
    [sanitizedState, setTableState],
  )

  const setTableColumnPinning = useCallback(
    (columnId: string, isPinned: boolean) => {
      setTableState(() => {
        const newColumns: TableState = sanitizedState.map((column) => ({
          ...column,
          sort: column.sort,
          isPinned: columnId === column.id ? isPinned : column.isPinned,
        }))

        return newColumns
      })
    },
    [sanitizedState, setTableState],
  )

  const moveTableColumn = useCallback(
    (fromIndex: number, toIndex: number) => {
      // Ag-Grid returns fromIndex = -1 when the column is moved to an invalid position
      if (fromIndex < 0) {
        return
      }
      setTableState(() => {
        const newColumns = [...sanitizedState]

        const [movedColumn] = newColumns.splice(fromIndex, 1) // pop the column

        newColumns.splice(toIndex, 0, movedColumn) // insert the popped column

        return newColumns
      })
    },
    [sanitizedState, setTableState],
  )

  const setValidTableState = useCallback(
    ({
      tableState,
      dimensions,
      metrics,
    }: {
      tableState: TableState | null
      dimensions: string[]
      metrics: string[]
    }) => {
      setTableState(
        getValidTableState({
          tableState,
          dimensionKeys: dimensions,
          metricKeys: metrics,
        }),
      )
    },
    [setTableState],
  )

  return {
    state: sanitizedState,
    setTableSorting,
    setTableColumnPinning,
    setValidTableState,
    moveTableColumn,
  } as const
}
