import { MenuItem, useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Icon } from 'components/Icon/Icon'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useCreateReport } from 'graphql/reports/useCreateReport'
import { type ReportResult } from 'graphql/reports/utils'
import type React from 'react'
import { type useSelectReport } from '../hooks/useSelectReport'

interface DuplicateReportMenuItemProps {
  report: ReportResult
  selectReport: ReturnType<typeof useSelectReport>
}

export const DuplicateReportMenuItem: React.FC<
  DuplicateReportMenuItemProps
> = ({ report, selectReport }) => {
  const [createReport, createReportState] = useCreateReport()
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  const createDuplicatedReport = async () => {
    try {
      const response = await createReport({
        report: {
          ...report,
          name: `Copy of ${report.name}`,
          dynamicDate: report.dynamicDate || undefined,
          compareStartDate: report.compareStartDate || undefined,
          compareDynamicDate: report.compareDynamicDate || undefined,
          compareEndDate: report.compareEndDate || undefined,
        },
      })

      toast({
        status: 'success',
        description: 'Successfully duplicated report',
      })
      selectReport(response)
      trackEvent({
        eventName: 'Report Duplicated',
        eventProperties: {
          reportId: report.id,
          newReportId: response.id,
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not create report',
      })
      captureException(`Duplicate report: ${error}`)
    }
  }

  return (
    <MenuItem
      onClick={createDuplicatedReport}
      disabled={createReportState.loading}
      icon={<Icon name="Copy2Icon" size="small" />}
    >
      Duplicate report
    </MenuItem>
  )
}
