import { type SortDirection } from 'ag-grid-community'
import { type IconName } from 'components/Icon/Icon'

export const getHeaderIcon = (
  sortDirection: SortDirection,
): IconName | undefined => {
  if (sortDirection === 'asc') {
    return 'ArrowUpIcon'
  } else if (sortDirection === 'desc') {
    return 'ArrowDownIcon'
  }

  return undefined
}

export const getCompareCellColor = (
  value?: number,
  isReversePositive?: boolean,
) => {
  // zero will be treated as 'N/A' so it should be gray
  if (value) {
    const sign = isReversePositive ? -1 : 1

    return sign * value > 0 ? 'green.600' : 'red.500'
  }

  return 'grey.800'
}
