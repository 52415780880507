import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { format } from 'date-fns'
import { graphql } from 'generated/graphql'
import { type IntegrationsViewQueryQuery } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { AccountCell } from './AccountCell'
import { ConnectionButton } from './ConnectionButton'
import { type AccountConnection } from './types'
import { generateIntegrationAccountsForTable } from './utils'

const columnHelper = createColumnHelper<AccountConnection>()

const formatDate = (date?: Date) => {
  if (date) return format(date, 'yyyy-MM-dd HH:mm')

  return 'No data available'
}

const getConnectedOpacity = (connected: boolean) => (connected ? '100%' : '50%')

const columns = [
  columnHelper.accessor('account', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        <AccountCell
          accounts={getValue().accounts}
          company={getValue().company}
          logo={getValue().logo}
        />
      </Flex>
    ),
    header: 'Account',
  }),
  columnHelper.accessor('lastFetch', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        {formatDate(getValue())}
      </Flex>
    ),
    header: 'Last Fetch',
  }),
  columnHelper.accessor('status', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        {getValue()}
      </Flex>
    ),
    header: 'Status',
  }),
  columnHelper.accessor('type', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        {getValue()}
      </Flex>
    ),
    header: 'Type',
  }),
  columnHelper.accessor('disconnectData', {
    cell: ({ row, getValue }) => (
      <ConnectionButton
        connected={row.original.connected}
        serviceName={row.original.account.company}
        {...getValue()}
      />
    ),
    id: 'disconnectButton',
    header: '',
  }),
]

export const Integrations_MerchantSiteFragment = graphql(/* GraphQL */ `
  fragment Integrations_MerchantSite on MerchantSite {
    integrations {
      ...IntegrationFields
    }
  }
`)

type AccountsTableProps = {
  merchantSite: NonNullable<
    IntegrationsViewQueryQuery['viewer']
  >['merchantSite']
  isReadOnly: boolean
  isLoading: boolean
}

export const AccountsTable = ({
  merchantSite,
  isReadOnly,
  isLoading,
}: AccountsTableProps) => {
  const accounts = useMemo(
    () => generateIntegrationAccountsForTable(merchantSite?.integrations ?? []),
    [merchantSite],
  )

  const filteredColumns = useMemo(() => {
    return isReadOnly
      ? columns.filter((column) => column.id !== 'disconnectButton')
      : columns
  }, [isReadOnly])

  const table = useReactTable({
    data: accounts,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading) {
    return (
      <Table>
        <Tbody>
          <SkeletonTableRows columns={5} rows={7} />
        </Tbody>
      </Table>
    )
  }

  const tableRows = table.getRowModel().rows

  if (tableRows.length === 0) {
    return (
      <Flex alignItems="center" py={16} flexDir="column" gap={10}>
        <Image
          src="/images/EmptyData.svg"
          alt="No integrations"
          boxSize={200}
        />
        <Typography>You haven&apos;t added any integrations yet.</Typography>
      </Flex>
    )
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header as string,
                      header.getContext(),
                    )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {tableRows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  minW="130px"
                  alignContent="baseline"
                  _last={{ w: '1px' }} // button column should not expand more than needed
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
