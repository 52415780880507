import { MenuItem, useDisclosure } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type ReportResult } from 'graphql/reports/utils'
import type React from 'react'
import { DeleteReportModal } from './DeleteReportModal'

interface DeleteReportMenuItemProps {
  report: ReportResult
}

export const DeleteReportMenuItem: React.FC<DeleteReportMenuItemProps> = ({
  report,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <MenuItem
        icon={<Icon name="TrashIcon" size="small" />}
        onClick={() => {
          onOpen()
        }}
        closeOnSelect={false}
      >
        Delete
      </MenuItem>
      <DeleteReportModal isOpen={isOpen} onClose={onClose} report={report} />
    </>
  )
}
