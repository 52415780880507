import {
  Tag as ChakraTag,
  TagCloseButton,
  TagLabel,
  type TagProps as ChakraTagProps,
} from '@chakra-ui/react'

export type SolidVariant = {
  variant: Extract<ChakraTagProps['variant'], 'solid'>
  colorScheme: Extract<
    ChakraTagProps['colorScheme'],
    'primary' | 'grey' | 'blackAlpha'
  >
}

type OutlineVariant = {
  variant: Extract<ChakraTagProps['variant'], 'outline'>
  colorScheme?: never
}

type SelectableVariant = {
  variant: 'selectable'
  colorScheme?: Extract<
    ChakraTagProps['colorScheme'],
    'white' | 'grey' | 'blackAlpha'
  >
}

export type TagProps = ChakraTagProps & {
  label: string
  onClose?: () => void
} & (SolidVariant | OutlineVariant | SelectableVariant)

export const Tag = ({
  label,
  variant,
  colorScheme,
  size,
  onClose,
  ...props
}: TagProps) => (
  <ChakraTag
    size={size}
    variant={variant}
    colorScheme={colorScheme}
    justifyContent="space-between"
    pr={onClose && size === 'sm' ? 0.5 : onClose ? 1 : undefined}
    {...props}
  >
    <TagLabel>{label}</TagLabel>
    {onClose && <TagCloseButton onClick={onClose} />}
  </ChakraTag>
)
