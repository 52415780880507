import { ArrayParam } from 'use-query-params'

export const NumberArrayParam = {
  encode: (array: number[] | null | undefined) => {
    if (!array) {
      return array
    }

    const strArray = array.map((val) => String(val))

    return ArrayParam.encode(strArray)
  },
  decode: (arrayStr: string | (string | null)[] | null | undefined) => {
    const baseDecode = ArrayParam.decode(arrayStr)

    if (!baseDecode) {
      return baseDecode
    }

    return baseDecode.map((val) => {
      const numberVal = Number(val)

      return isFinite(numberVal) ? numberVal : null
    })
  },
}
