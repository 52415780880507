import { useDateState } from 'features/reports/hooks/useDateState'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type FC, type ReactElement } from 'react'
import { FilterPopupPicker } from '../FilterPopupPicker'
interface TableFilterProps {
  filterId: string
  filterLabel: string
  setPopupOpen: (isOpen: boolean) => void
  isPopupOpen: boolean
  children: ReactElement
}

const TableFilter: FC<TableFilterProps> = ({
  filterId,
  filterLabel,
  isPopupOpen,
  setPopupOpen,
  children,
}) => {
  const { dateRange } = useDateState()
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    skip: !isPopupOpen, // Only dispatch request when opening popup
    dimensionId: filterId,
    dateRange,
  })

  return (
    <FilterPopupPicker
      filterId={filterId}
      filterLabel={filterLabel}
      isOpen={isPopupOpen}
      onOpen={() => setPopupOpen(true)}
      onClose={() => setPopupOpen(false)}
      options={uniqueDimensionValues}
      isLoading={query.loading}
      popupButton={children}
    />
  )
}

export default TableFilter
