import { getValidTableState } from 'features/reports/hooks/useAnalyticsTableState'
import { type Report } from 'generated/graphql/graphql'

const dimensions = ['merchantSite', 'channelGroup']
const metrics = [
  'session:count',
  'order:count',
  'order:total',
  'session:bounceRate',
]

export const report: Report = {
  analyticsConfig: {
    id: '',
    chart: { type: 'line', xAxis: 'day', yAxis: 'session:count' },
    createdAt: '1676329200000',
    dimensions: dimensions,
    endDate: '2023-02-14T23:59:59+01:00',
    filters: {},
    metrics: metrics,
    startDate: '2023-01-17T00:00:00+01:00',
    tableState: getValidTableState({
      tableState: null,
      dimensionKeys: dimensions,
      metricKeys: metrics,
    }),
    updatedAt: '2024-05-16T13:00:00+01:00',
  },
  chart: { type: 'line', xAxis: 'day', yAxis: 'session:count' },
  compareDynamicDate: null,
  compareEndDate: null,
  compareStartDate: null,
  createdAt: '1676329200000',
  description: '',
  dimensions,
  dynamicDate: 'last-week',
  endDate: '2023-02-14T23:59:59+01:00',
  filters: {},
  tableState: getValidTableState({
    tableState: null,
    dimensionKeys: dimensions,
    metricKeys: metrics,
  }),
  id: '',
  metrics,
  name: 'Report name',
  owner: {
    id: 'owner-id',
    externalId: 'external-owner-id',
  },
  startDate: '2023-01-17T00:00:00+01:00',
  updatedAt: '2024-05-16T13:00:00+01:00',
  visibility: [],
  labels: [],
  favorite: false,
}
