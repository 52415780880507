import { Button, Flex } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { Typography } from 'components/Typography'
import { type ReportResult } from 'graphql/reports/utils'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { type Dispatch, type SetStateAction, useState, type FC } from 'react'
import { useReportStateAtom } from '../../hooks/useReportState'
import { ReportFavoriteStar } from '../ReportFavoriteStar'
import { useGenerateReportUrl } from '../ReportSidebar/hooks/useGenerateReportUrl'
import { useSelectReport } from '../ReportSidebar/hooks/useSelectReport'
import { ReportContextualMenu } from '../ReportSidebar/ReportContextualMenu/ReportContextualMenu'
import { DownloadCSVButton } from './DownloadCSVButton'
import { ReportHeaderWrapper } from './ReportHeaderWrapper'
import { ReportNameDescription } from './ReportNameDescription'
import { ReportPanelControl } from './ReportPanelControl'
import { SaveChangesButtons } from './SaveChangesButtons'

const defaultReport = {
  name: '',
  description: '',
}

interface EditReportHeaderProps {
  selectedReport?: ReportResult
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

const EditReportHeader: FC<EditReportHeaderProps> = ({
  selectedReport,
  setIsEditing,
}) => {
  const [editedReport, setEditedReport] = useState(
    selectedReport ?? defaultReport,
  )

  return (
    <>
      <ReportNameDescription
        reportState={editedReport}
        setReportState={setEditedReport}
      />
      <Flex gap={2} alignItems="center">
        <Button
          size="sm"
          colorScheme="gray"
          variant="outline"
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </Button>
        <SaveChangesButtons
          selectedReport={selectedReport}
          reportName={editedReport?.name}
          reportDescription={editedReport?.description}
          onCompleted={() => setIsEditing(false)}
        />
        <ReportPanelControl />
      </Flex>
    </>
  )
}

interface NonEditReportHeaderProps {
  data: NormalizedStatistic[]
  selectedReport?: ReportResult
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

const NonEditReportHeader: FC<NonEditReportHeaderProps> = ({
  data,
  selectedReport,
  setIsEditing,
}) => {
  const generateReportUrl = useGenerateReportUrl()
  const selectReport = useSelectReport()
  const { user } = useUser()

  return (
    <>
      <Flex gap={2} alignItems="center">
        <Typography fontSize="lg" fontWeight={500} lineHeight={7}>
          {selectedReport?.name}
        </Typography>
        <ReportFavoriteStar report={selectedReport} size="sm" />
        <ReportContextualMenu
          isEditable={true}
          report={selectedReport}
          userId={user?.publicMetadata.dema_id?.toString()}
          generateReportUrl={generateReportUrl}
          selectReport={selectReport}
          setIsEditing={setIsEditing}
        />
      </Flex>
      <Flex gap={2} alignItems="center">
        <DownloadCSVButton selectedReportId={selectedReport?.id} data={data} />
        <SaveChangesButtons
          selectedReport={selectedReport}
          reportName={selectedReport?.name}
          reportDescription={selectedReport?.description}
        />
        <ReportPanelControl />
      </Flex>
    </>
  )
}

interface ReportHeaderProps {
  data: NormalizedStatistic[]
}

export const ReportHeader: FC<ReportHeaderProps> = ({ data }) => {
  const selectedReport = useReportStateAtom()
  const [isEditing, setIsEditing] = useState(false)

  return (
    <ReportHeaderWrapper>
      {isEditing ? (
        <EditReportHeader
          selectedReport={selectedReport}
          setIsEditing={setIsEditing}
        />
      ) : (
        <NonEditReportHeader
          data={data}
          selectedReport={selectedReport}
          setIsEditing={setIsEditing}
        />
      )}
    </ReportHeaderWrapper>
  )
}
