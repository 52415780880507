import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { toZonedTime } from 'date-fns-tz'
import { merchantInfoAtom } from 'graphql/useMerchantInfo'
import { atom, useAtom, useAtomValue } from 'jotai'
import { isEqual } from 'lodash-es'
import { useEffect } from 'react'
import {
  BooleanParam,
  StringParam,
  useQueryParams,
  decodeNumericArray,
  decodeString,
  decodeBoolean,
} from 'use-query-params'
import { NumberArrayParam } from 'utils/customQueryParams'
import { URL_PARAM_KEYS } from '../consts'
import {
  getDefaultDateRange,
  getPresetFromUrlCompareDynamicDate,
  getPresetFromUrlDynamicDate,
  getValidCompareDateRange,
} from '../utils/utils'
import { reportStateAtom } from './useReportState'

export interface DateState {
  dateRange: DateRange
  compareDateRange: DateRange
  dynamicDate: DYNAMIC_DATE_ID | undefined
  compareDynamicDate: COMPARE_DYNAMIC_DATE_ID | undefined
  isCompare: boolean
}

export const dynamicDates = [
  DYNAMIC_DATE_ID.CUSTOM_RANGE,
  DYNAMIC_DATE_ID.TODAY,
  DYNAMIC_DATE_ID.YESTERDAY,
  DYNAMIC_DATE_ID.LAST_7_DAYS,
  DYNAMIC_DATE_ID.LAST_14_DAYS,
  DYNAMIC_DATE_ID.LAST_28_DAYS,
  DYNAMIC_DATE_ID.THIS_WEEK_TO_DATE,
  DYNAMIC_DATE_ID.LAST_WEEK,
  DYNAMIC_DATE_ID.THIS_MONTH_TO_DATE,
  DYNAMIC_DATE_ID.LAST_MONTH,
  DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE,
  DYNAMIC_DATE_ID.LAST_YEAR,
  DYNAMIC_DATE_ID.LAST_12_MONTHS,
]

export const compareDynamicDates = [
  COMPARE_DYNAMIC_DATE_ID.CUSTOM_RANGE,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
]

const DEFAULT_DYNAMIC_DATE = DYNAMIC_DATE_ID.CUSTOM_RANGE

export const dateStateParamConfigMap = {
  [URL_PARAM_KEYS.DATE_RANGE]: NumberArrayParam,
  [URL_PARAM_KEYS.COMPARE_DATE_RANGE]: NumberArrayParam,
  [URL_PARAM_KEYS.DYNAMIC_DATE]: StringParam,
  [URL_PARAM_KEYS.COMPARE_DYNAMIC_DATE]: StringParam,
  [URL_PARAM_KEYS.IS_COMPARE]: BooleanParam,
}

const searchParam = new URLSearchParams(window.location.search)

const dateStateParamAtom = atom<
  ReturnType<typeof useQueryParams<typeof dateStateParamConfigMap>>[0]
>({
  dateRange: decodeNumericArray(searchParam.getAll(URL_PARAM_KEYS.DATE_RANGE)),
  compareDateRange: decodeNumericArray(
    searchParam.getAll(URL_PARAM_KEYS.COMPARE_DATE_RANGE),
  ),
  dynamicDate: decodeString(searchParam.get(URL_PARAM_KEYS.DYNAMIC_DATE)),
  compareDynamicDate: decodeString(
    searchParam.get(URL_PARAM_KEYS.COMPARE_DYNAMIC_DATE),
  ),
  isCompare: decodeBoolean(searchParam.get(URL_PARAM_KEYS.IS_COMPARE)),
})

const dateStateAtom = atom((get) => {
  const report = get(reportStateAtom)
  const { timezone } = get(merchantInfoAtom)
  const dateState = get(dateStateParamAtom)

  const {
    dateRange,
    dynamicDate,
    compareDateRange,
    compareDynamicDate,
    isCompare,
  } = dateState

  const isReportDatePopulated = getIsPopulatedDateRange([
    report?.startDate ?? null,
    report?.endDate ?? null,
  ])
  const isReportCompareDatePopulated = getIsPopulatedDateRange([
    report?.compareStartDate ?? null,
    report?.compareEndDate ?? null,
  ])
  const validIsCompare =
    isCompare ?? (!!report?.compareDynamicDate || isReportCompareDatePopulated)

  const dynamicDatePreset = getPresetFromUrlDynamicDate(
    dynamicDate ?? report?.dynamicDate ?? DEFAULT_DYNAMIC_DATE,
  )

  const urlDateRange =
    getDefaultDateRange(dateRange, timezone) ??
    (report && isReportDatePopulated
      ? [
          toZonedTime(report.startDate, timezone),
          toZonedTime(report.endDate, timezone),
        ]
      : [null, null])

  const validDateRange: DateRange = [
    dynamicDatePreset?.value[0] ?? urlDateRange[0],
    dynamicDatePreset?.value[1] ?? urlDateRange[1],
  ]

  const compareDynamicDatePreset = getPresetFromUrlCompareDynamicDate(
    compareDynamicDate ??
      (validIsCompare
        ? (report?.compareDynamicDate ?? DEFAULT_DYNAMIC_DATE)
        : undefined),
    validDateRange,
  )

  const urlCompareDateRange =
    getDefaultDateRange(compareDateRange, timezone, true) ??
    (isCompare && isReportCompareDatePopulated
      ? [
          toZonedTime(report?.compareStartDate ?? '', timezone),
          toZonedTime(report?.compareEndDate ?? '', timezone),
        ]
      : [null, null])

  const isCompareCustomRange =
    compareDynamicDatePreset?.id === COMPARE_DYNAMIC_DATE_ID.CUSTOM_RANGE
  const hasRemovedCompareDate =
    isCompareCustomRange && compareDateRange === null

  let validCompareDateRange: DateRange = [
    compareDynamicDatePreset?.value[0] ??
      urlCompareDateRange[0] ??
      // checking that it is not removed
      ((!hasRemovedCompareDate &&
        report?.compareStartDate &&
        toZonedTime(report?.compareStartDate, timezone)) ||
        null),
    compareDynamicDatePreset?.value[1] ??
      urlCompareDateRange[1] ??
      // checking that it is not removed
      ((!hasRemovedCompareDate &&
        report?.compareEndDate &&
        toZonedTime(report?.compareEndDate, timezone)) ||
        null),
  ]

  validCompareDateRange = getValidCompareDateRange(
    validDateRange,
    validCompareDateRange,
  )

  return {
    dateRange: validDateRange,
    dynamicDate: dynamicDatePreset?.id,
    compareDateRange: validCompareDateRange,
    compareDynamicDate: compareDynamicDatePreset?.id,
    isCompare: validIsCompare,
  }
})

export const useDateStateQueryParam = () =>
  useQueryParams(dateStateParamConfigMap)
export const useDateState = () => useAtomValue(dateStateAtom)

export const useDateStateSync = () => {
  const [queryDateState] = useDateStateQueryParam()
  const [dateStateParams, setDateStateParams] = useAtom(dateStateParamAtom)

  useEffect(() => {
    if (!isEqual(queryDateState, dateStateParams)) {
      setDateStateParams(queryDateState)
    }
  }, [dateStateParams, queryDateState, setDateStateParams])
}
