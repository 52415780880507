import { MenuItem, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type ReportResult } from 'graphql/reports/utils'
import type React from 'react'
import { type useGenerateReportUrl } from '../hooks/useGenerateReportUrl'

interface CopyReportURLMenuItemProps {
  report: ReportResult
  generateReportUrl: ReturnType<typeof useGenerateReportUrl>
}

export const CopyReportURLMenuItem: React.FC<CopyReportURLMenuItemProps> = ({
  report,
  generateReportUrl,
}) => {
  const toast = useToast()

  const copyReportUrl = () => {
    const reportUrl =
      `${window.location.origin}/reports?` + generateReportUrl(report)

    navigator.clipboard.writeText(reportUrl)

    toast({
      status: 'success',
      description: 'Copied report url to clipboard',
    })
  }

  return (
    <MenuItem
      onClick={copyReportUrl}
      icon={<Icon name="LinkIcon" size="small" />}
    >
      Copy report URL
    </MenuItem>
  )
}
