import {
  type Metric,
  normalizedMetricsAtom,
  getMetricKeyWithoutAttribution,
} from 'graphql/statistics/useMetrics'
import { atom, useAtom, useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'
import { URL_PARAM_KEYS } from '../consts'
import {
  defaultMetrics,
  getActualMetrics,
  getValidMetrics,
  getValidMetricProcessors,
} from '../utils/utils'
import { dimensionsStateAtom } from './useDimensionsState'
import { reportStateAtom } from './useReportState'

const getUniqueMetrics = (metrics: Metric[]) => {
  const existingKeys = new Set()

  return metrics.reduce<Metric[]>((acc, metric) => {
    const key = getMetricKeyWithoutAttribution(metric)

    if (!existingKeys.has(key)) {
      existingKeys.add(key)
      acc.push(metric)
    }

    return acc
  }, [])
}

export const metricsParamConfigMap = { [URL_PARAM_KEYS.METRICS]: ArrayParam }

const metricsParamAtom = atom<(string | null)[] | null | undefined>(
  new URLSearchParams(window.location.search).getAll(URL_PARAM_KEYS.METRICS),
)

export const metricsStateAtom = atom((get) => {
  const metrics = get(metricsParamAtom)

  const report = get(reportStateAtom)
  const normalizedMetrics = get(normalizedMetricsAtom)
  const { dimensions } = get(dimensionsStateAtom)

  const filteredUrlValues = metrics?.filter<string>(
    (val): val is string => !!val,
  )

  const newMetrics = filteredUrlValues?.length
    ? filteredUrlValues
    : (report?.metrics ?? defaultMetrics)

  const actualMetrics = getActualMetrics(newMetrics, normalizedMetrics)

  const validMetricProcessors = getValidMetricProcessors(dimensions)

  const validMetrics = getValidMetrics(
    actualMetrics,
    normalizedMetrics,
    validMetricProcessors,
  )

  const uniqueMetrics = getUniqueMetrics(validMetrics)
  const metricKeys = validMetrics.map((m) => m.key)

  return {
    metricKeys,
    metrics: validMetrics,
    uniqueMetrics,
    unsafe_allMetricsKeys: newMetrics, // could involve invalid metrics
  }
})

export const useMetricsQueryParam = () =>
  useQueryParam(
    URL_PARAM_KEYS.METRICS,
    metricsParamConfigMap[URL_PARAM_KEYS.METRICS],
  )
export const useMetricsState = () => useAtomValue(metricsStateAtom)

export const useMetricsStateSync = () => {
  const [queryMetrics] = useMetricsQueryParam()
  const [metrics, setMetrics] = useAtom(metricsParamAtom)

  useEffect(() => {
    if (queryMetrics !== metrics) {
      setMetrics(queryMetrics)
    }
  }, [metrics, queryMetrics, setMetrics])
}
