import { useHistoricalAnalysisDateAtom } from 'features/optimizations/atoms/dateAtom'
import {
  type HistoricalAnalysis,
  useHistoricalAnalysisQuery,
} from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useParams } from 'react-router'
import { useHistoricalAnalysisFiltersAtom } from '../components/Settings/Filter'

export const useHistoricalAnalysis = () => {
  const [
    {
      formattedValue: [start, end],
    },
  ] = useHistoricalAnalysisDateAtom()
  const [filters] = useHistoricalAnalysisFiltersAtom()
  const selectedSegment = filters.segment.selected
  const { market } = useParams()

  const query = useHistoricalAnalysisQuery({
    startDate: start,
    endDate: end,
    markets: market ? [market] : [],
    segments: selectedSegment ? [selectedSegment] : [],
  })

  return {
    historicalAnalysis: query.data?.viewer?.merchant.historicalAnalysis as
      | HistoricalAnalysis
      | undefined,
    query,
  }
}
