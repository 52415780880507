import { useOrganization, useUser } from '@clerk/clerk-react'
import config from 'config'
import type { PropsWithChildren } from 'react'
import { IntercomProvider as IntercomProviderBase } from 'react-use-intercom'
import { intercomSelector } from './consts'

export function AuthedIntercomProvider({ children }: PropsWithChildren) {
  const { user } = useUser()
  const { organization } = useOrganization()

  return (
    <IntercomProviderBase
      appId={config.INTERCOM_APP_ID ?? ''}
      autoBoot
      initializeDelay={1000}
      // adds information about the user to intercom
      autoBootProps={{
        email: user?.primaryEmailAddress?.emailAddress,
        name: user?.fullName ?? user?.firstName ?? user?.lastName ?? undefined,
        userId: String(user?.publicMetadata?.dema_id),
        hideDefaultLauncher: true,
        customLauncherSelector: '#' + intercomSelector,
        company: {
          name: organization?.name,
          companyId: String(organization?.publicMetadata?.dema_id),
          website: organization?.name,
        },
      }}
    >
      {children}
    </IntercomProviderBase>
  )
}

// Will only be used for showing banners and not for showing the chat
export function UnAuthedIntercomProvider({ children }: PropsWithChildren) {
  return (
    <IntercomProviderBase
      appId={config.INTERCOM_APP_ID ?? ''}
      autoBoot
      initializeDelay={1000}
      autoBootProps={{
        hideDefaultLauncher: true,
      }}
    >
      {children}
    </IntercomProviderBase>
  )
}
