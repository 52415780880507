import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useCurrentReportsState } from 'features/reports/hooks/useCurrentReportsState'
import { REPORT_PAGES } from 'features/reports/routePages'
import { useCreateReport } from 'graphql/reports/useCreateReport'
import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router'
import { newReportViewStateAtom, useResetNewReportViewState } from './atom'

interface CreateNewReportButtonProps {
  isValidReportNaming: boolean
}

export const CreateNewReportButton = ({
  isValidReportNaming,
}: CreateNewReportButtonProps) => {
  const newReportViewState = useAtomValue(newReportViewStateAtom)
  const resetNewReportState = useResetNewReportViewState()
  const [createReport, createReportState] = useCreateReport()
  const reportState = useCurrentReportsState()
  const toast = useToast()
  const navigate = useNavigate()
  const hasValidDataOptions =
    reportState.dimensions.length > 0 && reportState.metrics.length > 0
  const isValidReport = isValidReportNaming && hasValidDataOptions

  const createNewReport = async () => {
    try {
      const report = await createReport({
        report: {
          ...reportState,
          name: newReportViewState.name,
          description: newReportViewState.description,
          visibility: newReportViewState.visibility,
          labels: newReportViewState.labels,
        },
      })

      navigate(REPORT_PAGES.REPORT_BY_ID(report.id))
      resetNewReportState()
      toast({ status: 'success', description: 'Successfully created report!' })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save report as copy, please try again',
      })
      captureException(`Save report copy: ${error}`)
    }
  }

  return (
    <Tooltip
      isDisabled={isValidReport}
      label={
        !hasValidDataOptions
          ? 'Select at least one dimension and metric'
          : !isValidReportNaming
            ? 'Title must include at least 2 characters'
            : undefined
      }
      placement="bottom-end"
    >
      <Button
        size="sm"
        colorScheme="primary"
        variant="solid"
        onClick={createNewReport}
        isDisabled={!isValidReport}
        isLoading={createReportState.loading}
      >
        Create report
      </Button>
    </Tooltip>
  )
}
