import { orgRolesArray, orgRoleKeys, type OrgRole } from 'constants/roles'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { useCreateOrganizationInvitation } from 'features/profile/graphql/useCreateOrganizationInvitation'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { type Invitations } from './types'

const invitationSchema = z.object({
  email: z.string().email(),
  role: z.enum(Object.values(orgRoleKeys) as [OrgRole, ...string[]]),
})

interface Props {
  invitations: Invitations | null
}

export const InviteMemberModal: React.FC<Props> = ({ invitations }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [createInvitation] = useCreateOrganizationInvitation()

  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  const {
    setValue,
    formState: { isValid, errors, isSubmitting },
    register,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    resolver: zodResolver(invitationSchema),
    defaultValues: {
      email: '',
      role: orgRoleKeys.MEMBER as OrgRole,
    },
  })

  const sendInvitation = async (values: z.infer<typeof invitationSchema>) => {
    try {
      const { data } = await createInvitation({
        variables: { params: { email: values.email, role: values.role } },
      })

      const createInvitationResult = data?.createOrganizationInvitation

      switch (createInvitationResult?.__typename) {
        case 'OrganizationInvitation': {
          // refetch the list of invites
          await invitations?.revalidate?.()

          toast({
            status: 'info',
            description: `An invitation was sent to ${values.email}`,
            position: 'bottom',
          })
          trackEvent({
            eventName: 'Team Invite Sent',
            eventProperties: {
              memberEmail: values.email,
              memberRole: values.role,
            },
          })

          reset()
          onClose()
          break
        }
        case 'CreateOrganizationInvitationError': {
          toast({
            status: 'warning',
            description: createInvitationResult.message,
            position: 'bottom',
          })
          break
        }
        default: {
          return
        }
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not send invitation, please try again',
        position: 'bottom',
      })
    }
  }

  return (
    <Box>
      <Button onClick={onOpen}>Invite member</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite team members</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(sendInvitation)}>
            <ModalBody>
              <FormControl mb={6}>
                <FormLabel
                  htmlFor="email"
                  fontSize="xs"
                  mb={2}
                  color="gray.700"
                >
                  Email
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="email@xyz.com"
                  {...register('email', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <Dropdown
                label="Role"
                value={watch('role')}
                options={orgRolesArray.map((role) => ({
                  id: role.key,
                  label: role.label,
                  name: role.label,
                }))}
                callback={(role) => setValue('role', role.id)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                variant="outline"
                mr={2}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                type="submit"
                colorScheme="primary"
                isLoading={isSubmitting}
                isDisabled={!isValid}
              >
                Send invite
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
