import { type ReportResult } from 'graphql/reports/utils'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { toISODateRange } from 'utils/dateRange'
import { useFiltersState } from './filters/useFiltersState'
import { useAnalyticsTableState } from './useAnalyticsTableState'
import { useChartState } from './useChartState'
import { useDateState } from './useDateState'
import { useDimensionsState } from './useDimensionsState'
import { useMetricsState } from './useMetricsState'
import { useReportStateAtom } from './useReportState'

export type ReportsState = Omit<
  ReportResult,
  'createdAt' | 'merchantId' | 'name' | 'description' | 'owner'
>

export const useCurrentReportsState = (): ReportsState => {
  const { timezone } = useMerchantInfo()
  const { dateRange, compareDateRange, dynamicDate, compareDynamicDate } =
    useDateState()
  const [{ xAxis, color: chartGroup, series }] = useChartState()
  const filters = useFiltersState()
  const { state: tableState } = useAnalyticsTableState()
  const { metricKeys: metrics } = useMetricsState()
  const { dimensionKeys: dimensions } = useDimensionsState()
  const report = useReportStateAtom()
  const [startDate, endDate] = toISODateRange(dateRange, timezone)
  const [compareStartDate, compareEndDate] = toISODateRange(
    compareDateRange,
    timezone,
  )

  return {
    chart: { xAxis, color: chartGroup, series },
    metrics,
    dimensions,
    dynamicDate,
    startDate: startDate ?? '',
    endDate: endDate ?? '',
    compareDynamicDate,
    compareStartDate: compareStartDate || undefined,
    compareEndDate: compareEndDate || undefined,
    filters,
    labels: report?.labels || [],
    tableState,
    id: report?.id ?? '',
    updatedAt: new Date().toISOString(),
    visibility: report?.visibility ?? [],
    favorite: false,
  }
}
