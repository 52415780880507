import {
  Box,
  type BoxProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  Menu,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import {
  useRef,
  type FC,
  type ReactElement,
  type ReactNode,
  useEffect,
} from 'react'

export type FilterPopoverProps = {
  children: ReactNode
  popupButton: ReactElement
  onOpenChange?: (isOpen: boolean) => void
  isOpenByDefault?: boolean
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  focusedElementRef?: React.RefObject<HTMLElement> | null
  popoverContainerProps?: Omit<BoxProps, 'ref'>
}

export const FilterPopover: FC<FilterPopoverProps> = ({
  children,
  popupButton,
  focusedElementRef,
  isOpenByDefault = false,
  isOpen: isOpenProps,
  onOpen: onOpenProps,
  onClose: onCloseProps,
  onOpenChange,
  popoverContainerProps,
}) => {
  const popoverContentRef = useRef<HTMLDivElement>(null)
  const popoverContainerRef = useRef<HTMLDivElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: isOpenByDefault,
    isOpen: isOpenProps,
    onOpen: onOpenProps,
    onClose: onCloseProps,
  })

  useEffect(() => {
    focusedElementRef?.current?.focus()
  })

  return (
    <Box
      as={Menu}
      ref={popoverContainerRef}
      overflow="hidden"
      display="flex"
      {...popoverContainerProps}
    >
      <Popover
        isOpen={isOpen}
        onClose={() => {
          onOpenChange?.(false)
          onClose()
        }}
        onOpen={() => {
          onOpenChange?.(true)
          onOpen()
        }}
        initialFocusRef={focusedElementRef ?? undefined}
        placement="bottom-start"
        trigger="click"
        isLazy
      >
        <PopoverTrigger>{popupButton}</PopoverTrigger>
        <Portal>
          <MenuOverlay isOpen={isOpen} />
          <PopoverContent p={0} ref={popoverContentRef}>
            <Box zIndex={2}>{children}</Box>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}
