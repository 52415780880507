import { type DateRange } from 'constants/types'
import { type DropdownOption } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { sub } from 'date-fns'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { useMemo, type FC } from 'react'
import { TaxonomyMenuButton } from './TaxonomyMenuButton'

type Props = {
  depth: number
  rowHasValue: boolean
  name?: string
  dimension: DropdownOption
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  isReadOnly?: boolean
  prevDimensionValues: string[]
  setSelectedItem: (option: string) => void
  setError: (errorMessage?: string) => void
}

const now = new Date()

export const dateRange: DateRange = [sub(now, { months: 2 }), now]

export const TaxonomyDropdown: FC<Props> = ({
  depth,
  rowHasValue,
  name,
  dimension,
  isOpen,
  isReadOnly,
  prevDimensionValues,
  onOpen,
  onClose,
  setSelectedItem,
  setError,
}) => {
  const { query, uniqueDimensionValues } = useUniqueDimensionValues({
    dimensionId: String(dimension.id),
    dateRange,
  })

  const options = useMemo(
    () =>
      uniqueDimensionValues
        .filter((option) => option.id && option.name)
        .map((option) => ({
          ...option,
          disabled: prevDimensionValues.includes(option.id.toString()),
        })),
    [prevDimensionValues, uniqueDimensionValues],
  )

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.SINGLE}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      customMenuButton={
        <TaxonomyMenuButton
          rowHasValue={rowHasValue}
          depth={depth}
          group={dimension}
          name={name}
          isOpen={isOpen}
          isReadOnly={isReadOnly}
          setError={setError}
        />
      }
      options={options}
      isLoading={query.loading}
      selected={name}
      setSelected={(option) => {
        setSelectedItem(option)
      }}
      searchPlaceholder={dimension.name}
      containerProps={{ w: 'full' }}
    />
  )
}
