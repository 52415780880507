import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { TARGETS } from 'features/optimizations/consts'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { round } from 'lodash-es'
import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { formatMetric, formatPercentage } from 'utils/numberFormats'
import { type SidebarFormState } from './types'

interface Props {
  isDisabled: boolean
}
export const ChannelConstraints = ({ isDisabled }: Props) => {
  const { currency } = useMerchantInfo()
  const [showConstraints, setShowConstraints] = useState(false)
  const { data } = useOptimizationConfig()
  const channels = data?.viewer?.merchant.optimizationConfig.channels ?? []
  const { getValues, setValue } = useFormContext<SidebarFormState>()
  const target = useWatch({ name: 'target' }) // We need to use watch so that component re-renders when target changes
  const targetValue = target[TARGETS.BUDGET] ?? 0
  const { channelBounds } = getValues()
  const isConstraintsOpen = showConstraints && !isDisabled

  return (
    <>
      <Box mt={6}>
        <Button
          colorScheme="primary"
          variant="link"
          size="md"
          trailingIcon={{
            name: isConstraintsOpen ? 'ChevronUpIcon' : 'ChevronDownIcon',
          }}
          onClick={() => setShowConstraints((c) => !c)}
          isDisabled={isDisabled}
        >
          Channel constraints
        </Button>
      </Box>
      {showConstraints && !isDisabled && (
        <Stack spacing={6} mt={6}>
          {channels.map((channel) => {
            const lowBound = channelBounds[channel.id]?.[0] ?? 0
            const highBound = channelBounds[channel.id]?.[1] ?? 1

            return (
              <Box key={channel.id}>
                <Typography
                  color="gray.700"
                  fontSize="xs"
                  lineHeight={4}
                  mb={2}
                >
                  {channel.name}
                </Typography>
                <RangeSlider
                  // eslint-disable-next-line jsx-a11y/aria-proptypes
                  aria-label={['min', 'max']}
                  onChange={(value) => {
                    const [min, max] = value
                    // The sum of all minimum values should not exceed 100%
                    const maxMinValue = Object.values(channelBounds).reduce(
                      (acc, [minBound]) => {
                        acc -= minBound

                        return round(Math.max(acc, 0), 2)
                      },
                      1,
                    )
                    const limitMin = Math.min(min, lowBound + maxMinValue)

                    setValue(
                      `channelBounds.${channel.id}`,
                      [round(limitMin, 2), round(max, 2)], // Round to avoid
                      { shouldValidate: true },
                    )
                  }}
                  colorScheme="primary"
                  min={0}
                  max={1}
                  defaultValue={[lowBound, highBound]}
                  value={[lowBound, highBound]}
                  step={0.01}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} bg="primary.500" />
                  <RangeSliderThumb index={1} bg="primary.500" />
                </RangeSlider>
                <Flex justifyContent="space-between" mb={0.5}>
                  <Typography
                    px={2}
                    py={1}
                    fontSize="xs"
                    lineHeight={4}
                    color="gray.900"
                  >
                    {formatPercentage(lowBound, 0)}
                  </Typography>
                  <Typography
                    px={2}
                    py={1}
                    fontSize="xs"
                    lineHeight={4}
                    color="gray.900"
                  >
                    {formatPercentage(highBound, 0)}
                  </Typography>
                </Flex>
                <Flex justifyContent="space-between">
                  <Typography
                    px={2}
                    py={1}
                    bg="gray.100"
                    borderRadius="sm"
                    fontSize="xs"
                    lineHeight={4}
                    color="gray.800"
                    maxW="125px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    title={formatMetric(
                      METRIC_FORMAT.CURRENCY,
                      lowBound * targetValue,
                      currency,
                    )}
                  >
                    {formatMetric(
                      METRIC_FORMAT.CURRENCY,
                      lowBound * targetValue,
                      currency,
                    )}
                  </Typography>
                  <Typography
                    px={2}
                    py={1}
                    bg="gray.100"
                    borderRadius="sm"
                    fontSize="xs"
                    lineHeight={4}
                    color="gray.800"
                    maxW="125px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    title={formatMetric(
                      METRIC_FORMAT.CURRENCY,
                      highBound * targetValue,
                      currency,
                    )}
                  >
                    {formatMetric(
                      METRIC_FORMAT.CURRENCY,
                      highBound * targetValue,
                      currency,
                    )}
                  </Typography>
                </Flex>
              </Box>
            )
          })}
        </Stack>
      )}
    </>
  )
}
