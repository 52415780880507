import { MenuItem, MenuList } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type HeaderMenuProps } from '../types'

export const MetricsMenu = ({
  setSortOrder,
  openFilterPopup,
}: HeaderMenuProps) => {
  return (
    <MenuList>
      <MenuItem
        icon={<Icon name="ArrowDownIcon" size="small" />}
        onClick={() => setSortOrder('desc')}
      >
        <Typography>Sort highest to lowest</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="ArrowUpIcon" size="small" />}
        onClick={() => setSortOrder('asc')}
      >
        <Typography>Sort lowest to highest</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="FilterIcon" size="small" />}
        onClick={() => openFilterPopup()}
      >
        <Typography>Filter</Typography>
      </MenuItem>
    </MenuList>
  )
}
