import { Box, Divider, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { LoaderPage } from 'shared'
import { NotFound } from 'shared/NotFound'
import { AnalyticsChart } from '../components/AnalyticsChart/AnalyticsChart'
import { AnalyticsTable } from '../components/AnalyticsTable/AnalyticsTable'
import { ChartQuickAccessSettings } from '../components/ChartQuickAccessSettings'
import { ReportFiltersContainer } from '../components/Filters/ReportFiltersContainer'
import { HiddenDimensionsOrMetricsAlert } from '../components/HiddenDimensionsOrMetricsAlert'
import { ReportHeader } from '../components/ReportHeader/ReportHeader'
import { ReportPanel } from '../components/ReportPanel/ReportPanel'
import { useReportsAnalytics } from '../hooks/useReportsAnalytics'
import { useSyncReportState } from '../hooks/useSyncReportState'

export const ReportView = () => {
  const { report, query, isInSync } = useSyncReportState()

  const { data, aggregatedData, loading } = useReportsAnalytics()
  const [showChart, setShowChart] = useState(true)

  // This is not optimal but it avoids some glitching effects when changing reports. Should be revisited as part of the report state refactoring
  if (query.loading || !isInSync) return <LoaderPage />
  // isInSync is needed for avoiding some flickering effects. This should be revisited as part of the report state refactoring
  if (!report && !query.loading && isInSync) return <NotFound />

  return (
    // zIndex 0 is needed to create a new stacking context so that the different popovers work as expected
    <Flex direction="column" flex={1} h="full" zIndex={0} overflowY="hidden">
      <ReportHeader data={data} />
      <Flex flex={1} h="full" w="full" overflowY="hidden" maxW="full">
        <Flex
          direction="column"
          w="100%"
          flexBasis={0}
          flexShrink={1}
          flexGrow={1}
          h="full"
          overflowY="auto"
        >
          <Box position="sticky" top={0} zIndex={2} bg="white">
            <HiddenDimensionsOrMetricsAlert />

            <ReportFiltersContainer />

            <Divider />
          </Box>

          <ChartQuickAccessSettings
            showChart={showChart}
            setShowChart={setShowChart}
          />
          {showChart && (
            <Box mx={4} maxW="full">
              <AnalyticsChart />
            </Box>
          )}

          <AnalyticsTable
            aggregatedData={aggregatedData}
            data={data}
            isLoading={loading}
          />
        </Flex>

        <ReportPanel />
      </Flex>
    </Flex>
  )
}
