import { Box, Flex, type ButtonProps } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { forwardRef, type FC } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { HEADER_HEIGHT, headerLinks } from './consts'

interface NavLinkProps {
  label: string
  to: string
  matchPath?: string
}

const buttonBaseStyles: ButtonProps = {
  variant: 'unstyled',
  position: 'relative',
  h: HEADER_HEIGHT,
  borderRadius: 0,
  _hover: {
    bg: 'gray.100',
  },
  _active: {
    bg: 'gray.200',
  },
}

type ActiveLineIndicatorProp = {
  isSelected?: boolean
}

const ActiveLineIndicator: FC<ActiveLineIndicatorProp> = () => (
  <Box
    position="absolute"
    bottom={0}
    left={0}
    right={0}
    h={1}
    bg="primary.500"
  />
)

const NavLink: React.FC<NavLinkProps> = ({ label, to, matchPath }) => {
  const match = useMatch({ path: matchPath ?? to })
  const navigate = useNavigate()
  const isSelected = !!match

  return (
    <Button {...buttonBaseStyles} onClick={() => navigate(to)} px={4}>
      <Typography color={isSelected ? 'gray.900' : 'gray.700'}>
        {label}
      </Typography>
      {isSelected && <ActiveLineIndicator />}
    </Button>
  )
}

interface NavIconLinkProps {
  iconName: IconName
  onClick?: () => void
  tooltip?: string
  isSelected?: boolean
  id?: string
}

export const NavIconLink = forwardRef<HTMLButtonElement, NavIconLinkProps>(
  ({ iconName, onClick, tooltip, isSelected, id }, ref) => {
    return (
      <Button
        ref={ref} // Forwarding the ref here
        {...buttonBaseStyles}
        onClick={onClick}
        p="14px"
        id={id}
        _hover={{
          '#active-line-indicator': { bg: 'grey.700' },
        }}
      >
        <Icon
          size="medium"
          name={iconName}
          tooltipLabel={tooltip}
          color="gray.700"
          display="block"
          customSize={20}
        />
        {isSelected && <ActiveLineIndicator />}
      </Button>
    )
  },
)

NavIconLink.displayName = 'NavIconLink'

export const NavLinks = () => {
  const flags = useFeatureFlags()

  return (
    <Flex as="nav">
      {headerLinks.map(
        ({ path, label, featureFlag, matchPath }) =>
          (!featureFlag || flags[featureFlag]) && (
            <NavLink key={path} label={label} to={path} matchPath={matchPath} />
          ),
      )}
    </Flex>
  )
}
