import type { ReportLabelWithoutCount } from 'graphql/reports/useReportLabels'
import { type ReportResult } from 'graphql/reports/utils'
import { atomWithReset, useResetAtom } from 'jotai/utils'

type NewReportViewState = Pick<
  ReportResult,
  'name' | 'description' | 'visibility'
> & {
  labels: ReportLabelWithoutCount[]
}

// Directly writable values
export const newReportViewStateAtom = atomWithReset<NewReportViewState>({
  name: '',
  description: '',
  labels: [],
  visibility: [], // is private by default
})

export const useResetNewReportViewState = () =>
  useResetAtom(newReportViewStateAtom)
