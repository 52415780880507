import { Flex, Switch } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type OptimizationChannel } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { get } from 'lodash-es'
import { colorTheme } from 'ui/theme/colors'
import { useSaturationCurveShowValuesPerDayAtom } from '../SaturationCurves'
import { ChartPointsItem, ChartPointsList } from '../shared/ChartPoints'

import { lineColors } from './MultiChannelGraph'

interface MultiChannelGraphControlPanelProps {
  curveData: SaturationCurveData
  channels: OptimizationChannel[]
}

export const MultiChannelGraphControlPanel: React.FC<
  MultiChannelGraphControlPanelProps
> = ({ curveData, channels }) => {
  const [trackEvent] = useTrackEvent()
  const [showValuesPerDay, setShowValuesPerDay] =
    useSaturationCurveShowValuesPerDayAtom()

  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-start"
      gap={2}
      position="relative"
    >
      <Flex gap={2} alignItems="center">
        <Typography
          fontSize="xs"
          color="gray.900"
          fontWeight={400}
          lineHeight={4}
        >
          Show values per day
        </Typography>
        <Switch
          size="sm"
          isChecked={showValuesPerDay}
          onChange={() => {
            trackEvent({
              eventName:
                'Optimizations Saturation Curve Values Per Day Toggled',
              eventProperties: { showValuesPerDay: !showValuesPerDay },
            })
            setShowValuesPerDay((c) => !c)
          }}
        />
      </Flex>

      <ChartPointsList>
        {curveData.map((data, index) => {
          const { key, primaryShade } = lineColors[index % lineColors.length]

          return (
            <ChartPointsItem
              key={data.channel}
              color={get(colorTheme, `${key}.${primaryShade}`)}
              label={
                channels.find((channel) => channel.id === data.channel)?.name ||
                data.channel
              }
            />
          )
        })}
      </ChartPointsList>
    </Flex>
  )
}
