import { useDemaReports } from 'graphql/reports/useDemaReports'
import { useSetAtom } from 'jotai'
import { REPORT_PAGE_TITLES } from '../routePages'
import { newReportViewStateAtom } from './NewReportView/atom'
import { ReportListTable } from './shared/ReportListTable'

export const DemaReportsView = () => {
  const { demaReports } = useDemaReports()
  const setNewReportViewState = useSetAtom(newReportViewStateAtom)

  return (
    <ReportListTable
      pageTitle={REPORT_PAGE_TITLES.DEMA}
      reports={demaReports}
      addReportCallback={() => {
        setNewReportViewState((prev) => {
          return {
            ...prev,
            visibility: [],
          }
        })
      }}
    />
  )
}
