import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'
import {
  type HistoricalHistoryContribution,
  type HistoricalHistoryReport,
} from '../../../hooks/useHistoricalHistoryAnalysis'
import { getChart } from './getChart'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)

interface MissedPotentialProps {
  contributions: HistoricalHistoryContribution[]
  reports: HistoricalHistoryReport[]
}

export const MissedPotential: React.FC<MissedPotentialProps> = ({
  contributions,
  reports,
}) => {
  const { currency } = useMerchantInfo()

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChart({
        contributions,
        reports,
        currency: currency,
      })}
      containerProps={{ style: { width: '100%' } }}
    />
  )
}
