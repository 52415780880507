import {
  type DropdownOption,
  type ComboBoxSingleProps,
  type DropdownGroup,
} from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { useMetricsState } from 'features/reports/hooks/useMetricsState'
import {
  getMetricLabelWithAttribution,
  getValidDimensions,
} from 'features/reports/utils/utils'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { type AttributedMetric } from 'graphql/statistics/useMetrics'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'

type DimensionMetricAutocompleteProps = Omit<
  ComboBoxSingleProps,
  'setSelected' | 'selectionMode' | 'options'
> & {
  setSelected: (item: DropdownOption) => void
}

export const DimensionMetricAutocomplete = ({
  setSelected,
  ...rest
}: DimensionMetricAutocompleteProps) => {
  const normalizedDimensions = useNormalizedDimensions()
  const dimensions = Object.values(normalizedDimensions)
  const { metrics } = useMetricsState()
  const normalizedAttributionModels = useNormalizedAttributionModels()

  const groups: DropdownGroup[] = useMemo(() => {
    const metricGroups = groupBy(metrics, (metric) => metric.groupKey)

    return [
      {
        name: 'Dimensions',
        items: getValidDimensions(dimensions, metrics).map((dim) => ({
          ...dim,
          name: dim.label,
        })),
      },
      {
        name: 'Metrics',
        items: metrics.map((metric) => ({
          ...metric,
          id: metric.key,
          name:
            metricGroups[metric.groupKey].length > 1
              ? getMetricLabelWithAttribution(
                  metric,
                  normalizedAttributionModels[
                    (metric as AttributedMetric).attributionId ?? ''
                  ]?.label,
                )
              : metric.label,
        })),
      },
    ]
  }, [dimensions, metrics, normalizedAttributionModels])

  return (
    <ComboBox
      {...rest}
      selectionMode={SELECTION_MODE.SINGLE}
      options={groups}
      setSelected={(id) => {
        const item = groups
          .flatMap((group) => group.items)
          .find((item) => item.id === id)

        if (item) {
          setSelected(item)
        }
      }}
    />
  )
}
