import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button, ButtonIcon } from 'components/buttons'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { useCurrentReportsState } from 'features/reports/hooks/useCurrentReportsState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useCreateReport } from 'graphql/reports/useCreateReport'
import { useEditReport } from 'graphql/reports/useEditReport'
import { getIsDemaReport, type ReportResult } from 'graphql/reports/utils'
import { type FC } from 'react'
import { useSelectReport } from '../ReportSidebar/hooks/useSelectReport'

type Props = {
  reportName?: string
  reportDescription?: string
  selectedReport?: ReportResult
  onCompleted?: () => void
}

export const SaveChangesButtons: FC<Props> = ({
  reportName,
  reportDescription,
  selectedReport,
  onCompleted,
}) => {
  const [editReport, editReportState] = useEditReport()
  const [createReport, createReportState] = useCreateReport()
  const [trackEvent] = useTrackEvent()

  const toast = useToast()
  const selectReport = useSelectReport()
  const reportState = useCurrentReportsState()

  const saveReport = async () => {
    if (!selectedReport) {
      toast({
        status: 'error',
        description: 'Could not find selected report, please try again',
      })

      return
    }
    try {
      const report = await editReport({
        id: selectedReport.id,
        report: {
          ...reportState,
          name: reportName,
          description: reportDescription,
        },
      })

      trackEvent({
        eventName: 'Report Saved',
        eventProperties: {
          reportId: selectedReport.id,
        },
      })
      selectReport(report)
      toast({ status: 'success', description: 'Successfully updated report!' })
      onCompleted?.()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save report, please try again',
      })

      captureException(`Save report: ${error}`)
    }
  }

  const saveReportAsCopy = async () => {
    try {
      const report = await createReport({
        report: {
          ...reportState,
          description: reportDescription ?? '',
          name: `Copy of ${reportName}`,
        },
      })

      trackEvent({
        eventName: 'Report Saved As Copy',
        eventProperties: {
          reportId: selectedReport?.id ?? '',
          newReportId: report.id,
        },
      })
      selectReport(report)
      toast({ status: 'success', description: 'Successfully created report!' })
      onCompleted?.()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save report as copy, please try again',
      })

      captureException(`Save report copy: ${error}`)
    }
  }

  const isLoading = editReportState.loading || createReportState.loading
  const canEditReport = useCanEditReport(selectedReport)
  const canSaveReport =
    canEditReport && selectedReport && !getIsDemaReport(selectedReport.owner)

  return (
    <Flex>
      <Button
        size="sm"
        width="full"
        variant="solid"
        colorScheme="primary"
        isLoading={isLoading}
        onClick={canSaveReport ? saveReport : saveReportAsCopy}
      >
        {canSaveReport ? 'Save' : 'Save copy'}
      </Button>

      {canSaveReport && (
        <Menu size="md" placement="bottom-end">
          {({ isOpen }) => (
            <>
              <Tooltip label="More actions" size="sm">
                <MenuButton
                  as={ButtonIcon}
                  size="sm"
                  ml="1px"
                  disabled={isLoading}
                  aria-label="Options"
                  borderLeft="none"
                  variant="solid"
                  colorScheme="primary"
                  roundedLeft="none"
                  name={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                />
              </Tooltip>
              <Portal>
                <MenuOverlay isOpen={isOpen} />

                <MenuList>
                  <MenuItem disabled={isLoading} onClick={saveReportAsCopy}>
                    Save as copy
                  </MenuItem>
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      )}
    </Flex>
  )
}
