import {
  availableIntegrations,
  INTEGRATION_TYPES,
  type IntegrationConfig,
} from 'constants/availableIntegrations'
import type { ResultOf } from '@graphql-typed-document-node/core'
import type { IntegrationFieldsFragmentDoc } from 'generated/graphql/graphql'
import { groupBy } from 'lodash-es'
import type { AccountConnection, AdsMatchingRule } from './types'

type Integration = ResultOf<typeof IntegrationFieldsFragmentDoc>

const matchIntegrationType = (
  account: Integration,
  integration: IntegrationConfig,
): boolean => {
  if (account.config) {
    return account.config.integrationType === integration.type.toLowerCase()
  }

  return true
}

export const generateIntegrationAccountsForTable = (
  integrations: Integration[],
) => {
  const accounts = groupBy(integrations, 'serviceId')

  if (Object.keys(accounts).length === 0) {
    return []
  }
  const accountConnections = [
    ...availableIntegrations[INTEGRATION_TYPES.MARKETING],
    ...availableIntegrations[INTEGRATION_TYPES.INVENTORY],
    ...availableIntegrations[INTEGRATION_TYPES.ORDER],
  ]
  const options: AccountConnection[] = accountConnections.flatMap(
    (integration) =>
      (accounts[integration.serviceId] ?? [])
        .filter((account) => matchIntegrationType(account, integration))
        .map((account) => ({
          account: {
            company: integration.title,
            logo: integration.logo,
            accounts: account.costConfig?.adsMatchingRules as AdsMatchingRule[],
          },
          lastFetch: account.lastFetch
            ? new Date(account.lastFetch)
            : undefined,
          connected: Boolean(account.connected),
          status: !account.connected
            ? 'Disconnected'
            : account.lastError
              ? 'Error'
              : ('Completed' as const),
          type: integration.type,
          disconnectData: {
            serviceId: account.serviceId,
            integrationId: account.id,
          },
        })),
  )

  return options
}
