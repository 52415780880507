import { useMutation } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'
import { graphql } from 'generated/graphql'
import { useCallback } from 'react'
import { type MixpanelEvents } from './types'

const TRACK_EVENT_MUTATION = graphql(/* GraphQL */ `
  mutation TrackEventMutation($params: TrackUserEventInput!) {
    trackUserEvent(params: $params)
  }
`)

type EventId = {
  [EventType in keyof MixpanelEvents]: {
    [EventName in keyof MixpanelEvents[EventType]]: `${EventType} ${string}`
  }[keyof MixpanelEvents[EventType]]
}[keyof MixpanelEvents]

type EventProperties<Name extends EventId> =
  Name extends `${infer EventType} ${infer EventName}`
    ? EventType extends keyof MixpanelEvents
      ? EventName extends keyof MixpanelEvents[EventType]
        ? MixpanelEvents[EventType][EventName]
        : never
      : never
    : never

export const useTrackEvent = () => {
  const [mutation, state] = useMutation(TRACK_EVENT_MUTATION)
  const { isSignedIn } = useUser()
  const trackEvent = useCallback(
    async <EventName extends EventId>({
      eventName,
      eventProperties,
    }: {
      eventName: EventName
      eventProperties: EventProperties<EventName>
    }) => {
      // we don't want to track events for unauthorized users
      if (!isSignedIn) return

      const trackEventMutationResult = await mutation({
        variables: {
          params: {
            eventName: eventName.trim(),
            eventProperties,
          },
        },
      })

      return trackEventMutationResult.data?.trackUserEvent
    },
    [isSignedIn, mutation],
  )

  return [trackEvent, state] as const
}
