import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  OrderedList,
  ListItem,
} from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import hljs from 'highlight.js'
import { type FC, useState, useEffect } from 'react'
import { TagCopier } from '../TagCopier/TagCopier'
import orderTrackingSnippet from './orderTrackingSnippet.txt'
import 'highlight.js/styles/a11y-light.css'

type OrderTrackingProps = {
  isOpen: boolean
  onClose: () => void
}

export const OrderTrackingModal: FC<OrderTrackingProps> = ({
  isOpen,
  onClose,
}) => {
  const [fetchedSnippet, setFetchedSnippet] = useState('')
  const [highlightedVariable, setHighlightedVariable] = useState('')

  useEffect(() => {
    fetch(orderTrackingSnippet)
      .then((r) => r.text())
      .then((text) => {
        setFetchedSnippet(text)
      })
  }, [])

  useEffect(() => {
    const highlight = hljs.highlight('{{<variable name>}}', {
      language: 'xml',
    }).value

    setHighlightedVariable(highlight)
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Order tracking tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <OrderedList mb={4} pl={1}>
            <ListItem>
              <Typography>
                Log in to your GTM account, click on &quot;Tags&quot; in the
                left sidebar navigation and click the &quot;New&quot; button on
                top of the page.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Name the tag&nbsp;
                <Typography fontWeight={500} as="span">
                  &quot;Dema order tracking&quot;
                </Typography>
                .
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Click on &quot;Custom HTML Tag&quot; and copy/paste the
                following code into the text field:
              </Typography>
            </ListItem>
          </OrderedList>
          <TagCopier maxH="280px" overflow="auto" snippet={fetchedSnippet} />
          <OrderedList mt={4} pl={1} start={4}>
            <ListItem>
              <Typography>
                Add the Data Layer Variables (DLVs). If you already have create
                DLVs, rename the values from the script above with the name of
                your variables. Otherwise, click on &quot;Variables&quot; in the
                left sidebar navigation and look for the &quot;User-Defined
                Variables&quot; section.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography as="span" display="flex" flexWrap="wrap">
                <Typography maxW="fit-content">
                  Click the &quot;New&quot; button and add one DLV for each of
                  the values within double bracers
                </Typography>
                <pre>
                  <code
                    className="language-html"
                    dangerouslySetInnerHTML={{ __html: highlightedVariable }}
                  />
                </pre>
                in the script above.
              </Typography>
            </ListItem>
          </OrderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
