import { Flex, Image, Td, Tr } from '@chakra-ui/react'
import { Typography } from 'components/Typography'

export const EmptyMappings = () => (
  <Tr>
    <Td colSpan={100} border="unset">
      <Flex
        alignItems="center"
        py={16}
        flexDir="column"
        gap={10}
        justifyItems="center"
      >
        <Image
          src="/images/EmptyData.svg"
          alt="No integrations"
          boxSize={200}
        />
        <Typography>No mappings found.</Typography>
      </Flex>
    </Td>
  </Tr>
)
