import { Box, Flex, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { AnalyticsChart } from 'features/reports/components/AnalyticsChart/AnalyticsChart'
import { AnalyticsTable } from 'features/reports/components/AnalyticsTable/AnalyticsTable'
import { ChartQuickAccessSettings } from 'features/reports/components/ChartQuickAccessSettings'
import { useCurrentReportsState } from 'features/reports/hooks/useCurrentReportsState'
import { useReportsAnalytics } from 'features/reports/hooks/useReportsAnalytics'
import { useState } from 'react'

export const DataSection = () => {
  const { data, aggregatedData, loading } = useReportsAnalytics()
  const [showChart, setShowChart] = useState(true)
  const reportState = useCurrentReportsState()
  const isValidReport =
    reportState.dimensions.length > 0 && reportState.metrics.length > 0

  if (!isValidReport) {
    return (
      <Box p={10}>
        <Flex alignItems="center" pt={100} flexDir="column" gap={10}>
          <Image src="/images/EmptyData.svg" alt="No data" boxSize={200} />
          <Typography>You haven&apos;t added any data yet</Typography>
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <ChartQuickAccessSettings
        showChart={showChart}
        setShowChart={setShowChart}
      />
      {showChart && (
        <Box mx={4} maxW="full">
          <AnalyticsChart />
        </Box>
      )}

      <AnalyticsTable
        aggregatedData={aggregatedData}
        data={data}
        isLoading={loading}
      />
    </>
  )
}
