import { type DropdownOption } from 'components/Dropdown/types'
import { type FilterPopoverProps } from './shared/FilterPopover'

export enum FilterOperator {
  contains = 'contains',
  doesNotContain = 'doesNotContain',
  isAnyOf = 'isAnyOf',
  isNoneOf = 'isNoneOf',
  greaterThan = 'gt',
  lessThan = 'lt',
  between = 'between',
}

export type AddFilterArgsBase = {
  filterId: string
  selectedFilterGroup: FilterOperator
}

export type BaseFilterProps = {
  filterLabel?: string
  filterId: string
  defaultSelectedFilter?: FilterOperator
  options: DropdownOption[]
  selectedOptions?: string[] | string
  isLoading: boolean
  removeFilter?: (filterId: string) => void
} & Omit<FilterPopoverProps, 'children'>

export type FilterTupleWithNull = [number | null, number | null]
export type FilterTuple = [number, number]
