import { ToggleWithIcons } from 'components/ToggleWithIcons/ToggleWithIcons'
import { ORGANIZATION_ID } from 'features/reports/consts'
import { type UserTeam } from 'graphql/teams/useUserTeams'
import type React from 'react'

interface TeamToggleProps {
  teams: UserTeam[]
  allTitle: string
  selected: string
  setSelected: (selected: string) => void
}

export const TeamToggle: React.FC<TeamToggleProps> = ({
  teams,
  allTitle,
  selected,
  setSelected,
}) => {
  return (
    <ToggleWithIcons
      items={[
        {
          id: ORGANIZATION_ID,
          title: allTitle,
          iconName: 'BulletListIcon',
        },
        ...teams.map((team) => ({
          iconName: team.iconName,
          id: team.id,
          title: team.name,
          iconProps: {
            hexColor: team.iconColor,
            size: 'small' as const,
          },
        })),
      ]}
      buttonProps={{ size: 'xs' }}
      selected={selected}
      setSelected={setSelected}
    />
  )
}
