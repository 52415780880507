import {
  CompareValueType,
  getOptimalOptimizationMetric,
  optimizationsMetricValuesMap,
  type MetricOptimizationTableColumn,
  type OPTIMIZATION_METRIC,
} from 'features/optimizations/consts'
import { type ContributionData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type OptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { calcPercentageDiff } from 'utils/calcPercentageDiff'
import { derivedMetrics, totalKey } from './consts'
import { type GroupedChannel } from './types'

interface GetAggregatedDataProps {
  contribution: ContributionData | undefined
  channelGroups: Record<string, GroupedChannel[]>
  columns: MetricOptimizationTableColumn[]
}

type AggregationRow = Record<OPTIMIZATION_METRIC, number>
type AggregationResult = Record<string, AggregationRow>

const initializeAggregation = (
  accumulator: AggregationResult,
  aggregationKey: string,
  rowKey: OPTIMIZATION_METRIC,
) => {
  const nextAggregation = accumulator[aggregationKey] ?? {}

  nextAggregation[rowKey] = 0
  nextAggregation[getOptimalOptimizationMetric(rowKey)] = 0

  return nextAggregation
}

const aggregateRow = (
  accumulator: AggregationResult,
  row: Record<string, unknown>,
  groups: [string, GroupedChannel[]][],
  columns: MetricOptimizationTableColumn[],
) => {
  const [groupKey] =
    groups.find(([, group]) => group.some(({ id }) => id === row.channel)) ?? []

  if (!groupKey) return

  columns.forEach(({ key }) => {
    const optimalKey = getOptimalOptimizationMetric(key)
    const value = optimizationsMetricValuesMap[key].getValue(row)
    const optimalValue = optimizationsMetricValuesMap[optimalKey].getValue(row)

    accumulator[totalKey][key] += isFinite(value) ? value : 0
    accumulator[groupKey][key] += isFinite(value) ? value : 0
    accumulator[totalKey][optimalKey] += isFinite(optimalValue)
      ? optimalValue
      : 0
    accumulator[groupKey][optimalKey] += isFinite(optimalValue)
      ? optimalValue
      : 0
  })
}

export const getAggregatedData = ({
  contribution,
  channelGroups,
  columns,
}: GetAggregatedDataProps) => {
  const groups = Object.entries(channelGroups)
  // Initialize the aggregated data
  const calculatedData = columns.reduce((acc, { key }) => {
    acc[totalKey] = initializeAggregation(acc, totalKey, key)
    groups.forEach(([groupKey]) => {
      acc[groupKey] = initializeAggregation(acc, groupKey, key)
    })

    return acc
  }, {} as AggregationResult)

  contribution?.forEach((row) => {
    aggregateRow(calculatedData, row, groups, columns)
  })

  // Derived metrics need to be calculated separately since they depend on other metrics
  derivedMetrics.forEach((metric) => {
    calculatedData[totalKey][metric] = optimizationsMetricValuesMap[
      metric
    ].getValue(calculatedData[totalKey])
    groups.forEach(([groupKey]) => {
      calculatedData[groupKey][metric] = optimizationsMetricValuesMap[
        metric
      ].getValue(calculatedData[groupKey])
    })
  })

  return calculatedData
}

interface GroupedOptimizationChannels {
  name: string
  id: string
  channel: string
  funnel: string
}

export const groupOptimizationChannels = (
  contributionData: ContributionData,
  optimizationConfig: OptimizationConfig | undefined,
) => {
  const result: Record<string, GroupedOptimizationChannels[]> = {}

  if (!optimizationConfig) return result

  for (const { channel } of contributionData) {
    const { name } =
      optimizationConfig.channels.find(({ id }) => id === channel) ?? {}

    if (!name) continue

    const [channelName, ...rest] = name.split(' ')

    if (!result[channelName]) {
      result[channelName] = [] as GroupedOptimizationChannels[]
    }

    result[channelName].push({
      name,
      id: String(channel),
      channel: channelName,
      funnel: rest.join(' '),
    })
  }

  return result
}

export const getCompareValue = (
  type: CompareValueType,
  previousValue: number,
  currentValue: number,
) => {
  if (type === CompareValueType.Percentage) {
    return calcPercentageDiff(currentValue, previousValue) / 100
  }

  return currentValue - previousValue
}
