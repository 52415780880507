import { Flex, type FlexProps } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { type ButtonIconProps } from 'components/buttons/ButtonIcon'
import { type IconName, type IconProps } from 'components/Icon/Icon'
import { type ReactElement } from 'react'
import { colorToRgba } from 'ui/theme/colors'

type Item<Id extends string> = {
  id: Id
  title: string
  iconProps?: Partial<IconProps>
} & (
  | { customIcon?: never; iconName: IconName }
  | { customIcon: ReactElement; iconName?: never }
)

type Props<Id extends string> = FlexProps & {
  items: Item<Id>[]
  selected: Id
  setSelected: (selected: Id) => void
  buttonProps?: Omit<ButtonIconProps, 'name' | 'title'>
}

export const ToggleWithIcons = <Id extends string>({
  items,
  selected = items[0].id,
  setSelected,
  buttonProps,
  ...rest
}: Props<Id>) => {
  return (
    <Flex
      w="fit-content"
      flexWrap="wrap"
      borderRadius="none"
      rowGap={1}
      {...rest}
    >
      {items.map((item) => {
        const isSelected = selected === item.id
        const { iconProps, customIcon, iconName } = item ?? {}

        const sharedProps: Omit<ButtonIconProps, 'name' | 'customIcon'> = {
          iconProps,
          colorScheme: 'grey',
          variant: 'unstyled',
          display: 'flex',
          alignItems: 'center',
          title: item.title,
          size: 'sm',
          _hover: isSelected
            ? {}
            : {
                bg: 'gray.300',
                color: 'gray.900',
              },
          color: isSelected ? 'gray.900' : 'gray.700',
          boxShadow: isSelected ? 'base' : 'none',
          border: '1px solid',
          borderColor: isSelected
            ? colorToRgba('gray.900', 0.16)
            : 'transparent',

          bg: isSelected ? 'white' : 'gray.200',
          ...buttonProps,
        }

        if (customIcon) {
          return (
            <ButtonIcon
              key={item.id}
              {...sharedProps}
              onClick={() => setSelected(item.id)}
              customIcon={customIcon}
            />
          )
        }

        return (
          <ButtonIcon
            key={item.id}
            {...sharedProps}
            onClick={() => setSelected(item.id)}
            name={iconName}
          />
        )
      })}
    </Flex>
  )
}
