import { DiffStatus, STATUS_COLORS } from 'constants/statusColors'
import { type IconName } from 'components/Icon/Icon'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import { type ColorType } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'
import { calcPercentageDiff } from './calcPercentageDiff'

const getWidgetFormat = (format: MetricFormat): MetricFormat => {
  switch (format) {
    case METRIC_FORMAT.INTEGER:
      return METRIC_FORMAT.WIDGET_NUMBER
    case METRIC_FORMAT.DECIMAL:
      return METRIC_FORMAT.WIDGET_DECIMAL
    default:
      return format
  }
}

const percentFormats: MetricFormat[] = [METRIC_FORMAT.PERCENT]

export type CalcTotalDifferenceProps = {
  value: number
  compareValue: number | undefined
  format: MetricFormat
  currency?: string
  isOppositeDiffColor?: boolean
  includePrefix?: boolean
  percentFormat?: MetricFormat
}

export const calcTotalDifference = ({
  value,
  compareValue,
  format,
  currency,
  isOppositeDiffColor = false,
  includePrefix,
  percentFormat = METRIC_FORMAT.PERCENT,
}: CalcTotalDifferenceProps) => {
  const isCompareValueExist = !!compareValue && isFinite(compareValue)

  const percentageDifference = isCompareValueExist
    ? calcPercentageDiff(value, compareValue) / 100
    : undefined

  const valueDifference = value - (compareValue ?? 0)

  const isPositiveDiff = (percentageDifference ?? 0) > 0
  const isNegativeDiff = (percentageDifference ?? 0) < 0

  // negative values will already get the '-' sign
  const prefix = includePrefix && isPositiveDiff ? '+' : ''

  const formattedPercentageDifference = percentageDifference
    ? prefix + formatMetric(percentFormat, percentageDifference)
    : undefined

  const isPercentage = percentFormats.includes(format)

  const metricFormat: MetricFormat = isPercentage
    ? METRIC_FORMAT.PERCENT
    : getWidgetFormat(format)

  const formattedValue = prefix + formatMetric(metricFormat, value, currency)
  const formattedDifference =
    prefix + formatMetric(metricFormat, valueDifference, currency)

  const isGreenCondition =
    (isPositiveDiff && !isOppositeDiffColor) ||
    (isNegativeDiff && isOppositeDiffColor)
  const isRedCondition =
    (isNegativeDiff && !isOppositeDiffColor) ||
    (isPositiveDiff && isOppositeDiffColor)

  const color: ColorType = isGreenCondition
    ? STATUS_COLORS[DiffStatus.INCREASE].color
    : isRedCondition
      ? STATUS_COLORS[DiffStatus.DECREASE].color
      : 'orange.400'

  const iconName: IconName = isPositiveDiff
    ? 'ArrowUpIcon'
    : isNegativeDiff
      ? 'ArrowDownIcon'
      : 'ArrowRightIcon'

  const status: DiffStatus = isGreenCondition
    ? DiffStatus.INCREASE
    : isRedCondition
      ? DiffStatus.DECREASE
      : DiffStatus.NEUTRAL

  return {
    formattedPercentageDifference,
    formattedValue,
    color,
    percentageDifference,
    formattedDifference,
    valueDifference,
    iconName,
    status,
  }
}
