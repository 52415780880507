import { lazy } from 'react'
import { Navigate, type RouteObject } from 'react-router-dom'

export const routes: RouteObject[] = [
  {
    // force /profile to go to /profile/personal
    index: true,
    element: <Navigate to="personal" replace />,
  },
  {
    path: 'personal',
    Component: lazy(() => import('./views/Personal/Personal')),
  },
]
