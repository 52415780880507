import { type DropdownOption } from 'components/Dropdown'
import { FilterOperator } from 'components/Filters/types'
import { type Metric } from 'graphql/statistics/useMetrics'
import { useMemo } from 'react'
import { formatMetric } from 'utils/numberFormats'
import {
  type FilterOperatorSingleValue,
  filterOperatorMappings,
  filterOperatorMappingsWithSingleValue,
} from './shared/FilterGroup/FilterGroup'

type FormatDisplayValueProps = {
  value?: string
  options: DropdownOption[]
  metricFormat?: Metric['format']
}

const formatDisplayValue = ({
  value = '',
  options,
  metricFormat,
}: FormatDisplayValueProps) => {
  const selectedDropdownValue = options.find(({ id }) => id === value)

  if (selectedDropdownValue) {
    return selectedDropdownValue.name
  }

  return formatMetric(metricFormat ?? 'integer', Number(value))
}

type Props = {
  values: string[]
  operator: FilterOperator
  options: DropdownOption[]
  metricFormat?: Metric['format']
  maxValue?: string
}

export const useFilterOperator = ({
  values,
  operator,
  options,
  metricFormat,
  maxValue,
}: Props) => {
  const { selectedValues, operatorText } = useMemo(() => {
    const firstFormattedValue = formatDisplayValue({
      value: values[0],
      options,
      metricFormat,
    })

    const formattedMaxValue = formatDisplayValue({
      value: maxValue,
      options,
      metricFormat,
    })

    const firstValue = [
      FilterOperator.contains,
      FilterOperator.doesNotContain,
    ].includes(operator)
      ? values[0]
      : firstFormattedValue

    const selectedValues =
      operator === FilterOperator.between
        ? `${firstValue} and ${formattedMaxValue}`
        : values.length > 1
          ? `${firstValue} and ${values.length - 1} more`
          : firstValue

    const operatorText =
      values.length === 1 &&
      [FilterOperator.isAnyOf, FilterOperator.isNoneOf].includes(operator)
        ? filterOperatorMappingsWithSingleValue[
            operator as FilterOperatorSingleValue
          ]
        : filterOperatorMappings[operator]

    return { selectedValues, operatorText }
  }, [maxValue, metricFormat, operator, options, values])

  return { selectedValues, operatorText }
}
