import { type DateRange } from 'constants/types'
import { type DropdownOption } from 'components/Dropdown/types'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useDimensionValues } from 'graphql/statistics/useDimensionValues'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { toISODateRange } from '../utils/dateRange'

type Props = {
  skip?: boolean
  dimensionId: string
  dateRange: DateRange
}

export const useUniqueDimensionValues = ({
  skip = false,
  dimensionId,
  dateRange,
}: Props): {
  uniqueDimensionValues: DropdownOption[]
  query: typeof query
} => {
  const { timezone, frontendIds = [] } = useMerchantInfo()
  const [startDate, endDate] = toISODateRange(dateRange, timezone)
  const normalizedDimensions = useNormalizedDimensions()

  const shouldFetch =
    Boolean(normalizedDimensions[dimensionId]) &&
    Boolean(timezone) &&
    Boolean(startDate) &&
    Boolean(endDate) &&
    frontendIds.length > 0

  const { query, dimensionValues } = useDimensionValues({
    skip: skip || !shouldFetch,
    variables: {
      dimensionValuesParams: {
        frontendIds,
        startDate: startDate as string,
        endDate: endDate as string,
        dimension: dimensionId,
      },
    },
  })

  const uniqueDimensionValues = useMemo(() => {
    const uniqueValues =
      dimensionValues.map<DropdownOption>(({ value, formattedValue }) => ({
        id: String(value),
        name: formattedValue,
      })) ?? []

    // Sort alphabetically
    uniqueValues.sort((a, b) => a.name.localeCompare(b.name))

    return uniqueValues
  }, [dimensionValues])

  return { uniqueDimensionValues, query }
}
