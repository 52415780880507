import { Image, Box, Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import Input from 'components/Input/Input'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { useReportLabels } from 'graphql/reports/useReportLabels'
import { startTransition, useMemo, useState } from 'react'
import { BaseView } from '../../shared/BaseView'
import { AddLabelModal } from './AddLabelModal'
import { DeleteLabelModal } from './DeleteLabelModal'
import { EditLabelModal } from './EditLabelModal'

export const OrganisationLabelsView = () => {
  const [searchInput, setSearchInput] = useState('')
  const { reportLabels, query: reportLabelsQuery } = useReportLabels({
    withReportCount: true,
  })

  const filteredReportLabels = useMemo(() => {
    return !searchInput
      ? reportLabels
      : reportLabels.filter((label) =>
          label.name.toLowerCase().includes(searchInput.toLowerCase()),
        )
  }, [reportLabels, searchInput])

  return (
    <BaseView title="Labels" description="Manage labels for your organisation.">
      <Box maxW="524px">
        <Flex justifyContent="space-between" mt={10}>
          <Input
            maxW={180}
            size="sm"
            leadingIcon={{
              name: 'MagnifyingGlassIcon',
            }}
            placeholder="Search labels"
            onChange={(e) => {
              startTransition(() => setSearchInput(e.target.value))
            }}
          />
          <AddLabelModal />
        </Flex>
        <Table mt={6}>
          <Tbody>
            {reportLabelsQuery.loading ? (
              <SkeletonTableRows rows={5} columns={1} />
            ) : filteredReportLabels.length === 0 ? (
              <Box p={10}>
                <Flex alignItems="center" flexDir="column" gap={10}>
                  <Image
                    src="/images/EmptyData.svg"
                    alt="No data"
                    boxSize={200}
                  />
                  <Typography>You haven&apos;t added any label yet</Typography>
                </Flex>
              </Box>
            ) : (
              filteredReportLabels.map((label) => (
                <Tr display="flex" key={label.id}>
                  <Td px={0} flexGrow={1} alignContent="center">
                    <Flex alignItems="center" gap={2}>
                      <Box m={2} bg={label.color} w={2} h={2} />
                      <Typography
                        fontSize="xs"
                        fontWeight={500}
                        color="gray.800"
                        lineHeight={4}
                      >
                        {label.name}
                      </Typography>
                      <Typography fontSize="xs" color="gray.700" lineHeight={4}>
                        •
                      </Typography>
                      <Typography fontSize="xs" color="gray.600" lineHeight={4}>
                        {label.reportCount || 0} reports
                      </Typography>
                    </Flex>
                  </Td>
                  <Td px={0}>
                    <EditLabelModal currentLabel={label} />
                    <DeleteLabelModal label={label} />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
    </BaseView>
  )
}
