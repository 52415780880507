import { MenuItem } from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import type { UserResource } from '@clerk/types'
import { captureException } from '@sentry/react'
import { Icon } from 'components/Icon/Icon'

import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { profileMenuItemStyles } from './Profile'

interface FeaturebaseProps {
  user: UserResource
}

export const Featurebase: React.FC<FeaturebaseProps> = ({ user }) => {
  const { organization } = useOrganization()
  const [trackEvent] = useTrackEvent()

  useEffect(() => {
    const script = document.createElement('script')

    // load featurebadse sdk
    script.src = 'https://do.featurebase.app/js/sdk.js'
    script.id = 'featurebase-sdk'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // Docs for featurebase  https://developers.featurebase.app/install
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win = window as Window & { Featurebase?: any }

    if (typeof win.Featurebase !== 'function') {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params, no-extra-semi
        ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
      }
    }
    win.Featurebase(
      'identify',
      {
        // Each 'identify' call should include an "organization" property,
        // which is your Featurebase board's name before the ".featurebase.app".
        organization: 'dema',

        // Required
        email: user.primaryEmailAddress?.emailAddress,
        name: user.fullName,
        id: user.id,

        // Optional
        profilePicture: user.imageUrl,
        companies: [
          {
            id: organization?.publicMetadata.dema_id,
            name: organization?.name,
          },
        ],
      },
      (err: unknown) => {
        // Callback function. Called when identify completed.
        if (err) {
          captureException('Could not load featurebase sdk ' + err)
        }
      },
    )
  }, [user, organization?.name, organization?.publicMetadata.dema_id])

  return (
    <>
      <MenuItem
        {...profileMenuItemStyles}
        as={Link}
        target="_blank"
        onClick={() => {
          trackEvent({
            eventName: ' Send Feedback Link Pressed',
            eventProperties: {},
          })
        }}
        icon={<Icon name="ChatBubbleIcon" size="small" />}
        to="https://inside.dema.ai"
      >
        Send feedback
      </MenuItem>
      <MenuItem
        {...profileMenuItemStyles}
        as={Link}
        target="_blank"
        onClick={() => {
          trackEvent({
            eventName: ' See Roadmap Link Pressed',
            eventProperties: {},
          })
        }}
        icon={<Icon name="RoadmapIcon" size="small" />}
        to="https://inside.dema.ai/roadmap"
      >
        Roadmap
      </MenuItem>
    </>
  )
}
