import { useFiltersStateSync } from 'features/reports/hooks/filters/useFiltersState'
import { useDateStateSync } from 'features/reports/hooks/useDateState'
import { useDimensionsStateSync } from 'features/reports/hooks/useDimensionsState'
import { useMetricsStateSync } from 'features/reports/hooks/useMetricsState'
import { useReportStateSync } from 'features/reports/hooks/useReportState'

export const useSyncReportState = () => {
  useMetricsStateSync()
  useDimensionsStateSync()
  useDateStateSync()
  useFiltersStateSync()
  const reportState = useReportStateSync()

  return reportState
}
