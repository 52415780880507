import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { type MerchantFieldsFragment } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useEditReport } from 'graphql/reports/useEditReport'
import { type ReportResult } from 'graphql/reports/utils'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'

type Props = {
  selectedReport: ReportResult
  newVisibility: MerchantTeam[] | MerchantFieldsFragment[]
  oldVisibility: string[]
}

export const useEditReportVisibility = () => {
  const [editReport] = useEditReport()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const editReportVisibility = async ({
    selectedReport,
    newVisibility,
    oldVisibility,
  }: Props) => {
    try {
      await editReport({
        id: selectedReport.id,
        report: {
          ...selectedReport,
          visibility: newVisibility,
        },
      })

      trackEvent({
        eventName: 'Report Visibility Changed',
        eventProperties: {
          reportId: selectedReport.id,
          oldVisibility,
          newVisibility: newVisibility.map((v) => v.id),
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not update report visibility, please try again',
      })

      captureException(`Update report visibility: ${error}`)
    }
  }

  return { editReportVisibility }
}
