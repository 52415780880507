export default {
  APP_ENV: import.meta.env.MODE,
  COPILOT_URL: import.meta.env.VITE_COPILOT_URL,
  COPILOT_WS: import.meta.env.VITE_COPILOT_WS_URL,
  INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,

  HOTJAR_ID: Number(import.meta.env.VITE_HOTJAR_ID),
  HOTJAR_SV: Number(import.meta.env.VITE_HOTJAR_SV),

  GRAPHQL_URL: `${import.meta.env.VITE_API_URL}graphql`,
  API_URL: import.meta.env.VITE_API_URL,
  APP_BASENAME: import.meta.env.VITE_APP_BASENAME || '/',
  SENTRY_DSN_KEY: import.meta.env.VITE_SENTRY_DSN_KEY,
  APP_VERSION: '1.3.0',
  CLERK_KEY: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
}
