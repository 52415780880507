import { graphql } from 'generated/graphql'

export const TeamFragment = graphql(/* GraphQL */ `
  fragment TeamFields on Team {
    id
    name
    iconName
    iconColor
    __typename
  }
`)
export const MerchantFragment = graphql(/* GraphQL */ `
  fragment MerchantFields on Merchant {
    id
    name
    __typename
  }
`)

export const OwnerFragment = graphql(/* GraphQL */ `
  fragment OwnerFields on User {
    id
    externalId
  }
`)

// TODO: Include owner and teams (if included in dema reports)
export const ReportFieldsFragment = graphql(/* GraphQL */ `
  fragment ReportFields on Report {
    id
    chart
    compareDynamicDate
    compareStartDate
    compareEndDate
    createdAt
    description
    dimensions
    dynamicDate
    endDate
    filters
    metrics
    name
    startDate
    tableState
    favorite
    visibility {
      __typename
      ...TeamFields
      ...MerchantFields
    }
    labels {
      id
      name
      color
    }
    updatedAt
  }
`)

export const ReportWithOwnerFragment = graphql(/* GraphQL */ `
  fragment ReportWithOwnerFields on Report {
    ...ReportFields
    owner {
      ...OwnerFields
    }
  }
`)
